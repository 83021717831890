export const state = () => ({
  groupNotifySetting: {},
});

export const mutations = {
  SET_GROUP_NOTI_SETTING(state, setting) {
    state.groupNotifySetting = setting;
  },
};

export const actions = {
  async getGroupNotifySetting({ commit, dispatch }, id) {
    try {
      const setting = await dispatch(
        'api/get',
        { query: 'getGroupNotificationSetting', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_GROUP_NOTI_SETTING', setting);
      return setting;
    } catch (error) {
      console.error('fail to get group notify setting', error);
      return false;
    }
  },

  async listGroupNotifySettings({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const setting = await dispatch(
        'api/query',
        { query: 'listGroupNotificationSettings', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_GROUP_NOTI_SETTING', setting[0]);
      return setting;
    } catch (error) {
      console.error('fail to get group notify setting', error);
      return false;
    }
  },

  async createGroupNotifySetting({ commit, dispatch }, input) {
    try {
      const setting = await dispatch(
        'api/mutate',
        { mutation: 'createGroupNotificationSetting', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      dispatch(
        'profile/getProfileSpace',
        setting.profileID,
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_GROUP_NOTI_SETTING', setting);
      return setting;
    } catch (error) {
      console.error('fail to create group notify setting', error);
      return false;
    }
  },

  async updateGroupNotifySetting({ commit, dispatch }, input) {
    try {
      const setting = await dispatch(
        'api/mutate',
        { mutation: 'updateGroupNotificationSetting', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      dispatch(
        'profile/getProfileSpace',
        setting.profileID,
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_GROUP_NOTI_SETTING', setting);
      return setting;
    } catch (error) {
      console.error('fail to update group notify setting', error);
      return Promise.reject();
    }
  },
};

export const getters = {
  groupNotifySetting: (state) => state.groupNotifySetting,
};
