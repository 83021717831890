import { mapGetters } from 'vuex';
import getNotifyFlashMess from '@/lib/notificationTemplates/notifyFlashMess';

export default {
  computed: {
    ...mapGetters('notifications', ['newNotify']),
    ...mapGetters('auth', ['currentUser']),
  },
  watch: {
    newNotify(value) {
      if (value.type === 'newMessage') return;
      if (value.type === 'answerSpaceRequest') return;
      const { receiveNotify } = this.currentUser;
      const notifyMessage = getNotifyFlashMess.getNotifyFlashMess(value);
      let notification = null;
      if ('Notification' in window && Notification.permission === 'granted' && receiveNotify) {
        notification = new Notification('KnowledgeLoop', {
          body: notifyMessage,
        });
      }
      if (notification) {
        setTimeout(() => {
          notification.close();
        }, 5000);
      }
    },
  },
  methods: {},
};
