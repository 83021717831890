/* eslint-disable */
export default async ({ store, route, redirect }) => {
  const spaceId = route.path.split('/')[2];
  const coachRoomId = route.path.split('/')[4];
  const { id: profileID, spaceID: profileSpaceID } = store.state.profile.userSpaceProfile;
  const { coachRoom } = store.state.coachRoom;
  if ((!profileID || profileSpaceID != spaceId)) {
    return redirect('/space');
  }
  if (!coachRoom || coachRoom.id != coachRoomId) {
    await store.dispatch('coachRoom/getCoachRoomById', coachRoomId);
  }
  const { currentSpace } = store.state.space;
  const { currentUser } = store.state.auth;
  const { coachRoom: coachRoomFetchData } = store.state.coachRoom;
  if (!(coachRoomFetchData.profileID === profileID || coachRoomFetchData.coachs.includes(profileID) || currentSpace.userID === currentUser.id)) {
    return redirect('/space');
  }
  if (!store.state.group.currentGroup?.id) {
    store.dispatch('group/getGroup', coachRoomFetchData.groupID);
  }
};
