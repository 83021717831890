export const state = () => ({
  isAddMember: false,
  addMemberAmount: 0,
  isAddUpload: false,
  addUploadAmount: 0,
  isAddStorage: false,
  addStorageAmount: 0,
  currentPlan: {},
  changePlan: {},
  customPageType: 'member',
  currentPayJPSubscription: {},
  listSubscriptions: [],
});

export const mutations = {
  SET_ADD_MEMBER(state, amount) {
    state.addMemberAmount = amount;
    state.isAddMember = amount > 0;
  },
  SET_ADD_UPLOAD(state, amount) {
    state.addUploadAmount = amount;
    state.isAddUpload = amount > 0;
  },
  SET_ADD_STORAGE(state, amount) {
    state.addStorageAmount = amount;
    state.isAddStorage = amount > 0;
  },
  SET_CURRENT_PLAN(state, plan) {
    state.currentPlan = plan;
  },
  SET_CHANGE_PLAN(state, plan) {
    state.changePlan = plan;
  },
  SET_CUSTOM_PAGE_TYPE(state, type) {
    state.customPageType = type;
  },
  SET_CURRENT_PAYJP_SUB(state, data) {
    if (['success', 'paused'].includes(data?.status)) {
      state.currentPayJPSubscription = data || {};
    }
  },
  RESET_CURRENT_PAYJP_SUB(state) {
    state.currentPayJPSubscription = {};
  },
  SET_LIST_SUB(state, data) {
    state.listSubscriptions = data.sort((item) => item.type).reverse();
  },
  UPDATE_PAYJP_SUB(state, sub) {
    const { currentPayJPSubscription, listSubscriptions } = state;
    if (currentPayJPSubscription.id === sub.id && ['success', 'paused'].includes(sub?.status)) {
      state.currentPayJPSubscription = sub || {};
    }
    const foundIndex = listSubscriptions.findIndex((item) => item.id === sub.id);
    if (foundIndex !== -1) {
      listSubscriptions[foundIndex] = sub;
      state.listSubscriptions = [...listSubscriptions];
    }
  },
};

export const actions = {
  async getPlanMaster({ dispatch, commit }, id) {
    try {
      const fetchPlan = await dispatch(
        'api/get',
        { query: 'getPlanMaster', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_CURRENT_PLAN', fetchPlan);
    } catch (error) {
      console.error('fail to get plan master', error);
      return false;
    }
  },

  async setChangePlan({ commit }, plan) {
    commit('SET_CHANGE_PLAN', plan);
  },

  async setCustomPageType({ commit }, type) {
    commit('SET_CUSTOM_PAGE_TYPE', type);
  },

  async settingPageInfo({ commit }, { memberAmount, uploadAmount, storageAmount }) {
    commit('SET_ADD_MEMBER', memberAmount);
    commit('SET_ADD_UPLOAD', uploadAmount);
    commit('SET_ADD_STORAGE', storageAmount);
  },

  async resetCurrentPayJPSubscription({ commit }) {
    commit('RESET_CURRENT_PAYJP_SUB', {});
  },

  async getPayJPSubscriptionByID({ commit, dispatch }, id) {
    try {
      const fetchData = await dispatch(
        'api/get',
        { query: 'getPayJPSubscription', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_CURRENT_PAYJP_SUB', fetchData);
      return true;
    } catch (error) {
      console.error('fail to get current subscription by id', error);
      return false;
    }
  },

  async getPayJPSubscriptionByName(
    { commit, dispatch },
    { spaceID, filter, limit, nextToken, ...variables } // eslint-disable-line
  ) {
    try {
      const fetchData = await dispatch(
        'api/query',
        { query: 'listBySpaceIDProductName', spaceID, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_CURRENT_PAYJP_SUB', fetchData[0]);
      return true;
    } catch (error) {
      console.error('fail to get current subscription by name', error);
      return false;
    }
  },

  async listSpaceSubscriptions(
    { commit, dispatch },
    { spaceID, filter, limit, nextToken, ...variables } // eslint-disable-line
  ) {
    try {
      const fetchData = await dispatch(
        'api/query',
        { query: 'listBySpaceID', spaceID, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_LIST_SUB', fetchData);
      return true;
    } catch (error) {
      console.error('fail to list active subscription by space id', error);
      return false;
    }
  },

  async getPayJPSubscriptionByType(
    { commit, dispatch },
    { spaceID, filter, limit, nextToken, ...variables } // eslint-disable-line
  ) {
    try {
      const fetchData = await dispatch(
        'api/query',
        { query: 'listBySpaceIDProductType', spaceID, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_CURRENT_PAYJP_SUB', fetchData[0]);
      return fetchData[0];
    } catch (error) {
      console.error('fail to get current subscription by type', error);
      return false;
    }
  },

  async listPayJPSubscriptionByType(
    { dispatch },
    { spaceID, filter, limit, nextToken, ...variables } // eslint-disable-line
  ) {
    try {
      const fetchData = await dispatch(
        'api/query',
        { query: 'listBySpaceIDProductType', spaceID, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      return fetchData;
    } catch (error) {
      console.error('fail to get current subscription by type', error);
      return false;
    }
  },

  async createPayJpSubscription({ commit, dispatch }, input) {
    try {
      const newSub = await dispatch(
        'api/mutate',
        { mutation: 'createPayJPSubscription', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_CURRENT_PAYJP_SUB', newSub);
      return newSub;
    } catch (error) {
      console.error('fail to create subscription', error);
      return false;
    }
  },

  async updatePayJpSubscription({ commit, dispatch }, input) {
    try {
      const updateSub = await dispatch(
        'api/mutate',
        { mutation: 'updatePayJPSubscription', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_CURRENT_PAYJP_SUB', updateSub);
      return updateSub;
    } catch (error) {
      console.error('fail to update subscription', error);
      return false;
    }
  },

  async updatePayJPSubscriptionInfo({ commit }, updatePayJPSubscription) {
    commit('UPDATE_PAYJP_SUB', updatePayJPSubscription);
  },
};

export const getters = {
  isAddMember: (state) => state.isAddMember,
  addMemberAmount: (state) => state.addMemberAmount,
  isAddUpload: (state) => state.isAddUpload,
  addUploadAmount: (state) => state.addUploadAmount,
  isAddStorage: (state) => state.isAddStorage,
  addStorageAmount: (state) => state.addStorageAmount,
  currentPlan: (state) => state.currentPlan,
  changePlan: (state) => state.changePlan,
  customPageType: (state) => state.customPageType,
  currentPayJPSubscription: (state) => state.currentPayJPSubscription,
  listSubscriptions: (state) => state.listSubscriptions,
};
