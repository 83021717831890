import * as Cookie from '@/lib/util/cookies';

export default async ({ store, redirect, route }) => {
  const videoID = route.params.id;
  const {
    currentUser: { id: userID },
    isAuthenticated,
  } = store.state.auth;
  const video = await store.dispatch('video/getVideoGuest', videoID);
  const { inviteCode: currentInvitation, publicToSpace, spaceID, status } = video;
  const { inviteCode: queryInviteCode, groupID } = route.query;
  if (!currentInvitation || queryInviteCode !== currentInvitation || status !== 'approve') {
    return redirect('/space');
  }
  if (isAuthenticated) {
    const input = {
      spaceId: spaceID,
      userId: userID,
    };
    const [userProfile] = await store.dispatch('profile/getUserSpaceProfile', input);
    if (userProfile && userProfile.status === 'active') {
      if (publicToSpace) {
        return redirect(`/space/${spaceID}/video/${videoID}`);
      }
      const joinGroupFilter = {
        groupID: { eq: groupID },
        userID: { eq: userID },
      };
      const userGroup = await store.dispatch('group/listJoinGroups', { filter: joinGroupFilter });
      if (userGroup.length > 0) {
        return redirect(`/space/${spaceID}/group/${groupID}/video/${videoID}`);
      }
    }
    const spaceFilter = {
      userID: { eq: userID },
      type: { eq: 'default' },
    };
    const [spaceDefault] = await store.dispatch('space/listSpace', { filter: spaceFilter });
    if (spaceDefault && spaceDefault.id) {
      const inputDefault = {
        spaceId: spaceDefault.id,
        userId: userID,
      };
      const [userProfileDefault] = await store.dispatch('profile/getUserSpaceProfile', inputDefault); // eslint-disable-line
      if (userProfileDefault && userProfileDefault.status === 'active') {
        return;
      }
    }
  }
  // check if there is guest info
  const {
    accessKeyId: stateAccessKeyId,
    secretAccessKey: stateSecretAccessKey,
    sessionToken: stateSessionToken,
    guestName: stateGuestName,
  } = store.state.guest.guestInfo;
  if (!(stateAccessKeyId && stateSecretAccessKey && stateSessionToken && stateGuestName)) {
    const guestName = Cookie.default.getCookie('guestName');
    const accessKeyId = Cookie.default.getCookie('accessKeyId');
    const secretAccessKey = Cookie.default.getCookie('secretAccessKey');
    const sessionToken = Cookie.default.getCookie('sessionToken');
    if (!(guestName && accessKeyId && secretAccessKey && sessionToken)) {
      store.dispatch('guest/removeGuestUser');
      return redirect(`/video/${videoID}/shared?inviteCode=${queryInviteCode}`);
    }
    const guestInfo = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };
    await store.dispatch('guest/setGuestUser', { guestName, guestInfo });
  }
};
