export const state = () => ({
  spaceNotifySetting: {},
});

export const mutations = {
  SET_SPACE_NOTI_SETTING(state, setting) {
    state.spaceNotifySetting = setting;
  },
};

export const actions = {
  async getSpaceNotifySetting({ commit, dispatch }, id) {
    try {
      const setting = await dispatch(
        'api/get',
        { query: 'getSpaceNotificationSetting', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACE_NOTI_SETTING', setting);
      return setting;
    } catch (error) {
      console.error('fail to get space notify setting', error);
      return false;
    }
  },

  async listSpaceNotifySettings({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const setting = await dispatch(
        'api/query',
        { query: 'listSpaceNotificationSettings', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACE_NOTI_SETTING', setting[0]);
      return setting;
    } catch (error) {
      console.error('fail to get space notify setting', error);
      return false;
    }
  },

  async createSpaceNotifySetting({ commit, dispatch }, input) {
    try {
      const setting = await dispatch(
        'api/mutate',
        { mutation: 'createSpaceNotificationSetting', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      dispatch(
        'profile/getProfileSpace',
        setting.profileID,
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACE_NOTI_SETTING', setting);
      return setting;
    } catch (error) {
      console.error('fail to create space notify setting', error);
      return false;
    }
  },

  async updateSpaceNotifySetting({ commit, dispatch }, input) {
    try {
      const setting = await dispatch(
        'api/mutate',
        { mutation: 'updateSpaceNotificationSetting', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      dispatch(
        'profile/getProfileSpace',
        setting.profileID,
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACE_NOTI_SETTING', setting);
      return setting;
    } catch (error) {
      console.error('fail to update space notify setting', error);
      return Promise.reject();
    }
  },
};

export const getters = {
  spaceNotifySetting: (state) => state.spaceNotifySetting,
};
