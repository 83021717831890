export const state = () => ({
  listPlans: [],
});

export const mutations = {
  SET_LIST_PLANS(state, plans) {
    state.listPlans = plans.sort((a, b) => a.monthlyFee - b.monthlyFee);
  },
};

export const actions = {
  async getPlanMaster({ dispatch }, id) {
    try {
      return await dispatch(
        'api/get',
        { query: 'getPlanMaster', id },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to get plan master', error);
      return false;
    }
  },

  async createPlanMaster({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'createPlanMaster', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to create plan master', error);
      return false;
    }
  },

  async updatePlanMaster({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'updatePlanMaster', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to update plan master', error);
      return false;
    }
  },

  async deletePlanMaster({ dispatch }, id) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'deletePlanMaster', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to delete plan master', error);
      return Promise.reject();
    }
  },

  async listPlanMasters({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const data = await dispatch(
        'api/query',
        { query: 'listPlanMasters', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_LIST_PLANS', data);
      return data;
    } catch (error) {
      console.error('fail to list plan master', error);
      return false;
    }
  },

  async listPlanMastersPublic({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const data = await dispatch(
        'api/queryPublic',
        { query: 'listPlanMasters', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_LIST_PLANS', data);
      return data;
    } catch (error) {
      console.error('fail to public list plan master', error);
      return false;
    }
  },
};

export const getters = {
  listPlans: (state) => state.listPlans,
};
