export default {
  data() {
    return {
      isExpand: false,
      selectedGroup: {},
    };
  },
  computed: {
    asideClasses() {
      return { aside: true, 'is-active': this.isShowing, 'is-expand': this.isExpand };
    },
  },
  methods: {
    onSelected(group) {
      this.selectedGroup = group;
    },
    isSelected(id) {
      return this.selectedGroup.id === id;
    },
    toggleExpand() {
      this.isExpand = !this.isExpand;
    },
    hideExpand() {
      this.isExpand = false;
    },
  },
};
