import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    ...mapActions('notifications', ['listNotifications']),
    async getNotifications(spaceID, fetchNotificationInfo = true) {
      const { id: userID } = this.currentUser;
      if (userID && spaceID) {
        const data = await this.listNotifications({
          filter: {
            receiverID: { eq: userID },
            spaceID: { eq: spaceID },
          },
          fetchNotificationInfo,
        });
        return data;
      }
    },
  },
};
