/* eslint-disable */
export default async ({ store, route, redirect }) => {
  // check authentication
  const spaceId = route.path.split('/')[2];
  if (!store.state.auth.isAuthenticated) {
    await store.dispatch('auth/load');
    if (!store.state.auth.isAuthenticated) {
      store.dispatch('notifications/stopListenToNotifications');
      return redirect('/login');
    }
  }
  // check space validation
  const validWhenBlock = [
    'space-id-profile', 
    'space-id-profile-edit', 
    'space-id-setting', 
    'space-id-edit',
    'space-id-block',
  ]
  const spaceData = await store.dispatch('space/getSpace', spaceId);
  if (spaceData.status === 'inactive') {
    return redirect('/space');
  }
  if (spaceData.status === 'blockMainFunctions') {
    if (!(validWhenBlock.includes(route.name) || route.name.includes('space-id-settingPlan'))) {
      store.dispatch('loading/setOffLoading');
      return redirect(`/space/${spaceId}/block`);
    }
  }
  if (!store.state.coachRoom?.coachRooms?.length) {
    await store.dispatch('coachRoom/getSpaceCoachRooms', {
      spaceID: spaceData.id,
    });
  }
  // check space profile
  const currentUserProfiles = store.state.auth.currentUser.profiles.items;
  const activeProfile = currentUserProfiles.find((profile) => profile.status === 'active' && profile.spaceID === spaceId);
  if (!activeProfile) {
    return redirect('/space');
  }
  const { id: profileID, spaceID: profileSpaceID } = store.state.profile.userSpaceProfile;
  if ((!profileID || profileID != activeProfile.id || profileSpaceID != spaceId) && activeProfile) {
    store.commit('profile/SET_USER_SPACE_PROFILE', activeProfile);
  }
  if (route.name !== 'space-id-profile-edit' && !store.state.profile.userSpaceProfile.confirmed) {
    store.dispatch('loading/setOffLoading');
    return redirect(`/space/${spaceId}/profile/edit`);
  }
  store.dispatch('localStoreManager/setItemUserStorage', { key: `${store.state.auth.currentUser.id}-spaceID`, value: spaceId })
  store.dispatch('notifications/startListenToNotifications', { currentUser: store.state.auth.currentUser, spaceID: spaceId });
};
