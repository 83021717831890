import { API, Auth } from 'aws-amplify';
import i18n from '@/plugins/i18n';

export const state = {
  customer: {},
  payjp: { notLoaded: true },
};

export const mutations = {
  set(state, { key, value }) {
    state[key] = value;
  },
  SET_CUSTOMER(state, customer) {
    state.customer = customer;
  },
  SET_CUSTOMER_CARD(state, card) {
    const cards = {
      ...state.customer.cards,
    };
    cards.data.push(card);
    state.customer = {
      ...state.customer,
      default_card: card.id,
      cards,
    };
  },
  SET_PAYJP(state, payjp) {
    state.payjp = payjp;
  },
};

export const actions = {
  load({ commit }) {
    const payjp = Payjp(process.env.PAYJP_PUBLIC_KEY_TEST); // eslint-disable-line no-undef
    commit('SET_PAYJP', payjp);

    return payjp;
  },
  async get({ commit }, { query, key, params = {} }) {
    // production and staging call direct to staging
    const apiName = 'PayJPQueries';
    const path = `/${query}`;
    const myInit = {
      queryStringParameters: {
        ...params,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };

    try {
      const { ...value } = await API.get(apiName, path, myInit);
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e.response.status >= 400 && e.response.status < 500) {
        // catch payjp error case here
        if (e.response.data.error.code === 'invalid_id') {
          return false;
        }
        console.error(e.response.data.error);
      }
    }
  },

  async post({ commit }, { query, key, params = { body: {} } }) {
    // production and staging call direct to staging
    const apiName = 'PayJPQueries';
    const path = `/${query}`;
    const myInit = {
      body: {
        ...params.body,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };

    try {
      const { ...value } = await API.post(apiName, path, myInit);
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e.response.status === 400 && e.response.data.error) {
        // catch payjp error case here
        if (e.response.data.error.code === 'already_have_card') {
          this.$toast.error(i18n.t('payJP.alreadyAddCard'));
        } else {
          console.log(e.response.data);
        }
      }
    }
  },

  async put({ commit }, { query, key, params = { body: {} } }) {
    // production and staging call direct to staging
    const apiName = 'PayJPQueries';
    const path = `/${query}`;
    const myInit = {
      body: {
        ...params.body,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };

    try {
      const { ...value } = await API.put(apiName, path, myInit);
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e.response.status === 400 && e.response.data.error) {
        // catch payjp error case here
        console.log(e.response.data);
      }
    }
  },

  async createCustomer({ commit, dispatch }, { token, id }) {
    try {
      if (!token) {
        console.error('no Token found');
        return false;
      }
      const params = { body: { card: token, id } };

      const customer = await dispatch('payjp/post', { query: 'customers', params }, { root: true });
      commit('SET_CUSTOMER', customer);
      return customer;
    } catch (error) {
      console.error('fail to create payjp customer', error);
      return false;
    }
  },

  async updateCard({ commit, dispatch }, { token, id }) {
    try {
      if (!token) {
        console.error('no Token found');
        return false;
      }
      const params = { body: { card: token } };

      const customer = await dispatch(
        'payjp/post',
        { query: `customers/${id}/cards`, params },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_CUSTOMER_CARD', customer);
      return customer;
    } catch (error) {
      console.error('fail to create payjp customer', error);
      return false;
    }
  },

  async getCustomer({ commit, dispatch }, id) {
    try {
      if (!id) {
        console.error('payjp customer id not found');
        return false;
      }
      const customer = await dispatch('payjp/get', { query: `customers/${id}` }, { root: true });
      commit('SET_CUSTOMER', customer);
      return customer;
    } catch (error) {
      console.error('fail to create payjp customer', error);
      return false;
    }
  },

  async updateDefaultCard({ commit, dispatch }, { id, cardID }) {
    try {
      if (!id) {
        console.error('payjp customer id not found');
        return false;
      }
      const params = { body: { default_card: cardID } };
      const customer = await dispatch(
        'payjp/put',
        { query: `customers/${id}`, params },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_CUSTOMER', customer);
      return customer;
    } catch (error) {
      console.error('fail to update payjp customer', error);
      return false;
    }
  },

  async createSubscription(
    { dispatch },
    // eslint-disable-next-line comma-dangle
    { id, planID, metadata }
  ) {
    try {
      if (!id) {
        console.error('payjp customer id not found');
        return false;
      }
      const params = { body: { planID, metadata } };
      const subscription = await dispatch(
        'payjp/post',
        { query: `customers/${id}/subscriptions`, params },
        { root: true } // eslint-disable-line comma-dangle
      );
      return subscription;
    } catch (error) {
      console.error('fail to create payjp subscription', error);
      return false;
    }
  },

  async updateSubscription(
    { dispatch },
    // eslint-disable-next-line comma-dangle
    { id, subscriptionID, planID, trialEnd, metadata }
  ) {
    try {
      if (!id) {
        console.error('payjp customer id not found');
        return false;
      }
      const params = { body: { planID, trialEnd, metadata } };
      const subscription = await dispatch(
        'payjp/put',
        { query: `customers/${id}/subscriptions/${subscriptionID}`, params },
        { root: true } // eslint-disable-line comma-dangle
      );
      return subscription;
    } catch (error) {
      console.error('fail to create payjp subscription', error);
      return false;
    }
  },

  async cancelSubscription(
    { dispatch },
    // eslint-disable-next-line comma-dangle
    { id, subscriptionID }
  ) {
    try {
      if (!id) {
        console.error('payjp customer id not found');
        return false;
      }
      const subscription = await dispatch(
        'payjp/put',
        { query: `customers/${id}/subscriptions/${subscriptionID}/cancel` },
        { root: true } // eslint-disable-line comma-dangle
      );
      return subscription;
    } catch (error) {
      console.error('fail to create payjp subscription', error);
      return false;
    }
  },

  /**
   * Change subscription subscriber
   *
   * @param  {id} profileID new subscriber profileID
   * @param  {subscriptionID} payjpSubsciptionID the payjp subscription to be change
   *
   * @return {object}      the returned payjp subscription object
   */
  async changeSubscriber(
    { dispatch },
    // eslint-disable-next-line comma-dangle
    { id, subscriptionID, userID }
  ) {
    try {
      if (!id) {
        console.error('payjp customer id not found');
        return false;
      }
      const params = { body: { userID } };
      const subscription = await dispatch(
        'payjp/put',
        { query: `customers/${id}/subscriptions/${subscriptionID}/changeSubscriber`, params },
        { root: true } // eslint-disable-line comma-dangle
      );
      return subscription;
    } catch (error) {
      console.error('fail to create payjp subscription', error);
      return false;
    }
  },

  async retryPayment(
    { dispatch },
    // eslint-disable-next-line comma-dangle
    { id, subscriptionID, trialEnd }
  ) {
    try {
      if (!id) {
        console.error('payjp customer id not found');
        return false;
      }
      const params = { body: { trialEnd } };
      const subscription = await dispatch(
        'payjp/put',
        { query: `customers/${id}/subscriptions/${subscriptionID}/retryPayment`, params },
        { root: true } // eslint-disable-line comma-dangle
      );
      return subscription;
    } catch (error) {
      console.error('fail to create payjp subscription', error);
      return false;
    }
  },

  async cancelDowngrade(
    { dispatch },
    // eslint-disable-next-line comma-dangle
    { id, subscriptionID, trialEnd, planID, DynamoSubId, oldDynamoSubId }
  ) {
    try {
      if (!id) {
        console.error('payjp customer id not found');
        return false;
      }
      const params = { body: { trialEnd, planID, DynamoSubId, oldDynamoSubId } };
      const subscription = await dispatch(
        'payjp/put',
        { query: `customers/${id}/subscriptions/${subscriptionID}/cancelDowngrade`, params },
        { root: true } // eslint-disable-line comma-dangle
      );
      return subscription;
    } catch (error) {
      console.error('fail to create payjp subscription', error);
      return false;
    }
  },
};

export const getters = {
  customer: (state) => state.customer,
  payjp: (state) => state.payjp,
};
