import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from '../config/locale/en';
import jaLocale from '../config/locale/ja';

Vue.use(VueI18n);

// use in script this.$locale.t('message.hello', { msg: 'hello' }) or import from this plugins;
// use in template {{ $t('message.hello', { msg: 'hello' }) }}
// to change locale any time this.$locale.locale = 'en';

/* eslint-disable */
// Ready translated locale messages
const messages = {
  en: enLocale,
  ja: jaLocale,
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'ja', // set locale
  messages, // set locale messages
});

Vue.prototype.$locale = i18n;
Vue.$locale = i18n;

export default i18n;

// Create a Vue instance with `i18n` option
new Vue({ i18n }).$mount('#app');
