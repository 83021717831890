import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=3a23840e&scoped=true&"
import script from "./admin.vue?vue&type=script&lang=js&"
export * from "./admin.vue?vue&type=script&lang=js&"
import style0 from "./admin.vue?vue&type=style&index=0&id=3a23840e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a23840e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/Users/yasumura65/Dev/pico/knowledgeloop-trial/components/layouts/Loading.vue').default})
