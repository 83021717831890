export const state = () => ({
  videoTags: [],
  videoTag: {},
});

export const mutations = {
  SET_VIDEO_TAG(state, tag) {
    state.videoTag = tag;
  },
  SET_VIDEO_TAGS(state, tags) {
    state.videoTags = tags;
  },
};

export const actions = {
  async getVideoTag({ commit, dispatch }, id) {
    try {
      const tag = await dispatch(
        'api/get',
        { query: 'getTag', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_VIDEO_TAG', tag);
      return tag;
    } catch (error) {
      console.error('fail to get video tag', error);
      return false;
    }
  },
  async listVideoTag({ commit, dispatch }, { spaceID, filter, limit, nextToken, ...variables }) {
    try {
      const data = await dispatch(
        'api/query',
        { query: 'tagsBySpaceAndTypeAndParent', spaceID, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_VIDEO_TAGS', data);
      return data;
    } catch (error) {
      console.error('fail to list video tags', error);
      return false;
    }
  },
  async createVideoTag({ commit, dispatch }, input) {
    try {
      const newTag = await dispatch(
        'api/mutate',
        { mutation: 'createTag', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_VIDEO_TAG', newTag);
      return newTag;
    } catch (error) {
      console.error('fail to create video tag', error);
      return false;
    }
  },
  async updateVideoTag({ commit, dispatch }, input) {
    try {
      const updateTag = await dispatch(
        'api/mutate',
        { mutation: 'updateTag', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_VIDEO_TAG', updateTag);
      return updateTag;
    } catch (error) {
      console.error('fail to update video tag', error);
      return false;
    }
  },
  async deleteVideoTag({ commit, dispatch }, id) {
    try {
      await dispatch(
        'api/mutate',
        { mutation: 'deleteTag', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_VIDEO_TAG', {});
      return true;
    } catch (error) {
      console.error('fail to delete video tag', error);
      return Promise.reject();
    }
  },
};
export const getters = {
  videoTag: (state) => state.videoTag,
  videoTags: (state) => state.videoTags,
};
