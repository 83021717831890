import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faEyeSlash as fortawesomefreeregularsvgicons_faEyeSlash } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faEyeSlash)

    import  { faEye as fortawesomefreeregularsvgicons_faEye } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faEye)

    import  { faCaretLeft as fortawesomefreesolidsvgicons_faCaretLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCaretLeft)

    import  { faChartLine as fortawesomefreesolidsvgicons_faChartLine } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChartLine)

    import  { faCheckCircle as fortawesomefreesolidsvgicons_faCheckCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheckCircle)

    import  { faChevronDown as fortawesomefreesolidsvgicons_faChevronDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronDown)

    import  { faChevronLeft as fortawesomefreesolidsvgicons_faChevronLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronLeft)

    import  { faChevronRight as fortawesomefreesolidsvgicons_faChevronRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronRight)

    import  { faChevronUp as fortawesomefreesolidsvgicons_faChevronUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronUp)

    import  { faDownload as fortawesomefreesolidsvgicons_faDownload } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faDownload)

    import  { faEdit as fortawesomefreesolidsvgicons_faEdit } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faEdit)

    import  { faUpload as fortawesomefreesolidsvgicons_faUpload } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUpload)

    import  { faUser as fortawesomefreesolidsvgicons_faUser } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUser)

    import  { faDollarSign as fortawesomefreesolidsvgicons_faDollarSign } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faDollarSign)

    import  { faAngleLeft as fortawesomefreesolidsvgicons_faAngleLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faAngleLeft)

    import  { faCalendar as fortawesomefreesolidsvgicons_faCalendar } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCalendar)

    import  { faSearch as fortawesomefreesolidsvgicons_faSearch } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSearch)

    import  { faFilter as fortawesomefreesolidsvgicons_faFilter } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faFilter)

    import  { faFacebook as fortawesomefreebrandssvgicons_faFacebook } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faFacebook)

    import  { faGoogle as fortawesomefreebrandssvgicons_faGoogle } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faGoogle)

Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)
