export default {
  data() {
    return {
      isSpaceSelect: false,
    };
  },
  methods: {
    toggleSpaceSelect() {
      this.$router.push({ path: '/space' });
    },
  },
};
