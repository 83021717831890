import * as CryptoJS from '@/lib/util/crypto';

export default async ({ store, redirect }) => {
  const emailStr = localStorage.getItem('oAuthEmail');
  const passwordStr = localStorage.getItem('oAuthPass');

  const { isAuthenticatedAdmin } = store.state.auth;

  if (isAuthenticatedAdmin) {
    return redirect('/admin');
  }

  if (emailStr && passwordStr) {
    try {
      await store.dispatch('auth/signIn', {
        email: emailStr,
        password: CryptoJS.default.decryptMessage(passwordStr),
        isOauth: true,
      });
      localStorage.removeItem('oAuthEmail');
      localStorage.removeItem('oAuthPass');
      localStorage.removeItem('oAuthId');
      return;
    } catch (err) {
      localStorage.removeItem('oAuthEmail');
      localStorage.removeItem('oAuthPass');
      localStorage.removeItem('oAuthId');
      return;
    }
  }

  const { cognitoUser } = store.state.auth;
  if (!cognitoUser) return;
  const { username } = cognitoUser;
  const isFb = username.indexOf('Facebook_');
  const isGoogle = username.indexOf('Google_');
  const isOAuth = isFb > -1 || isGoogle > -1;

  const user = {};

  if (isOAuth) {
    const oAuthEmail = cognitoUser.attributes.email;
    const userAccount = await store.dispatch('userAccount/getUserAccounts', {
      filter: {
        email: { eq: oAuthEmail },
      },
    });
    const oAuthUserAccount = (userAccount || []).find(
      // eslint-disable-next-line comma-dangle
      (account) => account.oAuthUserRef === username
    );
    if (!oAuthUserAccount) {
      if ((userAccount || []).length > 0) {
        window.$nuxt.$toast.error(
          window.$nuxt.$locale.t('common.errorWithMessage', {
            message: 'すでに使用済みのメールアドレスです。',
            // eslint-disable-next-line comma-dangle
          })
        );
        await store.dispatch('auth/logout');
        return;
      }
      if (passwordStr) {
        const idStr = localStorage.getItem('oAuthId');
        await store.dispatch('userAccount/createUserAccount', {
          userID: idStr,
          email: oAuthEmail,
          password: CryptoJS.default.encryptMessage(passwordStr),
          oAuthUserRef: username,
        });
        user.email = oAuthEmail;
        user.password = CryptoJS.default.encryptMessage(passwordStr);
      } else {
        try {
          const { id, email, password } = await store.dispatch('admin/post', {
            query: 'createUser',
            params: {
              email: oAuthEmail,
              isOauth: true,
            },
          });
          if (id) {
            localStorage.setItem('oAuthId', id);
            localStorage.setItem('oAuthPass', CryptoJS.default.encryptMessage(password));
            await store.dispatch('userAccount/createUserAccount', {
              userID: id,
              email: oAuthEmail,
              password: CryptoJS.default.encryptMessage(password),
              oAuthUserRef: username,
            });
            user.email = email;
            user.password = CryptoJS.default.encryptMessage(password);
          }
        } catch (err) {
          console.log('create masked user error: ', err);
          window.$nuxt.$toast.error(
            window.$nuxt.$locale.t('common.errorWithMessage', {
              message: 'すでに使用済みのメールアドレスです。',
              // eslint-disable-next-line comma-dangle
            })
          );
          await store.dispatch('auth/logout');
          return;
        }
      }
    } else {
      user.email = oAuthUserAccount.email;
      user.password = oAuthUserAccount.password;
    }
    localStorage.setItem('oAuthEmail', user.email);
    localStorage.setItem('oAuthPass', user.password);
    await store.dispatch('auth/logout');
  }
};
