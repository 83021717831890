//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import flashNewNotify from '@/mixins/flashNewNotify';
import spaceGroupExistChannel from '@/mixins/spaceGroupExistChannel';
import Loading from '@/components/layouts/Loading';

export default {
  name: 'Sidebarless',
  components: {
    Loading,
  },
  mixins: [flashNewNotify, spaceGroupExistChannel],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('group', ['currentGroup']),
    ...mapGetters('space', ['currentSpace']),
  },
  watch: {
    $route: {
      handler: function route() {
        this.$nextTick(() => {
          this.$store.dispatch('loading/setOffLoading');
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('loading/setOffLoading');
    window.addEventListener('online', () => this.$router.go(0));
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', this.handleResize, true);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  errorCaptured(err) {
    this.$store.dispatch('loading/setOffLoading');
    console.log(err);
    this.addAlert({
      type: 'alert-error',
    });
    return false;
  },
  methods: {
    ...mapActions('alertError', ['addAlert']),
    removeJoinGroup(id) {
      const groupID = this.currentGroup.id;
      const spaceID = this.currentSpace.id;
      const { name } = this.$route;
      if (name.includes('group') && id === groupID && spaceID) {
        this.$router.push({ path: `/space/${spaceID}/video` }, () => {
          this.$toast.error(this.$locale.t('group.deleted'));
        });
      }
    },
    handleResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
};
