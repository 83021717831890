const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authenticatedAdmin'] = require('../middleware/authenticatedAdmin.js')
middleware['authenticatedAdmin'] = middleware['authenticatedAdmin'].default || middleware['authenticatedAdmin']

middleware['autoLogout'] = require('../middleware/autoLogout.js')
middleware['autoLogout'] = middleware['autoLogout'].default || middleware['autoLogout']

middleware['fetchCoachRoom'] = require('../middleware/fetchCoachRoom.js')
middleware['fetchCoachRoom'] = middleware['fetchCoachRoom'].default || middleware['fetchCoachRoom']

middleware['fetchGroupFromQuery'] = require('../middleware/fetchGroupFromQuery.js')
middleware['fetchGroupFromQuery'] = middleware['fetchGroupFromQuery'].default || middleware['fetchGroupFromQuery']

middleware['fetchUserAccount'] = require('../middleware/fetchUserAccount.js')
middleware['fetchUserAccount'] = middleware['fetchUserAccount'].default || middleware['fetchUserAccount']

middleware['hasSpace'] = require('../middleware/hasSpace.js')
middleware['hasSpace'] = middleware['hasSpace'].default || middleware['hasSpace']

middleware['isCoachRoomOwnerOrCoach'] = require('../middleware/isCoachRoomOwnerOrCoach.js')
middleware['isCoachRoomOwnerOrCoach'] = middleware['isCoachRoomOwnerOrCoach'].default || middleware['isCoachRoomOwnerOrCoach']

middleware['isGroupEnabled'] = require('../middleware/isGroupEnabled.js')
middleware['isGroupEnabled'] = middleware['isGroupEnabled'].default || middleware['isGroupEnabled']

middleware['isGroupManager'] = require('../middleware/isGroupManager.js')
middleware['isGroupManager'] = middleware['isGroupManager'].default || middleware['isGroupManager']

middleware['isGroupMember'] = require('../middleware/isGroupMember.js')
middleware['isGroupMember'] = middleware['isGroupMember'].default || middleware['isGroupMember']

middleware['isSetUpGuestInfo'] = require('../middleware/isSetUpGuestInfo.js')
middleware['isSetUpGuestInfo'] = middleware['isSetUpGuestInfo'].default || middleware['isSetUpGuestInfo']

middleware['isShareVideo'] = require('../middleware/isShareVideo.js')
middleware['isShareVideo'] = middleware['isShareVideo'].default || middleware['isShareVideo']

middleware['isSpaceActiveCoachRoom'] = require('../middleware/isSpaceActiveCoachRoom.js')
middleware['isSpaceActiveCoachRoom'] = middleware['isSpaceActiveCoachRoom'].default || middleware['isSpaceActiveCoachRoom']

middleware['isSpaceManager'] = require('../middleware/isSpaceManager.js')
middleware['isSpaceManager'] = middleware['isSpaceManager'].default || middleware['isSpaceManager']

middleware['isSpaceMember'] = require('../middleware/isSpaceMember.js')
middleware['isSpaceMember'] = middleware['isSpaceMember'].default || middleware['isSpaceMember']

middleware['isSpaceOwner'] = require('../middleware/isSpaceOwner.js')
middleware['isSpaceOwner'] = middleware['isSpaceOwner'].default || middleware['isSpaceOwner']

middleware['isVideoManager'] = require('../middleware/isVideoManager.js')
middleware['isVideoManager'] = middleware['isVideoManager'].default || middleware['isVideoManager']

middleware['isVideoValid'] = require('../middleware/isVideoValid.js')
middleware['isVideoValid'] = middleware['isVideoValid'].default || middleware['isVideoValid']

middleware['landingPageRedirection'] = require('../middleware/landingPageRedirection.js')
middleware['landingPageRedirection'] = middleware['landingPageRedirection'].default || middleware['landingPageRedirection']

middleware['loginFromOAuth'] = require('../middleware/loginFromOAuth.js')
middleware['loginFromOAuth'] = middleware['loginFromOAuth'].default || middleware['loginFromOAuth']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.js')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['notAuthenticatedAdmin'] = require('../middleware/notAuthenticatedAdmin.js')
middleware['notAuthenticatedAdmin'] = middleware['notAuthenticatedAdmin'].default || middleware['notAuthenticatedAdmin']

middleware['notConfirmSignUp'] = require('../middleware/notConfirmSignUp.js')
middleware['notConfirmSignUp'] = middleware['notConfirmSignUp'].default || middleware['notConfirmSignUp']

middleware['notSetupAccount'] = require('../middleware/notSetupAccount.js')
middleware['notSetupAccount'] = middleware['notSetupAccount'].default || middleware['notSetupAccount']

middleware['redirectLp'] = require('../middleware/redirectLp.js')
middleware['redirectLp'] = middleware['redirectLp'].default || middleware['redirectLp']

middleware['saveLastRoute'] = require('../middleware/saveLastRoute.js')
middleware['saveLastRoute'] = middleware['saveLastRoute'].default || middleware['saveLastRoute']

middleware['setupAccount'] = require('../middleware/setupAccount.js')
middleware['setupAccount'] = middleware['setupAccount'].default || middleware['setupAccount']

middleware['userProfileSpace'] = require('../middleware/userProfileSpace.js')
middleware['userProfileSpace'] = middleware['userProfileSpace'].default || middleware['userProfileSpace']

export default middleware
