import dayjs from '@/plugins/dayjs';

function sortByCreatedAt(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}

export const state = () => ({
  messages: [],
  messageToDelete: null,
  avatarMessageToDelete: null,
  lastKeyInput: null,
  lastPositionInput: null,
});

export const mutations = {
  LIST_CHATS(state, { messages, dayLimitation, isLoadingDown }) {
    const dayLimit = dayLimitation || -1;
    if (dayLimit > 0) {
      const queryFrom = dayjs().add(-dayLimit, 'day').format();
      if (isLoadingDown) {
        messages.forEach((mess) => {
          if (mess.createdAt > queryFrom) state.messages.push(mess);
        });
      } else {
        messages.forEach((mess) => {
          if (mess.createdAt > queryFrom) state.messages.unshift(mess);
        });
      }
    } else if (isLoadingDown) {
      messages.forEach((mess) => state.messages.push(mess));
    } else {
      messages.forEach((mess) => state.messages.unshift(mess));
    }
  },
  CREATE_CHAT(state, message) {
    const { messages } = state;
    state.message = [...messages, message];
  },
  NEW_CHAT(state, message) {
    const { messages } = state;
    if (message.parentID === 'true') {
      state.messages = [...messages, message];
    } else {
      const parentMess = messages.find((mess) => mess.id === message.parentID);
      if (parentMess) {
        parentMess.child.items.push(message);
        // also push to root level of list messages for reactions state
        state.messages = [...messages];
      }
    }
  },
  UPDATE_CHAT(state, message) {
    const { messages } = state;
    const objIndex = messages.findIndex((obj) => obj.id === message.id);
    messages[objIndex] = message;
    state.messages = [...messages];
  },
  DELETE_CHAT(state, message) {
    const { messages } = state;
    const newMessages = messages.filter((item) => item.id !== message.id);
    state.messages = [...newMessages];
  },
  LOAD_MORE_CHATS(state, newMess) {
    const { messages } = state;
    state.messages = messages.concat(newMess);
  },
  SET_MESSAGE_TO_DELETE(state, { message, avatar }) {
    state.messageToDelete = message;
    state.avatarMessageToDelete = avatar;
  },
  RESET_LIST_CHAT(state) {
    state.messages = [];
  },
  UPDATE_DISPLAY_LINKS_FOR_SPACE_CHAT(state, { messageID, visions, goals, issues }) {
    const { messages } = state;
    const objIndex = messages.findIndex((obj) => obj.id === messageID);
    if (objIndex !== -1) {
      messages[objIndex] = {
        ...messages[objIndex],
        visions: { items: visions },
        goals: { items: goals },
        issues: { items: issues },
      };
      state.messages = [...messages];
    }
  },
  SET_LAST_KEY_INPUT(state, key) {
    state.lastKeyInput = key;
  },
  SET_LAST_POSITION_INPUT(state, position) {
    state.lastPositionInput = position;
  },
};

export const actions = {
  async createMessage({ dispatch }, input) {
    try {
      const comment = await dispatch(
        'api/mutate',
        { mutation: 'createChat', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      return comment;
    } catch (error) {
      console.error('create Space Chat', error);
      return Promise.reject();
    }
  },
  async updateMessage({ dispatch }, input) {
    try {
      const comment = await dispatch(
        'api/mutate',
        { mutation: 'updateChat', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      return comment;
    } catch (error) {
      console.error('update Space Chat', error);
      return Promise.reject();
    }
  },
  async deleteChat({ dispatch, state }, input) {
    try {
      const comment = await dispatch(
        'api/mutate',
        { mutation: 'deleteChat', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      const { messages } = state;
      const childChatToDelete = messages.filter((chat) => chat.parentID === input.id);
      childChatToDelete.forEach((chat) => {
        dispatch(
          'api/mutate',
          { mutation: 'deleteChat', input: { id: chat.id } },
          { root: true }, // eslint-disable-line prettier/prettier
        );
      });
      return comment;
    } catch (error) {
      console.error('delete Space Chat', error);
      return Promise.reject();
    }
  },
  async newMessage({ commit }, message) {
    commit('NEW_CHAT', message);
  },
  subscribeChangeMessage({ commit }, message) {
    commit('UPDATE_CHAT', message);
  },
  subscribeDeleteMessage({ commit }, message) {
    commit('DELETE_CHAT', message);
  },

  async queryListChat(
    { commit, dispatch },
    { options, dayLimitation = null, isHighLightList = false, isLoadingDown = false } // eslint-disable-line
  ) {
    try {
      const messagesData = await dispatch(
        'api/query',
        {
          query: 'spaceChatByCreatedAt',
          ...options,
          isHighLightList,
        },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      commit('LIST_CHATS', { messages: messagesData, dayLimitation, isLoadingDown });
      return messagesData.length > 0;
    } catch (error) {
      console.error('query space chats', error);
      return false;
    }
  },

  async setMessageToDelete({ commit }, { message, avatar }) {
    commit('SET_MESSAGE_TO_DELETE', { message, avatar });
  },

  async resetListChat({ commit }) {
    commit('RESET_LIST_CHAT');
  },
  async getChat({ dispatch }, id) {
    try {
      const chatData = await dispatch(
        'api/get',
        { query: 'getChat', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      return chatData;
    } catch (error) {
      console.error('fail to get issue', error);
      return false;
    }
  },
  updateDisplayLinksForSpaceChat({ commit }, { messageID, visions, goals, issues }) {
    commit('UPDATE_DISPLAY_LINKS_FOR_SPACE_CHAT', { messageID, visions, goals, issues });
  },
};

export const getters = {
  listChats: (state) => {
    const { messages } = state;
    return [...messages].sort(sortByCreatedAt);
  },
  messageToDelete: (state) => state.messageToDelete,
  avatarMessageToDelete: (state) => state.avatarMessageToDelete,
  lastKeyInput: (state) => state.lastKeyInput,
  lastPositionInput: (state) => state.lastPositionInput,
};
