import { Auth, Storage } from 'aws-amplify';
import AWS from 'aws-sdk';
import dayjs from '@/plugins/dayjs';

function sortByCreatedAt(a, b) {
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  return 0;
}

export const state = () => ({
  video: {
    description: '',
    user: {},
    likes: {
      items: [],
    },
    comments: {
      items: [],
    },
  },
  groupVideos: [],
  videos: [],
  videoInfo: {},
  videosUser: [],
  videoMembers: [],
});

export const mutations = {
  setVideo(state, video) {
    state.video = video;
  },
  setVideos(state, videos) {
    videos.forEach((video) => {
      video.author = video.profile.name;
    });
    state.videos = videos.sort(sortByCreatedAt);
  },
  setAuthentication(state, signedInUser) {
    state.authentication = signedInUser;
  },
  SET_VIDEO_INFO(state, info) {
    state.videoInfo = info;
  },
  SET_VIDEOS_USER(state, videos) {
    state.videosUser = videos;
  },
  CREATE_VIDEO(state, video) {
    let { videos } = state;
    videos.push(video);
    videos = videos.sort(sortByCreatedAt);
    state.videos = [...videos];
  },
  UPDATE_VIDEO(state, video) {
    const { videos } = state;
    const updateIndex = videos.findIndex((vid) => vid.id === video.id);
    if (updateIndex !== -1) {
      videos[updateIndex] = video;
    } else {
      videos.push(video);
    }
    state.videos = [...videos];
  },
  DELETE_VIDEO(state, video) {
    const { videos } = state;
    state.videos = videos.filter((vid) => vid.id !== video.id);
  },
  CREATE_GROUP_VIDEO(state, video) {
    let { groupVideos } = state;
    groupVideos.push(video);
    groupVideos = groupVideos.sort(sortByCreatedAt);
    state.groupVideos = [...groupVideos];
  },
  UPDATE_GROUP_VIDEO(state, video) {
    const { groupVideos } = state;
    const updateIndex = groupVideos.findIndex((vid) => vid.id === video.id);
    if (updateIndex !== -1) {
      groupVideos[updateIndex] = video;
    } else {
      groupVideos.push(video);
    }
    state.groupVideos = [...groupVideos];
  },
  DELETE_GROUP_VIDEO(state, video) {
    const { groupVideos } = state;
    state.groupVideos = groupVideos.filter((vid) => vid.id !== video.id);
  },
  SET_VIDEO_MEMBERS(state, members) {
    state.videoMembers = members;
  },
  UPDATE_CURRENT_VIDEO(state, updateVideo) {
    const { video: currentVideo } = state;
    currentVideo.isShareAble = updateVideo.isShareAble;
    state.video = { ...currentVideo };
  },
  SET_GROUP_VIDEOS(state, videos) {
    videos.forEach((video) => {
      video.author = video.profile.name;
    });
    state.groupVideos = videos.sort(sortByCreatedAt);
  },
};

export const actions = {
  async getVideo({ commit, dispatch }, id) {
    try {
      const video = await dispatch('api/get', { query: 'getVideo', id }, { root: true });
      video.author = video.profile.name;
      if (video.type !== 'youtube') {
        const { bucket, key } = video.content;
        AWS.config.region = process.env.REGION;
        const credentials = await Auth.currentUserCredentials();
        AWS.config.credentials = credentials;
        const S3 = new AWS.S3({
          apiVersion: '2006-03-01',
        });
        video.videoUrl = S3.getSignedUrl('getObject', { Key: key, Bucket: bucket });
      }
      commit('setVideo', video);
      commit('SET_VIDEO_INFO', video.info);
      return video;
    } catch (error) {
      console.error('get Video', error);
      return Promise.reject();
    }
  },

  async getVideoTutorial({ commit }) {
    try {
      const video = {
        title: process.env.TUTORIAL_TITLE,
        videoID: 'tutorial',
      };
      // const bucket = process.env.BUCKET_VIDEO_CONVERT;
      const key = process.env.TUTORIAL_S3_KEY;
      const bucket = process.env.BUCKET_VIDEO_CONVERT;
      video.content = {
        bucket,
        key,
        region: 'ap-southeast-1',
      };
      AWS.config.region = process.env.REGION;
      const credentials = await Auth.currentUserCredentials();
      AWS.config.credentials = credentials;
      const S3 = new AWS.S3({
        apiVersion: '2006-03-01',
      });
      video.videoUrl = S3.getSignedUrl('getObject', { Key: key, Bucket: bucket });
      commit('setVideo', video);
      return video;
    } catch (error) {
      console.error('get Video', error);
      return Promise.reject();
    }
  },

  async getVideoBasic({ dispatch }, id) {
    try {
      const video = await dispatch('api/get', { query: 'getVideo', id }, { root: true });
      return video;
    } catch (error) {
      console.error('get Video', error);
      return Promise.reject();
    }
  },

  async getVideoGuest({ commit, dispatch }, id) {
    try {
      const video = await dispatch('api/getPublic', { query: 'getVideoGuest', id }, { root: true });
      video.author = video.profile.name;
      if (video.type !== 'youtube') {
        const { bucket, key } = video.content;
        AWS.config.region = process.env.REGION;
        const credentials = await Auth.currentUserCredentials();
        AWS.config.credentials = credentials;
        const S3 = new AWS.S3({
          apiVersion: '2006-03-01',
        });
        video.videoUrl = S3.getSignedUrl('getObject', { Key: key, Bucket: bucket });
      }
      commit('setVideo', video);
      commit('SET_VIDEO_INFO', video.info);
      return video;
    } catch (error) {
      console.error('get Video', error);
      return Promise.reject();
    }
  },

  async updateVideo({ commit, dispatch }, input) {
    try {
      const video = await dispatch(
        'api/mutate',
        { mutation: 'updateVideo', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      if (video.type !== 'youtube') {
        const { bucket, key } = video.content;
        AWS.config.region = process.env.REGION;
        const credentials = await Auth.currentUserCredentials();
        AWS.config.credentials = credentials;
        const S3 = new AWS.S3({
          apiVersion: '2006-03-01',
          params: { Bucket: process.env.BUCKET_VIDEO_CONVERT },
        });
        video.videoUrl = S3.getSignedUrl('getObject', { Key: key, Bucket: bucket });
      }
      commit('setVideo', video);
      return video;
    } catch (error) {
      console.error('update Video', error);
      return Promise.reject();
    }
  },
  async deleteVideo({ commit, dispatch, state }, deleteId) {
    const { spaceID, capacity } = await dispatch(
      'api/mutate',
      { mutation: 'updateVideo', input: { id: deleteId, status: 'reject' } },
      { root: true } // eslint-disable-line comma-dangle
    );
    dispatch('space/updateSpaceVideoCapacity', { spaceID, capacity }, { root: true });
    const videos = state.videos.filter((video) => video.id !== deleteId);
    commit('setVideos', videos);
  },
  async listVideos({ dispatch }, { filter, limit, nextToken }) {
    try {
      const videosData = await dispatch(
        'api/query',
        { query: 'listVideos', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      return videosData;
    } catch (error) {
      console.error('get Videos', error);
      return Promise.reject();
    }
  },
  async getVideos({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const videosData = await dispatch(
        'api/query',
        { query: 'listVideos', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('setVideos', videosData);
      return videosData;
    } catch (error) {
      console.error('get Videos', error);
      return Promise.reject();
    }
  },
  async createVideo({ commit, dispatch }, input, infoInput = {}) {
    try {
      const videoInfo = await dispatch(
        'api/mutate',
        { mutation: 'createVideoInfo', input: infoInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      const video = await dispatch(
        'api/mutate',
        { mutation: 'createVideo', input: { ...input, infoID: videoInfo.id } },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('CREATE_VIDEO', video);
      commit('setVideo', video);
      commit('SET_VIDEO_INFO', videoInfo);
      return video;
    } catch (error) {
      console.error('create Videos', error);
      return Promise.reject();
    }
  },
  async updateVideoInfo({ commit, dispatch }, input) {
    try {
      const videoInfo = await dispatch(
        'api/mutate',
        { mutation: 'updateVideoInfo', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_VIDEO_INFO', videoInfo);
      return Promise.resolve();
    } catch (error) {
      console.error('update Video Info', error);
      return Promise.reject();
    }
  },

  async updateVideoInfoPublic({ commit, dispatch }, input) {
    try {
      const videoInfo = await dispatch(
        'api/mutatePublic',
        { mutation: 'updateVideoInfo', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_VIDEO_INFO', videoInfo);
      return Promise.resolve();
    } catch (error) {
      console.error('update Video Info', error);
      return Promise.reject();
    }
  },

  async listGroupVideos({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const groupVideoData = await dispatch(
        'api/queryAll',
        { query: 'listGroupVideos', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit(
        'SET_GROUP_VIDEOS',
        groupVideoData
          .map((groupVideo) => groupVideo.video)
          .filter((video) => video && ['approve', 'upload'].includes(video.status)) // eslint-disable-line comma-dangle
      );
      return groupVideoData;
    } catch (error) {
      console.error('fail to list Group Video', error);
      return false;
    }
  },

  async publicVideoToGroups({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'createGroupVideo', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to public Video To Groups', error);
      return Promise.reject();
    }
  },

  async stopPublicVideoToGroups({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'deleteGroupVideo', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to stop public Video To Groups', error);
      return Promise.reject();
    }
  },
  async getVideosSpace({ dispatch, commit }, { filter, limit, nextToken }) {
    try {
      const videos = await dispatch(
        'api/query',
        { query: 'listVideos', filter, limit, nextToken },
        { root: true } // eslint-disable-line
      );
      commit('SET_VIDEOS_USER', videos);
      return videos;
    } catch (error) {
      console.error('get videos user error: ', error);
      return Promise.reject();
    }
  },
  async getVideoMembers({ dispatch, commit }, { filter, limit, nextToken }) {
    try {
      const members = await dispatch(
        'api/query',
        { query: 'listProfiles', filter, limit, nextToken },
        { root: true } // eslint-disable-line
      );
      const returnData = [];
      Promise.all(
        members.map(async (mem) => {
          const key = mem.avatar?.key;
          if (key) {
            mem.avatarUrl = await Storage.get(key?.normalize('NFD'));
          }
          returnData.push(mem);
        }) // eslint-disable-line comma-dangle
      ).then(() => {
        commit('SET_VIDEO_MEMBERS', returnData);
      });
    } catch (error) {
      console.error('get videos member error: ', error);
      return Promise.reject();
    }
  },

  async createVideoAttachments({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'createVideoAttachment', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('create video attachment error: ', error);
      return Promise.reject();
    }
  },

  async deleteVideoAttachments({ dispatch }, id) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'deleteVideoAttachment', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('delete video attachment error: ', error);
      return Promise.reject();
    }
  },
  async listVideosBySpace(
    { commit, dispatch },
    { spaceID, filter, limit, nextToken, isMutateListVideos = true, ...variables } // eslint-disable-line
  ) {
    try {
      const data = await dispatch(
        'api/query',
        { query: 'bySpaceIDCreatedAt', spaceID, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      if (isMutateListVideos) {
        commit('setVideos', data);
      }
      return data;
    } catch (error) {
      console.error('fail to list videos by space', error);
      return false;
    }
  },
};

export const getters = {
  video: (state, getter, rootState) => {
    const dayLimit = rootState.space.currentSpace.dayLimitation || -1;
    if (dayLimit > 0) {
      const queryFrom = dayjs().add(-dayLimit, 'day').format();
      if (state.video.createdAt < queryFrom) return {};
    }
    return state.video;
  },
  videos: (state, getter, rootState) => {
    const dayLimit = rootState.space.currentSpace.dayLimitation || -1;
    if (dayLimit > 0) {
      const queryFrom = dayjs().add(-dayLimit, 'day').format();
      return state.videos.filter((video) => video.createdAt > queryFrom);
    }
    return state.videos;
  },
  groupVideos: (state, getter, rootState) => {
    const dayLimit = rootState.space.currentSpace.dayLimitation || -1;
    if (dayLimit > 0) {
      const queryFrom = dayjs().add(-dayLimit, 'day').format();
      return state.groupVideos.filter((video) => video.createdAt > queryFrom);
    }
    return state.groupVideos;
  },
  authentication: (state) => state.authentication,
  videoInfo: (state) => state.videoInfo,
  videosUser: (state) => state.videosUser,
  videoMembers: (state) => state.videoMembers,
};
