export const state = () => ({
  listUserAccounts: [],
});

export const mutations = {
  SET_LIST_ACCOUNTS(state, lists) {
    state.listUserAccounts = lists;
  },
  ADD_ACCOUNT(state, newAcc) {
    const { listUserAccounts } = state;
    listUserAccounts.push(newAcc);
    state.listUserAccounts = [...listUserAccounts];
  },
  UPDATE_ACCOUNT(state, newAcc) {
    const { listUserAccounts } = state;
    let updateIndex = null;
    listUserAccounts.forEach((acc, index) => {
      if (acc.id === newAcc.id) updateIndex = index;
    });
    listUserAccounts[updateIndex] = newAcc;
    state.listUserAccounts = [...listUserAccounts];
  },
};

export const actions = {
  async getUserAccounts({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const data = await dispatch(
        'api/query',
        { query: 'listUserAccounts', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_LIST_ACCOUNTS', data);
      return data;
    } catch (error) {
      console.error('fail to list user account', error);
      return false;
    }
  },

  async getUserAccountsPublic({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const data = await dispatch(
        'api/queryPublic',
        { query: 'listUserAccounts', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_LIST_ACCOUNTS', data);
      return data;
    } catch (error) {
      console.error('fail to list user account public:', error);
      return false;
    }
  },

  async createUserAccount({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'createUserAccount', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to create user account', error);
      return false;
    }
  },

  async updateUserAccount({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'updateUserAccount', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to update user account', error);
      return false;
    }
  },

  async deleteUserAccount({ dispatch }, id) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'deleteUserAccount', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to delete user account', error);
      return Promise.reject();
    }
  },
  async subscriptionAddUserAccount({ commit }, newAccount) {
    commit('ADD_ACCOUNT', newAccount);
  },
  async subscriptionUpdateUserAccount({ commit }, newAccount) {
    commit('UPDATE_ACCOUNT', newAccount);
  },
};

export const getters = {
  listUserAccounts: (state) => state.listUserAccounts,
};
