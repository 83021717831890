import CryptoJS from 'crypto-js';

function encryptMessage(message) {
  const secretKey = process.env.SECRET_ENCRYPT_KEY;
  return CryptoJS.AES.encrypt(message, secretKey).toString();
}

function decryptMessage(ciphertext) {
  try {
    const secretKey = process.env.SECRET_ENCRYPT_KEY;
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    if (!bytes) return false;

    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    console.log(e.message);
    return false;
  }
}

export default {
  encryptMessage,
  decryptMessage,
};
