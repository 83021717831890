export default async ({ store, route, redirect }) => {
  const videoID = route.params.id;
  await store.dispatch('video/getVideo', videoID);
  const {
    currentUser: { id: userID },
  } = store.state.auth;
  const { currentSpace } = store.state.space;
  const { video } = store.state.video;
  const spaceEditors = currentSpace.editors || [];
  const {
    userSpaceProfile: { id: profileID },
  } = store.state.profile;
  if (
    currentSpace.userID !== userID &&
    !spaceEditors.includes(userID) &&
    video.profileID !== profileID
  ) {
    return redirect(`/space/${currentSpace.id}/video`);
  }
};
