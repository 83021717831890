/*
  alert type:
  - alert-success
  - alert-error
*/

export const state = () => ({
  listAlert: [],
});

export const mutations = {
  ADD_ALERT(state, alert) {
    const { listAlert } = state;
    listAlert.unshift(alert);
    state.listAlert = [...listAlert];
  },
  REMOVE_ALERT(state, id) {
    state.listAlert = state.listAlert.filter((alert) => alert.id !== id);
  },
};

export const actions = {
  addAlert({ commit, dispatch }, { type, message }) {
    if (type === 'alert-error' && !message) {
      message = 'エラーが発生しました。';
    }
    const alert = {
      id: Date.now(),
      type,
      message,
    };
    commit('ADD_ALERT', alert);

    setTimeout(() => {
      dispatch('removeAlert', alert.id);
    }, 5000);
  },

  removeAlert({ commit }, id) {
    commit('REMOVE_ALERT', id);
  },
};

export const getters = {
  listAlert: (state) => state.listAlert,
};
