const enLocale = {
  common: {
    success: 'Success!',
    error: 'Error!',
    errorWithMessage: 'Error! Cause: {message}',
    updated: 'Updated!',
    copied: 'Copied!',
    nameTaken: 'This name is already taken',
  },
  auth: {
    incorrectLogin: 'Incorrect username or password',
    invalidUser: 'Account invalid. Please contact administrator for more information',
    registerError: 'Error during registration. Cause: {message}',
    alreadyRegistered: 'You are already registered. Resend verification code',
    forgotPasswordError: '',
    loginError: 'Login error! Cause: {message}',
    checkVerifyCode: 'Please check your verification code in {email}',
    userAlreadyConfirmed: 'User is already confirmed',
    confirmCodeError: 'Invalid verification code provided. Please try again',
    sendResetPasswordEmail: 'We have sent you a password reset email.',
  },
  user: {
    createError: "Can't create user",
    deactivated: 'This account have been deactivated',
    adminEnabled: 'Enable success',
    adminDisabled: 'Disable success',
    notFound: "Can't found this user",
    emailUpdated: 'Email address update is complete',
    nameUpdated: 'The user name change is complete.',
    passwordUpdated: 'The password change is complete.',
  },
  space: {
    deleted: 'This space have been deleted',
    nameTaken: 'This name is already taken',
    spaceLimitMember: 'Space have exceed the maximum number of members',
    invitationExpired: 'Your invitation to join this space is expired',
    invitationInvalid: 'Your invitation to join this space is invalid',
    alreadyJoined: 'You are already joined this space',
    noPlan: 'No plan have been set',
  },
  group: {
    deleted: 'This group have been deleted',
    nameTaken: 'This name is already taken',
  },
  profile: {
    nameTaken: 'Profile name is already taken',
    updateProfile: 'Profile change is complete',
  },
  video: {
    upload: {
      groupRequired: 'Please select a group.',
      spaceLimitUpload: 'The upload capacity has been exceeded.',
    },
    share: {
      invalid: 'Shared video link is invalid',
    },
    changeToGroupError: 'This video have been changed to public to groups',
    changeToSpaceError: 'This video have been changed to public to space',
    delete: 'The video has been deleted',
  },
  upload: {
    s3Error: 'Upload error! Cause: {message}',
    sizeLimit2MB: "File size can't exceed 2MB",
    sizeLimit5MB: "File size can't exceed 5MB",
    sizeLimit10MB: "File size can't exceed 10MB",
    imageLimit2MB: "Image size can't exceed 2MB",
    blankError: "File can't be blank",
    imageUploadComplete: 'The image upload is complete',
    videoUploadComplete: 'The video upload is complete.',
  },
  payJP: {
    alreadyAddCard: 'This card is already added',
  },
  admin: {
    space: {
      userAlreadyAdd: 'This user already been added',
    },
  },
};

export default enLocale;
