const userStoredKeys = 'userStoredKeys';

export const state = () => ({
  userStoredKeys: [],
});

export const mutations = {
  SET_STORED_KEYS(state, { storage, keys }) {
    state[storage] = keys;
  },
  ADD_STORED_KEY(state, { storage, key }) {
    const storageToAdd = state[storage];
    const find = storageToAdd.find((item) => item === key);
    if (!find) {
      storageToAdd.push(key);
      state[storage] = [...storageToAdd];
    }
  },
  REMOVE_STORED_KEY(state, storage) {
    const storageToRemove = state[storage];
    storageToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
    state[storage] = [];
  },
};

export const actions = {
  setItemUserStorage({ commit }, { key, value }) {
    if (key && value) {
      commit('ADD_STORED_KEY', { storage: userStoredKeys, key });
      localStorage.setItem(key, value);
    } else {
      console.log(`storage input is invalid, key: ${key}, value: ${value}`);
    }
  },

  removeUserStorage({ commit }) {
    commit('REMOVE_STORED_KEY', userStoredKeys);
  },
};

export const getters = {};
