export const state = () => ({
  isLoading: true,
  isLoadingChat: false,
  isLoadingChatScrollDown: false,
});

export const mutations = {
  ON_LOADING(state) {
    state.isLoading = true;
  },
  OFF_LOADING(state) {
    state.isLoading = false;
  },
  ON_LOADING_CHAT(state) {
    state.isLoadingChat = true;
  },
  OFF_LOADING_CHAT(state) {
    state.isLoadingChat = false;
  },
  ON_LOADING_CHAT_DOWN(state) {
    state.isLoadingChatScrollDown = true;
  },
  OFF_LOADING_CHAT_DOWN(state) {
    state.isLoadingChatScrollDown = false;
  },
};

export const actions = {
  setOnLoading({ commit }) {
    commit('ON_LOADING');
  },

  setOffLoading({ commit }) {
    commit('OFF_LOADING');
  },

  setOnLoadingChat({ commit }) {
    commit('ON_LOADING_CHAT');
  },

  setOffLoadingChat({ commit }) {
    commit('OFF_LOADING_CHAT');
  },

  setOnLoadingChatDown({ commit }) {
    commit('ON_LOADING_CHAT_DOWN');
  },

  setOffLoadingChatDown({ commit }) {
    commit('OFF_LOADING_CHAT_DOWN');
  },
};

export const getters = {
  isLoading: (state) => state.isLoading,
  isLoadingChat: (state) => state.isLoadingChat,
  isLoadingChatScrollDown: (state) => state.isLoadingChatScrollDown,
};
