//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import flashNewNotify from '@/mixins/flashNewNotify';

export default {
  mixins: [flashNewNotify],
  middleware: ['landingPageRedirection'],
  head: {
    bodyAttrs: {
      'cz-shortcut-listen': 'true',
    },
  },
  watch: {
    $route: {
      handler: function route() {
        this.$nextTick(() => {
          this.$store.dispatch('loading/setOffLoading');
        });
      },
      deep: true,
    },
  },
  errorCaptured(err) {
    this.$store.dispatch('loading/setOffLoading');
    console.log(err);
    this.addAlert({
      type: 'alert-error',
    });
    return false;
  },
  mounted() {
    this.$store.dispatch('loading/setOffLoading');
  },
  methods: {
    ...mapActions('alertError', ['addAlert']),
  },
};
