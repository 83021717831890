export default async ({ store, redirect, route }) => {
  const spaceId = route.path.split('/')[2];
  const input = {
    spaceId,
    userId: store.state.auth.currentUser.id,
  };
  await store.dispatch('profile/getUserSpaceProfile', input);
  const { newSpace } = route.query;
  store.dispatch('localStoreManager/setItemUserStorage', {
    key: `${store.state.auth.currentUser.id}-spaceID`,
    value: spaceId,
  });
  const { id, confirmed, status } = store.state.profile.userSpaceProfile;
  if (status !== 'active') {
    return redirect('/space');
  }
  if ((newSpace !== 'true' && !id) || (id && !confirmed)) {
    return redirect(`/space/${spaceId}/profile/edit`);
  }
};
