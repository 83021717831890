function getNotifyFlashMess(notify) {
  const notifyName = notify.profile?.name;
  const spaceName = notify.space?.name;
  const groupName = notify.group?.name;
  const groupNames = (notify.video?.publicToGroup?.items || []).map((item) => item.group.name);
  if (notify.type === 'likeVideo') {
    const videoName = notify.video.title;
    return `${notifyName}さんが #${videoName} であなたをリアクションしました。`;
  }
  if (notify.type === 'likeChat') {
    const {
      chat: { groupID },
    } = notify;
    if (groupID) {
      return `${notifyName}さんが #${groupName} であなたをリアクションしました。`;
    }
    return `${notifyName}さんが #${spaceName} であなたをリアクションしました。`;
  }
  if (notify.type === 'likeComment') {
    const videoName = notify.video.title;
    return `${notifyName}さんが #${videoName} であなたをリアクションしました。`;
  }
  if (notify.type === 'uploadVideoSpace') {
    return `${notifyName}さんが #${spaceName} で動画を投稿しました。`;
  }
  if (notify.type === 'uploadVideoGroup') {
    return `${notifyName}さんが #${groupNames.join(', #')} で動画を投稿しました。`;
  }
  if (notify.type === 'replyChat') {
    const {
      chat: { groupID: replyGroupID },
    } = notify;
    if (replyGroupID) {
      return `#${groupName}内、${notifyName}がメッセージに返信しました。`;
    }
    return `#${spaceName}内、${notifyName}がメッセージに返信しました。`;
  }
  if (notify.type === 'mentionChat') {
    const {
      chat: { groupID: mentionGroupID },
    } = notify;
    if (mentionGroupID) {
      return `${notifyName}さんが #${groupName} であなたをメンションしました。`;
    }
    return `${notifyName}さんが #${spaceName} であなたをメンションしました。`;
  }
  if (notify.type === 'commentVideo') {
    return `${notifyName}が動画にコメントしました。`;
  }
  if (notify.type === 'replyComment') {
    return `${notifyName}が動画のコメントに返信しました。`;
  }
  if (notify.type === 'mentionComment') {
    const videoName = notify.video.title;
    return `${notifyName}さんが ${videoName} であなたをメンションしました。`;
  }
  if (notify.type === 'requestJoinSpace') {
    return `${notify.user.name}からのお知らせ`;
  }
  if (notify.type === 'createEditVision') {
    return 'ビジョンが変更されました';
  }
  if (notify.type === 'newGoal') {
    return '目標が新規追加されました';
  }
  if (notify.type === 'editGoal') {
    return '目標が編集されました';
  }
  if (notify.type === 'newIssue') {
    return '課題が追加されました';
  }
  if (notify.type === 'editIssue') {
    return '課題が編集されました';
  }
  if (notify.type === 'goalComplete') {
    return '目標が完了しました';
  }
  if (notify.type === 'issueComplete') {
    return '課題が完了しました';
  }
  if (notify.type === 'editIssueProgress') {
    return '課題の進捗度が変更されました';
  }
  if (notify.type === 'setCoach') {
    return 'コーチとして設定されました';
  }
  if (notify.type === 'removeCoach') {
    return 'コーチではなくなりました。';
  }
  if (notify.type === 'issueOverDue') {
    return '期限が超過した目標/課題があります。';
  }
}

export default {
  getNotifyFlashMess,
};
