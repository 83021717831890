export const state = () => ({
  listReactions: {},
});

export const mutations = {
  SET_LIST_REACTIONS(state, { targetID, reactions }) {
    const { listReactions } = state;
    state.listReactions = { ...listReactions, [targetID]: reactions };
  },
  ADD_ITEM_REACTIONS(state, { userProfileID, reaction }) {
    const { targetID, emoji, profile, profileID, id } = reaction;
    const { listReactions } = state;
    if (listReactions[targetID]) {
      if (listReactions[targetID][emoji]) {
        const { count, likeID, liked } = listReactions[targetID][emoji];

        listReactions[targetID][emoji].count = count + 1;
        listReactions[targetID][emoji].liked = userProfileID === profileID || liked;
        listReactions[targetID][emoji].likeID = userProfileID === profileID ? id : likeID;
        listReactions[targetID][emoji].listUserReaction.push(profile);
        state.listReactions = { ...listReactions };
      } else {
        listReactions[targetID][emoji] = {
          count: 1,
          liked: userProfileID === profileID,
          likeID: userProfileID === profileID ? id : 0,
          listUserReaction: [profile],
        };
        state.listReactions = { ...listReactions };
      }
    } else {
      const reactions = {
        [emoji]: {
          count: 1,
          liked: userProfileID === profileID,
          likeID: userProfileID === profileID ? id : 0,
          listUserReaction: [profile],
        },
      };
      state.listReactions = { ...listReactions, [targetID]: reactions };
    }
  },
  DELETE_ITEM_REACTIONS(state, { userProfileID, reaction }) {
    const { targetID, emoji, profileID } = reaction;
    const { listReactions } = state;

    const { count, listUserReaction, liked, likeID } = listReactions[targetID][emoji];

    if (count <= 1) {
      delete listReactions[targetID][emoji];
      state.listReactions = { ...listReactions };
    } else {
      listReactions[targetID][emoji].count = count - 1;
      listReactions[targetID][emoji].liked = userProfileID === profileID ? false : liked;
      listReactions[targetID][emoji].likeID = userProfileID === profileID ? 0 : likeID;
      listReactions[targetID][emoji].listUserReaction = listUserReaction.filter(
        (profile) => profile.id !== profileID // eslint-disable-line comma-dangle
      );

      state.listReactions = { ...listReactions };
    }
  },
  RESET_REACTIONS(state) {
    state.listReactions = {};
  },
};

export const actions = {
  async getListReactions({ commit }, { userProfileID, targetID, reactions }) {
    const returnData = {};
    reactions.forEach((item) => {
      if (returnData[item.emoji]) {
        const { count, likeID, liked, listUserReaction } = returnData[item.emoji];
        listUserReaction.push(item.profile);
        returnData[item.emoji] = {
          count: count + 1,
          liked: userProfileID === item.profileID || liked,
          likeID: userProfileID === item.profileID ? item.id : likeID,
          listUserReaction,
        };
      } else {
        returnData[item.emoji] = {
          count: 1,
          liked: userProfileID === item.profileID,
          likeID: userProfileID === item.profileID ? item.id : 0,
          listUserReaction: [item.profile],
        };
      }
    });
    commit('SET_LIST_REACTIONS', { targetID, reactions: returnData });
  },

  async addReaction({ commit }, { userProfileID, reaction }) {
    commit('ADD_ITEM_REACTIONS', { userProfileID, reaction });
  },

  async deleteReaction({ commit }, { userProfileID, reaction }) {
    commit('DELETE_ITEM_REACTIONS', { userProfileID, reaction });
  },
  async resetReaction({ commit }) {
    commit('RESET_REACTIONS');
  },
};

export const getters = {
  listReactions: (state) => state.listReactions,
};
