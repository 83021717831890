//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  props: {
    layout: {
      type: String,
      default: 'sidebarless',
    },
  },
  computed: {
    ...mapGetters('alertError', ['listAlert']),
    faTimes() {
      return faTimes;
    },
  },
  methods: {
    ...mapActions('alertError', ['removeAlert']),
    onRemoveAlert(alert) {
      this.removeAlert(alert.id);
    },
    cardClass(alert) {
      let returnClass = `${alert.type} `;
      if (this.layout === 'default') {
        returnClass += 'is-layout-default';
      } else {
        returnClass += 'is-layout-sidebarless';
      }
      return returnClass;
    },
  },
};
