import AWS from 'aws-sdk';
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import getVideoId from 'get-video-id';
import { onCreateNotification, onUpdateNotification } from '@/graphql/customSubscriptions';
import awsconfig from '@/aws-exports';
import * as Cookie from '@/lib/util/cookies';
import getNotifyFlashMess from '@/lib/notificationTemplates/notifyFlashMess';

const sourceEmail = process.env.DEFAULT_EMAIL_ADDRESS;
const emailApiVersion = process.env.EMAIL_API_VERSION;
const currentOrigin = window.location.origin;

const cachedData = {
  spaces: {},
  videos: {},
  groups: {},
};

async function pushFireBaseNotification({ notificationData, redirectUrl }) {
  if (!notificationData.fcmToken) return;
  const notificationMessage = await getNotifyFlashMess.getNotifyFlashMess(notificationData);
  const { ...value } = await API.post('firebaseCloudMessage', '/pushFirebaseMessage', {
    body: {
      title: 'KnowledgeLoop',
      body: notificationMessage,
      fcmToken: notificationData.fcmToken,
      redirectUrl: redirectUrl || currentOrigin,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  });
  return value;
}

export const state = () => ({
  notifications: {
    uploadVideoSpace: [],
    uploadVideoGroup: [],
    commentVideo: [],
    replyChat: [],
    replyComment: [],
    mentionChat: [],
    mentionComment: [],
    requestJoinSpace: [],
    likeVideo: [],
    likeChat: [],
    likeComment: [],
    newMessage: [],
    answerSpaceRequest: [],
    joinGroup: [],
    deActiveSpace: [],
    deActiveGroup: [],
    addToGroup: [],
    billFail: [],
    usageExceed: [],
    downgrade: [],
    retryPayment: [],
    createEditVision: [],
    newGoal: [],
    editGoal: [],
    newIssue: [],
    editIssue: [],
    goalComplete: [],
    issueComplete: [],
    editIssueProgress: [],
    setCoach: [],
    removeCoach: [],
    issueOverDue: [],
  },
  createNotificationChannel: null,
  updateNotificationChannel: null,
  newNotify: null,
});

export const mutations = {
  SET_NOTIFICATIONS(state, fetchNotifications) {
    const { notifications } = state;
    fetchNotifications.forEach((noti) => {
      const { type } = noti;
      try {
        notifications[type].push(noti);
      } catch (error) {
        console.log(error);
      }
    });
    state.notifications = { ...notifications };
  },
  ADD_NOTIFICATION(state, newNotify) {
    const { type } = newNotify;
    const { notifications } = state;
    const listNotifiesIDs = [...notifications[type]].map((noti) => noti.id);
    if (listNotifiesIDs.includes(newNotify.id)) return;
    notifications[type].push(newNotify);
    state.notifications = { ...notifications };
  },
  UPDATE_NOTIFICATION(state, updateNotify) {
    const { type } = updateNotify;
    const { notifications } = state;
    const listNotifies = [...notifications[type]];
    const findIndex = listNotifies.findIndex((notify) => notify.id === updateNotify.id);
    if (findIndex > -1) {
      listNotifies[findIndex] = updateNotify;
      state.notifications = { ...notifications, [type]: listNotifies };
    }
  },
  CHECK_NOTIFICATIONS(state, { type, notifyIDs = null }) {
    const { notifications } = state;
    const typeNotifications = [...notifications[type]];
    if (notifyIDs) {
      typeNotifications.forEach((notify) => {
        if (notifyIDs.includes(notify.id)) {
          notify.status = 'check';
          notify.statusBadge = false;
        }
      });
    } else {
      typeNotifications.forEach((notify) => {
        notify.status = 'check';
        notify.statusBadge = false;
      });
    }
    state.notifications = { ...notifications, [type]: typeNotifications };
  },
  CHECK_BADGES(state, { type, notifyIDs = null }) {
    const { notifications } = state;
    const typeNotifications = [...notifications[type]];
    if (notifyIDs) {
      typeNotifications.forEach((notify) => {
        if (notifyIDs.includes(notify.id)) {
          notify.statusBadge = false;
        }
      });
    } else {
      typeNotifications.forEach((notify) => {
        notify.statusBadge = false;
      });
    }
    state.notifications = { ...notifications, [type]: typeNotifications };
  },
  SET_NOTIFICATION_CHANNEL(state, { createNotificationChannel, updateNotificationChannel }) {
    state.createNotificationChannel = createNotificationChannel;
    state.updateNotificationChannel = updateNotificationChannel;
  },
  STOP_NOTIFICATION_CHANNEL(state) {
    if (state.createNotificationChannel) {
      state.createNotificationChannel.unsubscribe();
    }
    if (state.updateNotificationChannel) {
      state.updateNotificationChannel.unsubscribe();
    }
    state.createNotificationChannel = null;
    state.updateNotificationChannel = null;
  },
  FLASH_NEW_NOTIFY(state, newNotify) {
    state.newNotify = newNotify;
  },
  RESET_LIST_NOTIFICATIONS(state) {
    state.notifications = {
      uploadVideoSpace: [],
      uploadVideoGroup: [],
      commentVideo: [],
      replyChat: [],
      replyComment: [],
      mentionChat: [],
      mentionComment: [],
      requestJoinSpace: [],
      likeVideo: [],
      likeChat: [],
      likeComment: [],
      newMessage: [],
      answerSpaceRequest: [],
      joinGroup: [],
      deActiveSpace: [],
      deActiveGroup: [],
      addToGroup: [],
      billFail: [],
      usageExceed: [],
      downgrade: [],
      retryPayment: [],
      createEditVision: [],
      newGoal: [],
      editGoal: [],
      newIssue: [],
      editIssue: [],
      goalComplete: [],
      issueComplete: [],
      editIssueProgress: [],
      setCoach: [],
      removeCoach: [],
      issueOverDue: [],
    };
  },
};

export const actions = {
  async preFetchCache({ dispatch }, notifies = []) {
    const spaces = {};
    const videos = {};
    const groups = {};
    notifies.forEach((notify) => {
      if (!cachedData.spaces[notify.spaceID] && notify.spaceID && !spaces[notify.spaceID]) {
        spaces[notify.spaceID] = true;
      }
      if (!cachedData.videos[notify.videoID] && notify.videoID && !videos[notify.videoID]) {
        videos[notify.videoID] = true;
      }
      if (!cachedData.groups[notify.groupID] && notify.groupID && !groups[notify.groupID]) {
        groups[notify.groupID] = true;
      }
    });
    const spacesPromises = Object.keys(spaces).map(async (spaceID) => {
      const space = await dispatch(
        'api/get',
        { query: 'getSpace', id: spaceID },
        { root: true } // eslint-disable-line comma-dangle
      );
      cachedData.spaces[spaceID] = space;
    });
    const videoPromises = Object.keys(videos).map(async (videoID) => {
      const space = await dispatch(
        'api/get',
        { query: 'getVideo', id: videoID },
        { root: true } // eslint-disable-line comma-dangle
      );
      cachedData.videos[videoID] = space;
    });
    const groupPromises = Object.keys(groups).map(async (groupID) => {
      const group = await dispatch(
        'api/get',
        { query: 'getGroup', id: groupID },
        { root: true } // eslint-disable-line comma-dangle
      );
      cachedData.groups[groupID] = group;
    });
    await Promise.allSettled([...spacesPromises, ...videoPromises, ...groupPromises]);
  },
  async fetchNotificationInfo({ dispatch }, notify) {
    try {
      if (
        !['likeComment', 'likeChat', 'requestJoinSpace'].includes(notify.type) &&
        notify.profile?.avatar?.key
      ) {
        notify.profileAvatar = await Storage.get(notify.profile.avatar?.key?.normalize('NFD'));
      }
      if (notify.spaceID) {
        let space = null;
        if (cachedData.spaces[notify.spaceID]) {
          space = cachedData.spaces[notify.spaceID];
        } else {
          space = await dispatch(
            'api/get',
            { query: 'getSpace', id: notify.spaceID },
            { root: true } // eslint-disable-line comma-dangle
          );
          cachedData.spaces[notify.spaceID] = { ...space };
        }
        if (!space || space.status === 'inactive') return null;
        notify.space = space;
      }
      if (
        notify.groupID &&
        ['likeChat', 'replyChat', 'mentionChat', 'deActiveGroup', 'addToGroup'].includes(
          notify.type // eslint-disable-line
        )
      ) {
        let group = null;
        if (cachedData.groups[notify.groupID]) {
          group = cachedData.groups[notify.groupID];
        } else {
          group = await dispatch(
            'api/get',
            { query: 'getGroup', id: notify.groupID },
            { root: true } // eslint-disable-line comma-dangle
          );
          cachedData.groups[notify.groupID] = { ...group };
        }
        if ((notify.type !== 'deActiveGroup' && !group) || group.status === 'inactive') {
          return null;
        }
        notify.group = group;
      }
      if (notify.messageID) {
        if (
          ['commentVideo', 'replyComment', 'mentionComment', 'likeComment'].includes(
            notify.type // eslint-disable-line comma-dangle
          )
        ) {
          const commentData = await dispatch(
            'api/get',
            { query: 'getComment', id: notify.messageID },
            { root: true } // eslint-disable-line comma-dangle
          );
          if (!commentData) return null;
          notify.comment = commentData;
          if (commentData && commentData.profile.avatar?.key) {
            notify.profileAvatar = await Storage.get(
              commentData.profile.avatar?.key?.normalize('NFD') // eslint-disable-line
            );
          }
        }
        if (['replyChat', 'mentionChat', 'likeChat', 'newMessage'].includes(notify.type)) {
          if (notify.groupID) {
            const chatData = await dispatch(
              'api/get',
              { query: 'getChatGroup', id: notify.messageID },
              { root: true } // eslint-disable-line comma-dangle
            );
            if (!chatData) return null;
            notify.chat = chatData;
          } else if (notify.spaceID) {
            const chatData = await dispatch(
              'api/get',
              { query: 'getChat', id: notify.messageID },
              { root: true } // eslint-disable-line comma-dangle
            );
            if (!chatData) return null;
            notify.chat = chatData;
          }
          if (notify.chat && notify.chat.profile.avatar?.key) {
            notify.profileAvatar = await Storage.get(
              notify.chat.profile.avatar?.key?.normalize('NFD') // eslint-disable-line
            );
          }
          if ((notify?.chat?.attachedVideos?.items || []).length > 0) {
            const videoIDs = notify.chat.attachedVideos.items.map((item) => ({
              id: { eq: item.videoID },
            }));
            const filter = { or: videoIDs };
            notify.videos = await dispatch(
              'api/query',
              { query: 'listVideos', filter, limit: 10000 },
              { root: true } // eslint-disable-line comma-dangle
            );
          }
        }
      }
      if (
        ['requestJoinSpace', 'mentionComment', 'mentionChat'].includes(notify.type) &&
        notify.isPushFBNotification
      ) {
        const customQueries = `
        query GetUser($id: ID!) {
          getUser(id: $id) {
            fcmToken
          }
        }
      `;
        const {
          data: { getUser: userData },
        } = await API.graphql(
          graphqlOperation(customQueries, { id: notify.receiverID }) // eslint-disable-line
        );
        notify.fcmToken = userData?.fcmToken;
      }
      if (notify.type === 'requestJoinSpace') {
        const customQueries = `
        query GetUser($id: ID!) {
          getUser(id: $id) {
            name
            avatar {
              key
            }
          }
        }
      `;
        const {
          data: { getUser: userData },
        } = await API.graphql(
          graphqlOperation(customQueries, { id: notify.userID }) // eslint-disable-line comma-dangle
        );
        if (!userData) return null;
        notify.user = userData;
        if (userData.avatar?.key) {
          notify.profileAvatar = await Storage.get(userData.avatar?.key?.normalize('NFD'));
        }
      }
      if (
        notify.videoID &&
        [
          'uploadVideoSpace',
          'uploadVideoGroup',
          'likeVideo',
          'mentionComment',
          'likeComment',
        ].includes(notify.type)
      ) {
        if (notify.fromGroups) {
          const filterGroupIDs = notify.fromGroups.map((groupID) => ({
            id: { eq: groupID },
          }));
          notify.groups = await dispatch(
            'api/query',
            {
              query: 'listGroups',
              filter: { or: filterGroupIDs },
            },
            { root: true } // eslint-disable-line comma-dangle
          );
        }
        let video;
        if (cachedData.videos[notify.videoID]) {
          video = cachedData.videos[notify.videoID];
        } else {
          video = await dispatch(
            'api/get',
            { query: 'getVideo', id: notify.videoID },
            { root: true } // eslint-disable-line comma-dangle
          );
          cachedData.videos[notify.videoID] = { ...video };
        }
        if (!video) return null;

        let imgURL = '';
        if (video?.type === 'youtube') {
          const videoYoutubeInfo = getVideoId(video.videoUrl);
          imgURL = `https://img.youtube.com/vi/${videoYoutubeInfo.id}/0.jpg`;
        } else {
          AWS.config.region = process.env.REGION;
          AWS.config.credentials = await Auth.currentUserCredentials();
          const S3 = new AWS.S3({
            apiVersion: '2006-03-01',
            params: { Bucket: process.env.BUCKET_VIDEO_CONVERT },
          });
          const { key, bucket } = video.thumbnail;
          imgURL = S3.getSignedUrl('getObject', { Key: key, Bucket: bucket });
        }

        notify.thumbnail = { 'background-image': `url(${imgURL})` };
        notify.video = video;
      }
      if (notify.visionID) {
        const visionData = await dispatch(
          'api/get',
          { query: 'getVision', id: notify.visionID },
          { root: true } // eslint-disable-line comma-dangle
        );
        if (!visionData?.id) return null;
        notify.vision = visionData;
      }
      if (notify.goalID) {
        const goalData = await dispatch(
          'api/get',
          { query: 'getGoal', id: notify.goalID },
          { root: true } // eslint-disable-line comma-dangle
        );
        if (!goalData?.id) return null;
        notify.goal = goalData;
      }
      if (notify.issueID) {
        const issueData = await dispatch(
          'api/get',
          { query: 'getIssue', id: notify.issueID },
          { root: true } // eslint-disable-line comma-dangle
        );
        if (!issueData?.id) return null;
        notify.issue = issueData;
      }
      return notify;
    } catch (error) {
      console.error('fetch notification info:', error);
    }
  },
  async listNotifications(
    { commit, dispatch },
    { filter, limit, nextToken = null, fetchNotificationInfo = true } // eslint-disable-line
  ) {
    try {
      commit('RESET_LIST_NOTIFICATIONS');
      // queryAllだと、重複データが返ってきてしまうので、queryに変更
      // データが全て取得完了していても、nextTokenがnullでない場合があるので、要調査（2024.04.02 安村）
      const notificationData = await dispatch(
        'api/query',
        { query: 'listNotifications', filter, limit, nextToken },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      const data = [];
      Promise.all(
        notificationData.map(async (item) => {
          // if (fetchNotificationInfo) {
          //   const notify = await dispatch('fetchNotificationInfo', item);
          //   if (notify) {
          //     data.push(notify);
          //     return true;
          //   }
          // } else {
          data.push(item);
          //   return true;
          // }
        }) // eslint-disable-line comma-dangle
      ).then(() => {
        commit('SET_NOTIFICATIONS', data);
      });
      return data;
    } catch (error) {
      console.error('get notifications', error);
      return Promise.reject();
    }
  },
  async getLikeNotification({ dispatch }, { filter, limit, nextToken }) {
    try {
      return await dispatch(
        'api/query',
        { query: 'listNotifications', filter, limit, nextToken },
        { root: true }, // eslint-disable-line prettier/prettier
      );
    } catch (error) {
      console.error('get like notifications', error);
      return Promise.reject();
    }
  },
  async createNotify({ dispatch }, { input, emailContent = {}, redirectUrl = '' }) {
    try {
      input.status = 'uncheck';
      const { subject: emailSubject, body: emailBody } = emailContent;
      let notification = {};
      // create notification on timeline, check type to not create notification on timeline
      if (
        ![
          'answerSpaceRequest',
          'joinGroup',
          'deActiveSpace',
          'removeFromGroup',
          'removeFromSpace',
          'editIssueProgress',
          'issueOverDue',
        ].includes(
          input.type // eslint-disable-line comma-dangle
        )
      ) {
        notification = await dispatch(
          'api/mutate',
          { mutation: 'createNotification', input },
          { root: true }, // eslint-disable-line prettier/prettier
        );
        if (['requestJoinSpace', 'mentionComment', 'mentionChat'].includes(input.type)) {
          notification.isPushFBNotification = true;
          const notificationData = await dispatch('fetchNotificationInfo', notification);
          pushFireBaseNotification({ notificationData, redirectUrl });
        }
      }
      // send email, check type to send email
      if (
        ([
          'answerSpaceRequest',
          'joinGroup',
          'deActiveSpace',
          'deActiveGroup',
          'removeFromGroup',
          'removeFromSpace',
          'editIssueProgress',
          'issueOverDue',
        ].includes(
          input.type // eslint-disable-line comma-dangle
        ) ||
          notification.id) &&
        emailSubject &&
        emailBody
      ) {
        const { receiverID } = input;

        const customQueries = `
          query GetUser($id: ID!) {
            getUser(id: $id) {
              email
              name
              receiveEmail
              receiveEmailCycle
              isDisabled
              isDeleted
            }
          }
        `;
        const { data: userData } = await API.graphql(
          graphqlOperation(customQueries, { id: receiverID }) // eslint-disable-line comma-dangle
        );
        const {
          getUser: { email, receiveEmail, name, receiveEmailCycle, isDisabled, isDeleted },
        } = userData;
        if (receiveEmail && !isDisabled && !isDeleted) {
          const emailTextBody = emailBody.replaceAll('<br/>', '\n');
          if (receiveEmailCycle === 0) {
            const { aws_user_files_s3_bucket_region: region } = awsconfig;
            AWS.config.region = region;
            AWS.config.credentials = await Auth.currentUserCredentials();
            const footerBody =
              'メッセージの通知設定は、ナレッジ・ループの「スペース設定＞通知設定」より変更できます。 <br/><br/>※このメールアドレスは送信専用です。返信はおこなっておりません。';
            const footerTextBody = footerBody.replaceAll('<br/>', '\n');

            const params = {
              Destination: {
                ToAddresses: [email],
              },
              Message: {
                Body: {
                  Html: {
                    Charset: 'UTF-8',
                    Data: `${name}様${emailBody}${footerBody}`,
                  },
                  Text: {
                    Charset: 'UTF-8',
                    Data: `${name}様${emailTextBody}${footerTextBody}`,
                  },
                },
                Subject: {
                  Charset: 'UTF-8',
                  Data: emailSubject,
                },
              },
              Source: sourceEmail,
            };
            const sendPromise = new AWS.SES({ apiVersion: emailApiVersion })
              .sendEmail(params)
              .promise();
            sendPromise
              .then((data) => {
                console.log(data.MessageId);
              })
              .catch((err) => {
                console.error(err, err.stack);
              });
          } else {
            const emailCycleInput = {
              typeCycle: receiveEmailCycle,
              content: emailBody.replace(/^\s*<br\s*\/?><br\s*\/?>|<br\s*\/?><br\s*\/?>\s*$/g, ''),
              email,
              receiverName: name,
            };
            await dispatch(
              'api/mutate',
              { mutation: 'createEmailCycle', input: emailCycleInput },
              { root: true } // eslint-disable-line comma-dangle
            );
          }
        }
      }
      return notification;
    } catch (error) {
      console.error('create Notification', error);
      return Promise.reject();
    }
  },
  async checkNotifications({ commit, dispatch, state }, { type, notifyIDs = null }) {
    if (Cookie.default.getCookie('isAdminSignInAsUser') !== 'true') {
      try {
        const isRequestJoinSpace = type === 'requestJoinSpace';
        const checkInput = {
          status: 'check',
        };
        if (!isRequestJoinSpace) checkInput.statusBadge = false;
        if (notifyIDs) {
          notifyIDs.forEach(async (id) => {
            const input = { ...checkInput, id };
            await dispatch(
              'api/mutate',
              { mutation: 'updateNotification', input },
              { root: true }, // eslint-disable-line prettier/prettier
            );
          });
        } else {
          const notifiesToCheck = [...state.notifications[type]];
          notifiesToCheck.forEach(async (notify) => {
            if (notify.status === 'check') return;
            const input = { ...checkInput, id: notify.id };
            await dispatch(
              'api/mutate',
              { mutation: 'updateNotification', input },
              { root: true }, // eslint-disable-line prettier/prettier
            );
          });
        }
        commit('CHECK_NOTIFICATIONS', { type, notifyIDs });
      } catch (error) {
        console.error('check Notifications', error);
        return Promise.reject();
      }
    }
  },

  async checkBadges({ commit, dispatch, state }, { type, notifyIDs = null }) {
    if (Cookie.default.getCookie('isAdminSignInAsUser') !== 'true') {
      try {
        const isRequestJoinSpace = type === 'requestJoinSpace';
        const checkInput = {
          statusBadge: false,
        };
        if (isRequestJoinSpace) checkInput.status = 'check';
        if (notifyIDs) {
          notifyIDs.forEach(async (id) => {
            const input = { ...checkInput, id };
            await dispatch(
              'api/mutate',
              { mutation: 'updateNotification', input },
              { root: true }, // eslint-disable-line prettier/prettier
            );
          });
        } else {
          const notifiesToCheck = [...state.notifications[type]];
          notifiesToCheck.forEach(async (notify) => {
            if (notify.statusBadge === false) return;
            const input = { ...checkInput, id: notify.id };
            await dispatch(
              'api/mutate',
              { mutation: 'updateNotification', input },
              { root: true }, // eslint-disable-line prettier/prettier
            );
          });
        }
        commit('CHECK_BADGES', { type, notifyIDs });
      } catch (error) {
        console.error('check Badges', error);
        return Promise.reject();
      }
    }
  },

  async startListenToNotifications(
    { commit, dispatch },
    { currentUser, spaceID = null } // eslint-disable-line comma-dangle
  ) {
    const { id: userID } = currentUser;
    if (spaceID) {
      await dispatch('stopListenToNotifications');
      const createNotificationChannel = API.graphql(
        graphqlOperation(onCreateNotification) // eslint-disable-line comma-dangle
      ).subscribe({
        next: async ({ value }) => {
          const notification = value.data.onCreateNotification;
          if (notification.receiverID === userID) {
            const notify = await dispatch('fetchNotificationInfo', notification);
            if (!notify) return;
            commit('FLASH_NEW_NOTIFY', notify);
            if (notification.spaceID === spaceID) {
              commit('ADD_NOTIFICATION', notify);
            }
          }
        },
      });
      const updateNotificationChannel = API.graphql(
        graphqlOperation(onUpdateNotification) // eslint-disable-line comma-dangle
      ).subscribe({
        next: async ({ value }) => {
          const notification = value.data.onUpdateNotification;
          if (notification.receiverID === userID && notification.spaceID === spaceID) {
            const notify = await dispatch('fetchNotificationInfo', notification);
            if (notify) {
              commit('UPDATE_NOTIFICATION', notify);
            }
          }
        },
      });
      commit('SET_NOTIFICATION_CHANNEL', { createNotificationChannel, updateNotificationChannel });
    }
  },

  async stopListenToNotifications({ commit }) {
    commit('STOP_NOTIFICATION_CHANNEL');
  },
  async clearNotifications({ commit }) {
    commit('RESET_LIST_NOTIFICATIONS');
  },
};

export const getters = {
  notifications: (state) => state.notifications,
  newNotify: (state) => state.newNotify,
};
