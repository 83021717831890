import { Auth } from 'aws-amplify';
import * as Cookie from '@/lib/util/cookies';

const expireDays = process.env.GUEST_TOKEN_EXPIRE_DAYS || 1;

function setUserInfoCookies(guestName, guestInfo) {
  const { accessKeyId, secretAccessKey, sessionToken } = guestInfo;
  Cookie.default.setCookie('guestName', guestName, expireDays);
  Cookie.default.setCookie('accessKeyId', accessKeyId, expireDays);
  Cookie.default.setCookie('secretAccessKey', secretAccessKey, expireDays);
  Cookie.default.setCookie('sessionToken', sessionToken, expireDays);
}

export const state = () => ({
  guestInfo: {},
});

export const mutations = {
  SET_GUEST(state, user) {
    state.guestInfo = user;
  },
  REMOVE_GUEST(state) {
    state.guestInfo = {};
    Cookie.default.setCookie('guestName', '', -1);
    Cookie.default.setCookie('accessKeyId', '', -1);
    Cookie.default.setCookie('secretAccessKey', '', -1);
    Cookie.default.setCookie('sessionToken', '', -1);
  },
};

export const actions = {
  async setGuestUser({ commit }, { guestName, guestInfo }) {
    if (guestInfo) {
      setUserInfoCookies(guestName, guestInfo);
      guestInfo.guestName = guestName;
      commit('SET_GUEST', guestInfo);
    } else {
      const user = await Auth.currentUserCredentials();
      setUserInfoCookies(guestName, user);
      user.guestName = guestName;
      commit('SET_GUEST', user);
    }
  },

  async removeGuestUser({ commit }) {
    commit('REMOVE_GUEST');
  },
};

export const getters = {
  guestInfo: (state) => state.guestInfo,
};
