//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  faInfoCircle,
  faAt,
  faGlobe,
  faClipboardList,
  faClipboardCheck,
  faDoorOpen,
  faCaretDown,
  faCaretRight,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import toggle from '@/mixins/toggle';
import toggleSpaceSelect from '@/mixins/toggleSpaceSelect';
import expandSidebar from '@/mixins/expandSidebar';
import flashNewNotify from '@/mixins/flashNewNotify';
import checkBadge from '@/mixins/checkBadge';
import getNotifications from '@/mixins/getNotifications';
import spaceGroupExistChannel from '@/mixins/spaceGroupExistChannel';
import TheNavbar from '@/components/layouts/TheNavbar';
import Loading from '@/components/layouts/Loading';
import Alert from '@/components/layouts/Alert';
import dayjs from '@/plugins/dayjs';
import adminNotificationChannel from '@/mixins/adminNotificationChannel';
import { Storage } from 'aws-amplify';

function sortByCreatedAt(a, b) {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
}

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

export default {
  name: 'Default',
  components: {
    Alert,
    Loading,
    TheNavbar,
  },
  mixins: [
    toggle,
    toggleSpaceSelect,
    expandSidebar,
    flashNewNotify,
    checkBadge,
    getNotifications,
    spaceGroupExistChannel,
    adminNotificationChannel,
  ],
  data() {
    return {
      currentGroupID: null,
      navTitle: null,
      navType: null,
      joinedGroup: [],
      publicGroup: [],
      defaultAvatar: require('~/assets/images/default-avatar-2.png'), // eslint-disable-line global-require
      spaceMentionNotifications: 0,
      spaceChatMentions: 0,
      hasNewNotification: false,
      hasNewSpaceVideoNotification: false,
      hasNewSpaceChatNotification: false,
      toggleGroupID: '',
      loadingMoreChatComponentHeight: 32,
      formMessageHeight: 90,
      minimumScrollDownCheck: 5,
      showListCoachGroup: true,
      showListNormalGroup: true,
      image_src: 'https://bulma.io/images/placeholders/128x128.png',
      default: 'https://bulma.io/images/placeholders/128x128.png',
    };
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('space', ['currentSpace']),
    ...mapGetters('group', ['currentGroup']),
    ...mapGetters('notifications', ['notifications', 'newNotify']),
    ...mapGetters('profile', ['userSpaceProfile']),
    ...mapGetters('api', ['nextTokenListChat', 'nextTokenScrollDownListChat']),
    ...mapGetters('loading', ['isLoading', 'isLoadingChat', 'isLoadingChatScrollDown']),
    ...mapGetters('adminNotification', ['listNotiAdmin']),
    ...mapGetters('coachRoom', ['coachRooms']),
    currentSpaceName() {
      return this.currentSpace?.name;
    },
    isListMemberPublic() {
      return this.currentSpace?.isListMemberPublic;
    },
    isOwner() {
      const editors = this.currentSpace?.editors || [];
      return (
        this.currentSpace?.userID === this.currentUser.id || editors.includes(this.currentUser.id)
      );
    },
    spaceId() {
      return this.$route.path.split('/')[2];
    },
    faInfoCircle() {
      return faInfoCircle;
    },
    faAt() {
      return faAt;
    },
    faGlobe() {
      return faGlobe;
    },
    faClipboardList() {
      return faClipboardList;
    },
    notFreeOrLightPlan() {
      return !['FREE', 'LIGHT'].includes(this.currentSpace?.planName || '');
    },
    notBlockMainFunctions() {
      return this.currentSpace?.status !== 'blockMainFunctions';
    },
    faClipboardCheck() {
      return faClipboardCheck;
    },
    faDoorOpen() {
      return faDoorOpen;
    },
    faCaretDown() {
      return faCaretDown;
    },
    faCaretRight() {
      return faCaretRight;
    },
    listCoachGroup() {
      return this.joinedGroup.filter((group) => group.isCoachGroup);
    },
    listNormalGroup() {
      return this.joinedGroup.filter((group) => !group.isCoachGroup);
    },
    faUsers() {
      return faUsers;
    },
    hasLogo() {
      return this.currentSpace?.avatar;
    },
  },
  watch: {
    notifications() {
      this.getNotificationBadges();
    },
    newNotify(notify) {
      if (notify.type !== 'newMessage') {
        this.hasNewNotification = true;
      }
      if (notify.type === 'newMessage' && !notify.groupID) {
        this.hasNewSpaceChatNotification = true;
      }
      if (notify.type === 'uploadVideoSpace') {
        this.hasNewSpaceVideoNotification = true;
      }
    },
    $route: {
      handler() {
        this.$nextTick(() => {
          this.setOffLoading();
        });
        this.setNavType();
      },
      deep: true,
    },
    listNotiAdmin(listNotiAdmin) {
      if (listNotiAdmin.length > 0) {
        this.setCheckInterval();
      } else {
        this.clearCheckInterval();
      }
    },
  },
  async created() {
    this.getAvatar();
    if (this.coachRooms.length === 0) {
      await this.getSpaceCoachRooms({
        spaceID: this.spaceId,
      });
      this.getCoachRoomsShared(this.currentGroup);
    }
    this.getListNotifications();
    this.fetchSidebarGroups();
    await this.getNotifications(this.currentSpace.id, this.$route.name === 'space-id-notification');
  },
  async beforeDestroy() {
    await this.clearNotifications();
  },
  mounted() {
    this.setOffLoading();
    const newUser = window.localStorage.getItem(`${this.$auth.id}-new`);
    if (screen.width <= 760 && newUser === 'new') {
      this.isShowing = true;
    }
    window.localStorage.removeItem(`${this.$auth.id}-new`);
    window.addEventListener('online', () => this.$router.go(0));
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', this.handleResize, true);
    this.setNavType();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  errorCaptured(err) {
    this.setOffLoading();
    console.log(err);
    this.addAlert({
      type: 'alert-error',
    });
    return false;
  },
  methods: {
    ...mapActions('group', [
      'getListGroups',
      'listJoinGroups',
      'getListPublicGroups',
      'joinGroup',
      'getGroup',
    ]),
    ...mapActions('notifications', ['clearNotifications']),
    ...mapActions('coachRoom', [
      'getSpaceCoachRooms',
      'getCoachRoomsShared',
      'getSpaceCoachRoomOfProfileOrGroup',
    ]),
    ...mapMutations('group', ['SET_GROUP']),
    ...mapActions('alertError', ['addAlert']),
    ...mapActions('chatGroup', ['createChatGroup']),
    ...mapActions('chat', ['queryListChat']),
    ...mapActions('chatGroup', ['queryListChatGroup']),
    ...mapActions('adminNotification', [
      'queryListAdminNotification',
      'setListNotiAdmin',
      'setCheckInterval',
      'clearCheckInterval',
    ]),
    async fetchSidebarGroups() {
      const { id: userID } = this.currentUser;
      const joinGroupData = await this.listJoinGroups({
        filter: { spaceID: { eq: this.spaceId }, userID: { eq: userID } },
      });
      const joinGroupIDs = joinGroupData.map((join) => join.group.id);
      const allGroups = await this.getListGroups({
        filter: {
          spaceID: { eq: this.spaceId },
          status: { ne: 'inactive' },
        },
      });
      const ownedGroup = allGroups.filter((group) => group.userID === userID);
      const joinedGroup = joinGroupData.map((join) => join.group);
      const showGroup = ownedGroup.concat(joinedGroup);
      this.joinedGroup = showGroup
        .filter((item, index) => showGroup.findIndex((item2) => item.id === item2.id) === index)
        .filter((group) => group.status !== 'inactive')
        .map((group) => ({
          ...group,
          isPublic: group.public?.items?.length > 0,
        }))
        .sort(sortByCreatedAt);
      this.fetchCoachGroupAvatar();
      const notJoinedGroup = allGroups.filter(
        (group) => !joinGroupIDs.includes(group.id) && group.userID !== userID // eslint-disable-line comma-dangle
      );
      let finalSearchGroup = [];
      if (this.isOwner) {
        finalSearchGroup = notJoinedGroup;
      } else {
        finalSearchGroup = notJoinedGroup.filter((group) => group.public?.items?.length > 0);
      }
      this.publicGroup = finalSearchGroup
        .map((group) => ({
          ...group,
          isPublic: group.public?.items?.length > 0,
        }))
        .sort(sortByCreatedAt);
    },
    expandCoachGroup() {
      this.showListCoachGroup = !this.showListCoachGroup;
    },
    expandNormalGroup() {
      this.showListNormalGroup = !this.showListNormalGroup;
    },
    async fetchCoachGroupAvatar() {
      const currentSpaceProfiles = this.currentSpace?.profiles?.items || [];
      const fetchPromises = this.joinedGroup.map(async (group) => {
        if (group.isCoachGroup) {
          const findProfile = currentSpaceProfiles.find(
            (profile) => profile.userID === group.userID // eslint-disable-line comma-dangle
          );
          const avatar = findProfile?.avatar || group.user.avatar;
          const avatarURL = await this.getResourceUrl(avatar);
          return {
            ...group,
            avatarURL,
          };
        }
        return {
          ...group,
          avatarURL: this.defaultAvatar,
        };
      });
      const groups = (await Promise.allSettled(fetchPromises)).map(({ value }) => value);
      this.joinedGroup = groups;
    },
    async getResourceUrl(resource) {
      let avatar;
      if (resource) {
        avatar = await Storage.get(resource.key.normalize('NFD'));
      }
      return avatar;
    },
    async getListNotifications() {
      const todayUnixTime = dayjs().unix();
      const options = {
        limit: 1000000,
        sortDirection: 'DESC',
        targetID: 'all',
        startedAt: { le: todayUnixTime },
        filter: {
          endedAt: { ge: todayUnixTime },
        },
      };
      const notifications = await this.queryListAdminNotification(options);
      this.setListNotiAdmin(notifications);
    },
    toggleGroupOption(e, groupID) {
      e.stopPropagation();
      if (this.toggleGroupID === groupID) {
        this.toggleGroupID = '';
        return;
      }
      this.toggleGroupID = groupID;
      this.$nextTick(() => {
        const optionBtn = e.target.closest('.column')?.getBoundingClientRect();
        const optionBox = document.getElementById('group-option');
        if (optionBox && optionBtn) {
          optionBox.style.top = `${optionBtn.top}px`;
          optionBox.style.left = `${optionBtn.left + 30}px`;
        }
      });
    },
    hideGroupOption() {
      this.toggleGroupID = '';
    },
    setNavType() {
      switch (this.$route.name) {
        case 'space-id-video':
          this.navTitle = '動画';
          this.navType = 'video';
          this.currentGroupID = '';
          break;
        case 'space-id':
          this.navTitle = '全体チャット';
          this.navType = 'chat';
          this.currentGroupID = '';
          break;
        case 'space-id-notification':
          this.navTitle = '新規アクション';
          this.navType = 'notification';
          this.currentGroupID = '';
          break;
        case 'space-id-block':
          this.navTitle = 'Block';
          this.navType = '';
          this.currentGroupID = '';
          break;
        default:
          this.navType = '';
          this.currentGroupID = this.currentGroup.id;
          this.navTitle = this.currentGroup.name;
          break;
      }
    },
    getNotificationBadges() {
      this.getMentionBadge();
      this.getSpaceChatBadge();
      this.getGroupChatBadge();
      const { uploadVideoSpace } = this.notifications;
      this.hasNewSpaceVideoNotification =
        uploadVideoSpace.filter((notify) => notify.status === 'uncheck').length > 0;
    },
    getMentionBadge() {
      const { mentionChat, mentionComment } = this.notifications;
      this.spaceMentionNotifications = mentionChat
        .concat(mentionComment)
        .filter((notify) => notify.statusBadge && notify.status === 'uncheck').length;
      const allNewNotification = { ...this.notifications, newMessage: [] };
      this.hasNewNotification =
        Object.values(allNewNotification)
          .flat()
          .filter((notify) => notify.status === 'uncheck').length > 0;
    },
    getSpaceChatBadge() {
      const { mentionChat, newMessage } = this.notifications;
      this.spaceChatMentions = mentionChat.filter(
        (mention) => !mention.groupID && mention.statusBadge && mention.status === 'uncheck' // eslint-disable-line comma-dangle
      ).length;
      this.hasNewSpaceChatNotification =
        newMessage.filter(
          (notify) => !notify.groupID && notify.status === 'uncheck' // eslint-disable-line comma-dangle
        ).length > 0;
    },
    getGroupChatBadge() {
      this.joinedGroup = this.joinedGroup
        .map((group) => ({
          ...group,
          groupMentions: this.getGroupMentions(group),
          groupNewMessages: this.getGroupNewMessages(group),
        }))
        .sort(sortByCreatedAt);
    },
    async onJoin() {
      this.$store.dispatch('loading/setOnLoading');
      const { id: groupID, name } = this.selectedGroup;
      const { id: userID } = this.currentUser;
      const joinGroupInput = {
        groupID,
        userID,
        spaceID: this.spaceId,
      };
      await this.joinGroup(joinGroupInput);
      this.joinedGroup.push(this.selectedGroup);
      this.createChatGroup({
        content: 'has joined the group.',
        profileID: this.userSpaceProfile.id,
        groupID,
        parentID: 'true',
        type: 'system',
      });
      const joinIndex = this.publicGroup.indexOf(this.selectedGroup);
      this.publicGroup.splice(joinIndex, 1);
      this.toggleShow();
      this.toggleExpand();
      this.selectedGroup = {};
      this.navTitle = name;
      this.currentGroupID = groupID;
      this.$router.push(
        { path: `/space/${this.spaceId}/group/${groupID}` },
        this.setOffLoading // eslint-disable-line comma-dangle
      );
    },
    switchGroup(group) {
      if (this.isSelectedJoinGroup(group.id)) return;
      this.$store.dispatch('loading/setOnLoading');
      const storageKey = `${this.currentUser.id}-${this.currentSpace.id}-history`;
      if (this.isExpand) this.toggleExpand();
      this.currentGroupID = group.id;
      this.navTitle = group.name;
      this.navType = '';
      this.toggleShow();
      this.checkBadge('mentionChat', group.id);
      const spaceHistory = localStorage.getItem(storageKey);
      const history = JSON.parse(spaceHistory || '{}');
      if (!history[group.id]) {
        history[group.id] = 'chat';
      }
      let path = `/space/${this.spaceId}/group/${group.id}`;
      if (history[group.id] === 'video') path += '/video';
      if (group.isCoachGroup) {
        const findCoachRoomOfGroup = this.coachRooms.find((room) => room.groupID === group.id);
        this.$router.push({ path, query: { profileID: findCoachRoomOfGroup?.profileID } }, () => {
          this.getSpaceCoachRoomOfProfileOrGroup({
            spaceID: this.currentSpace.id,
            profileID: findCoachRoomOfGroup?.profileID,
            groupID: group.id,
          });
        });
      } else {
        this.getCoachRoomsShared(group);
        this.$router.push({ path });
      }
    },
    switchMain() {
      this.$store.dispatch('loading/setOnLoading');
      const { spaceId } = this;
      const storageKey = `${this.currentUser.id}-${this.currentSpace.id}-history`;
      const target = {
        chat: { title: '全体チャット', path: `/space/${spaceId}` },
        video: { title: '動画', path: `/space/${spaceId}/video` },
      };
      const spaceHistory = localStorage.getItem(storageKey);
      const history = JSON.parse(spaceHistory || '{}');
      if (!history.space) {
        history.space = 'chat';
      }
      if (history.space === 'chat') {
        this.hasNewSpaceChatNotification = false;
        this.checkBadge('mentionChat');
      }
      if (history.space === 'video') {
        this.hasNewSpaceVideoNotification = false;
      }
      if (this.navTitle === target[history.space].title) {
        this.$store.dispatch('loading/setOffLoading');
      }
      this.setNavType();
      this.SET_GROUP({});
      this.isShowing = false;
      this.$router.push({ path: target[history.space].path });
    },
    addGroup() {
      this.$router.push({ path: `/space/${this.spaceId}/group/new` });
    },
    async onSelectNav(type) {
      this.$store.dispatch('loading/setOnLoading');
      this.hideExpand();
      const { spaceId } = this;
      const target = {
        notification: { title: '新規アクション', path: `/space/${spaceId}/notification` },
        chat: { title: '全体チャット', path: `/space/${spaceId}` },
        video: { title: '動画', path: `/space/${spaceId}/video` },
        profile: { title: 'プロフィール', path: '/profile' },
        member: { title: 'スペース設定', path: `/space/${spaceId}/member` },
        setting: { title: 'スペース設定', path: `/space/${spaceId}/setting` },
        invite: { title: '招待', path: `/space/${spaceId}/send_invite` },
        settingPlan: { path: `/space/${spaceId}/settingPlan` },
        videoTags: { path: `/space/${spaceId}/video_tags` },
      };
      if (this.navTitle === target[type].title) {
        this.$store.dispatch('loading/setOffLoading');
      }
      this.setNavType();
      this.SET_GROUP({});
      this.isShowing = false;
      this.$router.push({ path: target[type].path });
      if (type === 'notification') {
        this.hasNewNotification = false;
        this.getNotifications(this.currentSpace.id);
        this.checkBadge('mentionChat', 'all');
        this.checkBadge('mentionComment');
      }
      if (type === 'video') {
        this.hasNewSpaceVideoNotification = false;
      }
      if (type === 'chat') {
        this.hasNewSpaceChatNotification = false;
        this.checkBadge('mentionChat');
      }
    },
    showProfile() {
      if (this.$route.name?.includes('space')) {
        return true;
      }
      return false;
    },
    profileUrl() {
      return `/space/${this.spaceId}/profile`;
    },
    isSelectedJoinGroup(id) {
      return this.currentGroupID === id;
    },
    getGroupMentions(group) {
      const { mentionChat } = this.notifications;
      return mentionChat.filter((noti) => noti.groupID === group.id && noti.statusBadge).length;
    },
    getGroupNewMessages(group) {
      const { newMessage } = this.notifications;
      return (
        newMessage.filter((noti) => noti.groupID === group.id && noti.status === 'uncheck').length >
        0
      );
    },
    getSpaceRequestNotifications() {
      const { id: spaceID } = this.currentSpace;
      const { requestJoinSpace } = this.notifications;
      return requestJoinSpace.filter(
        (noti) => noti.spaceID === spaceID && noti.statusBadge // eslint-disable-line comma-dangle
      ).length;
    },
    notifyClass(type) {
      const {
        navType,
        hasNewNotification,
        hasNewSpaceVideoNotification,
        hasNewSpaceChatNotification,
      } = this;
      let returnClass = 'has-noti-dot ';
      if (navType === type) {
        returnClass += 'group-highlighted ';
      }
      if (type === 'chat') {
        if (this.spaceChatMentions > 0) {
          returnClass += 'has-badge ';
        } else if (!hasNewSpaceChatNotification) {
          returnClass += 'no-notify-dot ';
          if (navType !== type) returnClass += 'no-notify ';
        }
      }
      if (type === 'video') {
        if (!hasNewSpaceVideoNotification) {
          returnClass += 'no-notify-dot ';
          if (navType !== type) returnClass += 'no-notify ';
        }
      }
      if (type === 'notification') {
        if (this.spaceMentionNotifications > 0) {
          returnClass += 'has-badge ';
        } else if (!hasNewNotification) {
          returnClass += 'no-notify-dot ';
          if (navType !== type) returnClass += 'no-notify ';
        }
      }
      return returnClass;
    },
    groupItemClass(group) {
      let returnClass = '';
      if (this.isSelectedJoinGroup(group.id)) returnClass += 'group-highlighted';
      if (group.groupMentions > 0) returnClass += ' has-badge';
      if (group.groupNewMessages) returnClass += ' has-noti-dot';
      return returnClass.length > 0 ? returnClass : 'group-item';
    },
    setOffLoading() {
      this.$store.dispatch('loading/setOffLoading');
    },
    removeJoinGroup(id) {
      const groupID = this.currentGroup.id;
      this.joinedGroup = this.joinedGroup.filter((group) => group.id !== id);
      const { name } = this.$route;
      if (name.includes('group') && id === groupID) {
        this.$router.push({ path: `/space/${this.currentSpace.id}/video` }, () => {
          this.$toast.error(this.$locale.t('group.deleted'));
        });
      }
    },
    async onScrollLoad() {
      const { scrollTop, scrollHeight: initialHeight } = document.querySelector('.main');
      if (
        ['space-id', 'space-id-group-id'].includes(this.$route.name) &&
        (this.nextTokenListChat || this.nextTokenScrollDownListChat)
      ) {
        const bottom = document.querySelector('.box-chat-space')?.getBoundingClientRect()?.bottom;
        if (scrollTop === 0 && this.nextTokenListChat && !this.isLoadingChat) {
          this.$store.dispatch('loading/setOnLoadingChat');
          this.$nextTick(() => {
            document.querySelector('.loading-space-chat .vld-icon').style.display = 'flex';
          });
          if (this.$route.name === 'space-id') {
            await this.loadMoreSpaceChat();
            this.keepScrollPosition(initialHeight);
          } else {
            await this.loadMoreGroupChat();
            this.keepScrollPosition(initialHeight);
          }
          this.$store.dispatch('loading/setOffLoadingChat');
        } else if (
          Math.abs(window.innerHeight - this.formMessageHeight - bottom) <=
            this.minimumScrollDownCheck &&
          this.nextTokenScrollDownListChat &&
          !this.isLoadingChatScrollDown
        ) {
          this.$store.dispatch('loading/setOnLoadingChatDown');
          this.$nextTick(() => {
            document.querySelector('.loading-space-chat-down .vld-icon').style.display = 'flex';
          });
          if (this.$route.name === 'space-id') {
            await this.loadMoreScrollDownSpaceChat();
          } else {
            await this.loadMoreScrollDownGroupChat();
          }
          this.$store.dispatch('loading/setOffLoadingChatDown');
        }
      }
    },
    async loadMoreSpaceChat() {
      try {
        const spaceID = this.$route.params.id;
        const { createdAt, highlightComment } = this.$route.query;
        const options = {
          spaceID,
          limit: 50,
          sortDirection: 'DESC',
          nextToken: this.nextTokenListChat,
        };
        if (highlightComment && createdAt) {
          options.parentIDCreatedAt = { le: { parentID: 'true', createdAt } };
          options.filter = {
            parentID: { eq: 'true' },
            id: { ne: highlightComment },
          };
        } else {
          options.parentIDCreatedAt = { beginsWith: { parentID: 'true' } };
        }
        await this.queryListChat({ options, dayLimitation: this.currentSpace.dayLimitation });
        return true;
      } catch (e) {
        console.log('error load more space chat', e);
      }
    },
    async loadMoreScrollDownSpaceChat() {
      try {
        const spaceID = this.$route.params.id;
        const { createdAt } = this.$route.query;
        const options = {
          spaceID,
          limit: 40,
          sortDirection: 'ASC',
          parentIDCreatedAt: { ge: { parentID: 'true', createdAt } },
          nextToken: this.nextTokenScrollDownListChat,
          filter: { parentID: { eq: 'true' } },
        };
        await this.queryListChat({
          options,
          dayLimitation: this.currentSpace.dayLimitation,
          isHighLightList: true,
          isLoadingDown: true,
        });
      } catch (e) {
        console.log('error load down more space chat', e);
      }
    },
    async loadMoreGroupChat() {
      try {
        const groupID = this.currentGroup.id;
        const { createdAt, highlightComment } = this.$route.query;
        const options = {
          groupID,
          limit: 50,
          sortDirection: 'DESC',
          nextToken: this.nextTokenListChat,
        };
        if (highlightComment && createdAt) {
          options.parentIDCreatedAt = { le: { parentID: 'true', createdAt } };
          options.filter = {
            parentID: { eq: 'true' },
            id: { ne: highlightComment },
          };
        } else {
          options.parentIDCreatedAt = { beginsWith: { parentID: 'true' } };
        }
        await this.queryListChatGroup({ options, dayLimitation: this.currentSpace.dayLimitation });
        return true;
      } catch (e) {
        console.log('error load more group chat', e);
      }
    },
    async loadMoreScrollDownGroupChat() {
      try {
        const groupID = this.currentGroup.id;
        const { createdAt } = this.$route.query;
        const options = {
          groupID,
          limit: 40,
          sortDirection: 'ASC',
          parentIDCreatedAt: { ge: { parentID: 'true', createdAt } },
          nextToken: this.nextTokenScrollDownListChat,
          filter: { parentID: { eq: 'true' } },
        };
        await this.queryListChatGroup({
          options,
          dayLimitation: this.currentSpace.dayLimitation,
          isHighLightList: true,
          isLoadingDown: true,
        });
      } catch (e) {
        console.log('error load down more group chat', e);
      }
    },
    keepScrollPosition(initialHeight) {
      this.$nextTick(() => {
        document.querySelector('.main').scrollTop =
          document.querySelector('.main').scrollHeight - initialHeight;
      });
    },
    handleResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    getSpaceSymbol(name) {
      return name.charAt(0);
    },
    async getAvatar() {
      const avatar = this.currentSpace?.avatar?.key;
      if (avatar) {
        this.image_src = await Storage.get(avatar.normalize('NFD'));
      } else {
        this.image_src = this.default;
      }
    },
  },
};
