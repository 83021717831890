/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      birthday
      gender
      address
      phoneNumber
      career
      avatar {
        bucket
        region
        key
      }
      description
      email
      receiveNotify
      receiveEmail
      receiveEmailCycle
      isDisabled
      isDeleted
      isUnregistered
      isReadyDelete
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedGroups {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      memo
      createdAt
      updatedAt
      spaces {
        items {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      birthday
      gender
      address
      phoneNumber
      career
      avatar {
        bucket
        region
        key
      }
      description
      email
      receiveNotify
      receiveEmail
      receiveEmailCycle
      isDisabled
      isDeleted
      isUnregistered
      isReadyDelete
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedGroups {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      memo
      createdAt
      updatedAt
      spaces {
        items {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      birthday
      gender
      address
      phoneNumber
      career
      avatar {
        bucket
        region
        key
      }
      description
      email
      receiveNotify
      receiveEmail
      receiveEmailCycle
      isDisabled
      isDeleted
      isUnregistered
      isReadyDelete
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedGroups {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      memo
      createdAt
      updatedAt
      spaces {
        items {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreatePublicSpace = /* GraphQL */ `
  subscription OnCreatePublicSpace {
    onCreatePublicSpace {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      spaceID
      owner
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePublicSpace = /* GraphQL */ `
  subscription OnUpdatePublicSpace {
    onUpdatePublicSpace {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      spaceID
      owner
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const onDeletePublicSpace = /* GraphQL */ `
  subscription OnDeletePublicSpace {
    onDeletePublicSpace {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      spaceID
      owner
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup {
    onCreateGroup {
      id
      name
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      public {
        items {
          id
          name
          description
          groupID
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedMembers {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup {
    onUpdateGroup {
      id
      name
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      public {
        items {
          id
          name
          description
          groupID
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedMembers {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup {
    onDeleteGroup {
      id
      name
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      public {
        items {
          id
          name
          description
          groupID
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedMembers {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePublicGroup = /* GraphQL */ `
  subscription OnCreatePublicGroup {
    onCreatePublicGroup {
      id
      name
      description
      groupID
      spaceID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePublicGroup = /* GraphQL */ `
  subscription OnUpdatePublicGroup {
    onUpdatePublicGroup {
      id
      name
      description
      groupID
      spaceID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePublicGroup = /* GraphQL */ `
  subscription OnDeletePublicGroup {
    onDeletePublicGroup {
      id
      name
      description
      groupID
      spaceID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJoinGroup = /* GraphQL */ `
  subscription OnCreateJoinGroup {
    onCreateJoinGroup {
      id
      groupID
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJoinGroup = /* GraphQL */ `
  subscription OnUpdateJoinGroup {
    onUpdateJoinGroup {
      id
      groupID
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJoinGroup = /* GraphQL */ `
  subscription OnDeleteJoinGroup {
    onDeleteJoinGroup {
      id
      groupID
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupVideo = /* GraphQL */ `
  subscription OnCreateGroupVideo {
    onCreateGroupVideo {
      id
      groupID
      videoID
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateGroupVideo = /* GraphQL */ `
  subscription OnUpdateGroupVideo {
    onUpdateGroupVideo {
      id
      groupID
      videoID
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteGroupVideo = /* GraphQL */ `
  subscription OnDeleteGroupVideo {
    onDeleteGroupVideo {
      id
      groupID
      videoID
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat {
    onCreateChat {
      id
      content
      title
      mentionContent
      profileID
      spaceID
      files {
        bucket
        region
        key
      }
      parentID
      parent {
        id
        content
        profileID
        spaceID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        mentions
        type
        createdAt
        child {
          nextToken
        }
        updatedAt
        likes {
          nextToken
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      mentions
      type
      createdAt
      child {
        items {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat {
    onUpdateChat {
      id
      content
      title
      mentionContent
      profileID
      spaceID
      files {
        bucket
        region
        key
      }
      parentID
      parent {
        id
        content
        profileID
        spaceID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        mentions
        type
        createdAt
        child {
          nextToken
        }
        updatedAt
        likes {
          nextToken
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      mentions
      type
      createdAt
      child {
        items {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat {
    onDeleteChat {
      id
      content
      profileID
      spaceID
      files {
        bucket
        region
        key
      }
      parentID
      parent {
        id
        content
        profileID
        spaceID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        mentions
        type
        createdAt
        child {
          nextToken
        }
        updatedAt
        likes {
          nextToken
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      mentions
      type
      createdAt
      child {
        items {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateChatGroup = /* GraphQL */ `
  subscription OnCreateChatGroup {
    onCreateChatGroup {
      id
      content
      title
      mentionContent
      profileID
      groupID
      files {
        bucket
        region
        key
      }
      parentID
      visionActionPerformer
      parent {
        id
        content
        profileID
        groupID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        mentions
        type
        createdAt
        child {
          nextToken
        }
        updatedAt
        likes {
          nextToken
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      mentions
      type
      createdAt
      child {
        items {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              title
              visionID
              status
              color
              vision {
                id
                status
                coachRoomID
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateChatGroup = /* GraphQL */ `
  subscription OnUpdateChatGroup {
    onUpdateChatGroup {
      id
      content
      title
      mentionContent
      profileID
      groupID
      files {
        bucket
        region
        key
      }
      parentID
      parent {
        id
        content
        profileID
        groupID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        mentions
        type
        createdAt
        child {
          nextToken
        }
        updatedAt
        likes {
          nextToken
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      mentions
      type
      createdAt
      child {
        items {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              title
              vision {
                id
                status
                coachRoomID
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteChatGroup = /* GraphQL */ `
  subscription OnDeleteChatGroup {
    onDeleteChatGroup {
      id
      content
      profileID
      groupID
      files {
        bucket
        region
        key
      }
      parentID
      visionActionPerformer
      parent {
        id
        content
        profileID
        groupID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        mentions
        type
        createdAt
        child {
          nextToken
        }
        updatedAt
        likes {
          nextToken
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      mentions
      type
      createdAt
      child {
        items {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      spaceID
      groupID
      videoID
      messageID
      visionID
      goalID
      issueID
      profileID
      receiverID
      type
      status
      statusBadge
      fromGroups
      userID
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      spaceID
      groupID
      videoID
      messageID
      visionID
      goalID
      issueID
      profileID
      receiverID
      type
      status
      statusBadge
      fromGroups
      userID
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      spaceID
      groupID
      videoID
      messageID
      visionID
      goalID
      issueID
      profileID
      receiverID
      type
      status
      statusBadge
      fromGroups
      userID
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateSpaceNotificationSetting = /* GraphQL */ `
  subscription OnCreateSpaceNotificationSetting {
    onCreateSpaceNotificationSetting {
      id
      profileID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      visionBoardSetting
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSpaceNotificationSetting = /* GraphQL */ `
  subscription OnUpdateSpaceNotificationSetting {
    onUpdateSpaceNotificationSetting {
      id
      profileID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      visionBoardSetting
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSpaceNotificationSetting = /* GraphQL */ `
  subscription OnDeleteSpaceNotificationSetting {
    onDeleteSpaceNotificationSetting {
      id
      profileID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      visionBoardSetting
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupNotificationSetting = /* GraphQL */ `
  subscription OnCreateGroupNotificationSetting {
    onCreateGroupNotificationSetting {
      id
      profileID
      groupID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupNotificationSetting = /* GraphQL */ `
  subscription OnUpdateGroupNotificationSetting {
    onUpdateGroupNotificationSetting {
      id
      profileID
      groupID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroupNotificationSetting = /* GraphQL */ `
  subscription OnDeleteGroupNotificationSetting {
    onDeleteGroupNotificationSetting {
      id
      profileID
      groupID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaymentHistory = /* GraphQL */ `
  subscription OnCreatePaymentHistory {
    onCreatePaymentHistory {
      id
      spaceID
      userID
      spaceName
      accountName
      paymentPackage
      productName
      amount
      maxMember
      monthlyUpload
      maxStorage
      totalFee
      invoiceDate
      paymentDate
      paymentCycle
      paymentPeriod
      expireDate
      paymentMethod
      status
      memo
      payJPPaymentID
      payJPSubscriptionID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaymentHistory = /* GraphQL */ `
  subscription OnUpdatePaymentHistory {
    onUpdatePaymentHistory {
      id
      spaceID
      userID
      spaceName
      accountName
      paymentPackage
      productName
      amount
      maxMember
      monthlyUpload
      maxStorage
      totalFee
      invoiceDate
      paymentDate
      paymentCycle
      paymentPeriod
      expireDate
      paymentMethod
      status
      memo
      payJPPaymentID
      payJPSubscriptionID
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaymentHistory = /* GraphQL */ `
  subscription OnDeletePaymentHistory {
    onDeletePaymentHistory {
      id
      spaceID
      userID
      spaceName
      accountName
      paymentPackage
      productName
      amount
      maxMember
      monthlyUpload
      maxStorage
      totalFee
      invoiceDate
      paymentDate
      paymentCycle
      paymentPeriod
      expireDate
      paymentMethod
      status
      memo
      payJPPaymentID
      payJPSubscriptionID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserAccount = /* GraphQL */ `
  subscription OnCreateUserAccount($owner: String) {
    onCreateUserAccount(owner: $owner) {
      id
      userID
      email
      password
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUserAccount = /* GraphQL */ `
  subscription OnUpdateUserAccount($owner: String) {
    onUpdateUserAccount(owner: $owner) {
      id
      userID
      email
      password
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUserAccount = /* GraphQL */ `
  subscription OnDeleteUserAccount($owner: String) {
    onDeleteUserAccount(owner: $owner) {
      id
      userID
      email
      password
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateEmailCycle = /* GraphQL */ `
  subscription OnCreateEmailCycle($owner: String) {
    onCreateEmailCycle(owner: $owner) {
      id
      typeCycle
      content
      email
      receiverName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateEmailCycle = /* GraphQL */ `
  subscription OnUpdateEmailCycle($owner: String) {
    onUpdateEmailCycle(owner: $owner) {
      id
      typeCycle
      content
      email
      receiverName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteEmailCycle = /* GraphQL */ `
  subscription OnDeleteEmailCycle($owner: String) {
    onDeleteEmailCycle(owner: $owner) {
      id
      typeCycle
      content
      email
      receiverName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateSpaceInvitation = /* GraphQL */ `
  subscription OnCreateSpaceInvitation {
    onCreateSpaceInvitation {
      id
      spaceID
      userID
      email
      role
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSpaceInvitation = /* GraphQL */ `
  subscription OnUpdateSpaceInvitation {
    onUpdateSpaceInvitation {
      id
      spaceID
      userID
      email
      role
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSpaceInvitation = /* GraphQL */ `
  subscription OnDeleteSpaceInvitation {
    onDeleteSpaceInvitation {
      id
      spaceID
      userID
      email
      role
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSpace = /* GraphQL */ `
  subscription OnCreateSpace {
    onCreateSpace {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      inviteCode
      userID
      editors
      members
      isActiveCoachRoom
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      public {
        items {
          id
          name
          description
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      status
      planID
      planName
      dayLimitation
      maxMember
      currentUpload
      currentStorage
      monthlyUpload
      maxStorage
      paymentFee
      paymentCycle
      paymentMethod
      memo
      spaceInvitation {
        items {
          id
          spaceID
          userID
          email
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      videos {
        items {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          isShareAble
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
        nextToken
      }
      joinSpaceKey {
        items {
          id
          spaceID
          userName
          spaceName
          content
          avatarKey
          requestRole
          keyAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const onUpdateSpace = /* GraphQL */ `
  subscription OnUpdateSpace {
    onUpdateSpace {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      inviteCode
      userID
      editors
      members
      isActiveCoachRoom
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      public {
        items {
          id
          name
          description
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      status
      planID
      planName
      dayLimitation
      maxMember
      currentUpload
      currentStorage
      monthlyUpload
      maxStorage
      paymentFee
      paymentCycle
      paymentMethod
      memo
      spaceInvitation {
        items {
          id
          spaceID
          userID
          email
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      videos {
        items {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          isShareAble
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
        nextToken
      }
      joinSpaceKey {
        items {
          id
          spaceID
          userName
          spaceName
          content
          avatarKey
          requestRole
          keyAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const onDeleteSpace = /* GraphQL */ `
  subscription OnDeleteSpace {
    onDeleteSpace {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      inviteCode
      userID
      editors
      members
      isActiveCoachRoom
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      public {
        items {
          id
          name
          description
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      status
      planID
      planName
      dayLimitation
      maxMember
      currentUpload
      currentStorage
      monthlyUpload
      maxStorage
      paymentFee
      paymentCycle
      paymentMethod
      memo
      spaceInvitation {
        items {
          id
          spaceID
          userID
          email
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      videos {
        items {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          isShareAble
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
        nextToken
      }
      joinSpaceKey {
        items {
          id
          spaceID
          userName
          spaceName
          content
          avatarKey
          requestRole
          keyAccess
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateLike = /* GraphQL */ `
  subscription OnCreateLike {
    onCreateLike {
      id
      targetID
      targetType
      profileID
      emoji
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateLike = /* GraphQL */ `
  subscription OnUpdateLike {
    onUpdateLike {
      id
      targetID
      targetType
      profileID
      emoji
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteLike = /* GraphQL */ `
  subscription OnDeleteLike {
    onDeleteLike {
      id
      targetID
      targetType
      profileID
      emoji
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateVideoInfo = /* GraphQL */ `
  subscription OnCreateVideoInfo {
    onCreateVideoInfo {
      id
      watched
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVideoInfo = /* GraphQL */ `
  subscription OnUpdateVideoInfo {
    onUpdateVideoInfo {
      id
      watched
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVideoInfo = /* GraphQL */ `
  subscription OnDeleteVideoInfo {
    onDeleteVideoInfo {
      id
      watched
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVideo = /* GraphQL */ `
  subscription OnCreateVideo {
    onCreateVideo {
      id
      title
      description
      status
      thumbnail {
        bucket
        region
        key
      }
      content {
        bucket
        region
        key
      }
      profileID
      spaceID
      inviteCode
      publicToSpace
      duration
      infoID
      publicToGroup {
        items {
          id
          groupID
          videoID
          createdAt
          updatedAt
        }
        nextToken
      }
      isShareAble
      type
      videoUrl
      tags
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      info {
        id
        watched
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo {
    onUpdateVideo {
      id
      title
      description
      status
      thumbnail {
        bucket
        region
        key
      }
      content {
        bucket
        region
        key
      }
      profileID
      spaceID
      inviteCode
      publicToSpace
      duration
      infoID
      publicToGroup {
        items {
          id
          groupID
          videoID
          createdAt
          updatedAt
        }
        nextToken
      }
      isShareAble
      type
      videoUrl
      tags
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      info {
        id
        watched
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo {
    onDeleteVideo {
      id
      title
      description
      status
      thumbnail {
        bucket
        region
        key
      }
      content {
        bucket
        region
        key
      }
      profileID
      spaceID
      inviteCode
      publicToSpace
      duration
      infoID
      publicToGroup {
        items {
          id
          groupID
          videoID
          createdAt
          updatedAt
        }
        nextToken
      }
      isShareAble
      type
      videoUrl
      tags
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      info {
        id
        watched
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      content
      mentionContent
      commentAt
      attachments {
        bucket
        region
        key
      }
      profileID
      videoID
      groupID
      parentID
      spaceID
      editors
      mentions
      type
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      parent {
        id
        content
        commentAt
        attachments {
          bucket
          region
          key
        }
        profileID
        videoID
        groupID
        parentID
        spaceID
        editors
        mentions
        createdAt
        updatedAt
        likes {
          nextToken
        }
        video {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          isShareAble
          createdAt
          updatedAt
        }
        parent {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          spaceID
          editors
          mentions
          createdAt
          updatedAt
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      content
      mentionContent
      commentAt
      attachments {
        bucket
        region
        key
      }
      profileID
      videoID
      groupID
      parentID
      spaceID
      editors
      mentions
      type
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      parent {
        id
        content
        commentAt
        attachments {
          bucket
          region
          key
        }
        profileID
        videoID
        groupID
        parentID
        spaceID
        editors
        mentions
        createdAt
        updatedAt
        likes {
          nextToken
        }
        video {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          isShareAble
          createdAt
          updatedAt
        }
        parent {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          spaceID
          editors
          mentions
          createdAt
          updatedAt
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      content
      commentAt
      attachments {
        bucket
        region
        key
      }
      profileID
      videoID
      groupID
      parentID
      spaceID
      editors
      mentions
      createdAt
      updatedAt
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      parent {
        id
        content
        commentAt
        attachments {
          bucket
          region
          key
        }
        profileID
        videoID
        groupID
        parentID
        spaceID
        editors
        mentions
        createdAt
        updatedAt
        likes {
          nextToken
        }
        video {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          isShareAble
          createdAt
          updatedAt
        }
        parent {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        attachedVideos {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateVideoAttachment = /* GraphQL */ `
  subscription OnCreateVideoAttachment {
    onCreateVideoAttachment {
      id
      attachToID
      videoID
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateVideoAttachment = /* GraphQL */ `
  subscription OnUpdateVideoAttachment {
    onUpdateVideoAttachment {
      id
      attachToID
      videoID
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteVideoAttachment = /* GraphQL */ `
  subscription OnDeleteVideoAttachment {
    onDeleteVideoAttachment {
      id
      attachToID
      videoID
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateProfile = /* GraphQL */ `
  subscription OnCreateProfile {
    onCreateProfile {
      id
      name
      career
      avatar {
        bucket
        region
        key
      }
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      notifySetting {
        items {
          id
          profileID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          visionBoardSetting
          createdAt
          updatedAt
        }
        nextToken
      }
      confirmed
      status
      role
      reason
      groupNotifySetting {
        items {
          id
          profileID
          groupID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          createdAt
          updatedAt
        }
        nextToken
      }
      isEditOnce
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile {
    onUpdateProfile {
      id
      name
      career
      avatar {
        bucket
        region
        key
      }
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      notifySetting {
        items {
          id
          profileID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          visionBoardSetting
          createdAt
          updatedAt
        }
        nextToken
      }
      confirmed
      status
      role
      reason
      groupNotifySetting {
        items {
          id
          profileID
          groupID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          createdAt
          updatedAt
        }
        nextToken
      }
      isEditOnce
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      filterVisionMessage {
        myAction
        someOneElseAction
      }
    }
  }
`;
export const onDeleteProfile = /* GraphQL */ `
  subscription OnDeleteProfile {
    onDeleteProfile {
      id
      name
      career
      avatar {
        bucket
        region
        key
      }
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      notifySetting {
        items {
          id
          profileID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          visionBoardSetting
          createdAt
          updatedAt
        }
        nextToken
      }
      confirmed
      status
      role
      reason
      groupNotifySetting {
        items {
          id
          profileID
          groupID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          createdAt
          updatedAt
        }
        nextToken
      }
      isEditOnce
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        dayLimitation
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        spaceInvitation {
          nextToken
        }
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const onCreateJoinSpaceKey = /* GraphQL */ `
  subscription OnCreateJoinSpaceKey {
    onCreateJoinSpaceKey {
      id
      spaceID
      userName
      spaceName
      content
      avatarKey
      requestRole
      keyAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJoinSpaceKey = /* GraphQL */ `
  subscription OnUpdateJoinSpaceKey {
    onUpdateJoinSpaceKey {
      id
      spaceID
      userName
      spaceName
      content
      avatarKey
      requestRole
      keyAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJoinSpaceKey = /* GraphQL */ `
  subscription OnDeleteJoinSpaceKey {
    onDeleteJoinSpaceKey {
      id
      spaceID
      userName
      spaceName
      content
      avatarKey
      requestRole
      keyAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGuestComment = /* GraphQL */ `
  subscription OnCreateGuestComment {
    onCreateGuestComment {
      id
      name
      content
      commentAt
      accessKeyId
      secretAccessKey
      sessionToken
      parentID
      videoID
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateGuestComment = /* GraphQL */ `
  subscription OnUpdateGuestComment {
    onUpdateGuestComment {
      id
      name
      content
      commentAt
      accessKeyId
      secretAccessKey
      sessionToken
      parentID
      videoID
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteGuestComment = /* GraphQL */ `
  subscription OnDeleteGuestComment {
    onDeleteGuestComment {
      id
      name
      content
      commentAt
      accessKeyId
      secretAccessKey
      sessionToken
      parentID
      videoID
      createdAt
      updatedAt
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreatePlanMaster = /* GraphQL */ `
  subscription OnCreatePlanMaster {
    onCreatePlanMaster {
      id
      name
      description
      maxMember
      monthlyUpload
      maxStorage
      monthlyFee
      yearlyFee
      createdAt
      updatedAt
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const onUpdatePlanMaster = /* GraphQL */ `
  subscription OnUpdatePlanMaster {
    onUpdatePlanMaster {
      id
      name
      description
      maxMember
      monthlyUpload
      maxStorage
      monthlyFee
      yearlyFee
      createdAt
      updatedAt
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const onDeletePlanMaster = /* GraphQL */ `
  subscription OnDeletePlanMaster {
    onDeletePlanMaster {
      id
      name
      description
      maxMember
      monthlyUpload
      maxStorage
      monthlyFee
      yearlyFee
      createdAt
      updatedAt
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const onCreateUserUnfinish = /* GraphQL */ `
  subscription OnCreateUserUnfinish {
    onCreateUserUnfinish {
      id
      email
    }
  }
`;
export const onUpdateUserUnfinish = /* GraphQL */ `
  subscription OnUpdateUserUnfinish {
    onUpdateUserUnfinish {
      id
      email
    }
  }
`;
export const onDeleteUserUnfinish = /* GraphQL */ `
  subscription OnDeleteUserUnfinish {
    onDeleteUserUnfinish {
      id
      email
    }
  }
`;

export const onUpdatePayJPSubscription = /* GraphQL */ `
  subscription OnUpdatePayJPSubscription {
    onUpdatePayJPSubscription {
      id
      userID
      spaceID
      productType
      productName
      quantity
      amount
      startEffectDate
      currentPeriodStart
      currentPeriodEnd
      paymentCycle
      payJPPlanID
      payJPSubscriptionID
      createdAt
      status
      user {
        id
        name
      }
      updatedAt
    }
  }
`;

export const onCreateAdminNotification = /* GraphQL */ `
  subscription OnCreateAdminNotification {
    onCreateAdminNotification {
      id
      targetID
      body
      type
      startedAt
      startedAtFormat
      endedAt
      endedAtFormat
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAdminNotification = /* GraphQL */ `
  subscription OnUpdateAdminNotification {
    onUpdateAdminNotification {
      id
      targetID
      body
      type
      startedAt
      startedAtFormat
      endedAt
      endedAtFormat
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAdminNotification = /* GraphQL */ `
  subscription OnDeleteAdminNotification {
    onDeleteAdminNotification {
      id
      targetID
      body
      type
      startedAt
      startedAtFormat
      endedAt
      endedAtFormat
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag {
    onCreateTag {
      id
      name
      description
      type
      spaceID
      parentID
      parent {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        child {
          nextToken
        }
        createdAt
        updatedAt
      }
      child {
        items {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag {
    onUpdateTag {
      id
      name
      description
      type
      spaceID
      parentID
      parent {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        child {
          nextToken
        }
        createdAt
        updatedAt
      }
      child {
        items {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag {
    onDeleteTag {
      id
      name
      description
      type
      spaceID
      parentID
      parent {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        child {
          nextToken
        }
        createdAt
        updatedAt
      }
      child {
        items {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCoachRoom = /* GraphQL */ `
  subscription OnCreateCoachRoom {
    onCreateCoachRoom {
      id
      spaceID
      name
      description
      visions {
        items {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoachRoom = /* GraphQL */ `
  subscription OnUpdateCoachRoom {
    onUpdateCoachRoom {
      id
      spaceID
      name
      description
      visions {
        items {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoachRoom = /* GraphQL */ `
  subscription OnDeleteCoachRoom {
    onDeleteCoachRoom {
      id
      spaceID
      name
      description
      visions {
        items {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVision = /* GraphQL */ `
  subscription OnCreateVision {
    onCreateVision {
      id
      coachRoomID
      title
      description
      files {
        bucket
        region
        key
      }
      status
      createdAt
      isSetBackground
      totalGoals
      background {
        bucket
        region
        key
      }
      coachRoom {
        id
        spaceID
        name
        description
        visions {
          nextToken
        }
        createdAt
        updatedAt
      }
      goals {
        items {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateVision = /* GraphQL */ `
  subscription OnUpdateVision {
    onUpdateVision {
      id
      coachRoomID
      title
      description
      files {
        bucket
        region
        key
      }
      status
      createdAt
      isSetBackground
      totalGoals
      background {
        bucket
        region
        key
      }
      coachRoom {
        id
        spaceID
        name
        description
        visions {
          nextToken
        }
        createdAt
        updatedAt
      }
      goals {
        items {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteVision = /* GraphQL */ `
  subscription OnDeleteVision {
    onDeleteVision {
      id
      coachRoomID
      title
      description
      files {
        bucket
        region
        key
      }
      status
      createdAt
      coachRoom {
        id
        spaceID
        name
        description
        visions {
          nextToken
        }
        createdAt
        updatedAt
      }
      goals {
        items {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateGoal = /* GraphQL */ `
  subscription OnCreateGoal {
    onCreateGoal {
      id
      visionID
      title
      color
      startDate
      order
      endDate
      status
      createdAt
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachRoom {
          id
          spaceID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      issues {
        items {
          id
          goalID
          title
          startDate
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateGoal = /* GraphQL */ `
  subscription OnUpdateGoal {
    onUpdateGoal {
      id
      visionID
      title
      color
      startDate
      order
      endDate
      status
      createdAt
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachRoom {
          id
          spaceID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      issues {
        items {
          id
          goalID
          title
          startDate
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteGoal = /* GraphQL */ `
  subscription OnDeleteGoal {
    onDeleteGoal {
      id
      visionID
      title
      color
      startDate
      order
      endDate
      status
      createdAt
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachRoom {
          id
          spaceID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      issues {
        items {
          id
          goalID
          title
          startDate
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateIssue = /* GraphQL */ `
  subscription OnCreateIssue {
    onCreateIssue {
      id
      goalID
      title
      startDate
      endDate
      status
      createdAt
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateIssue = /* GraphQL */ `
  subscription OnUpdateIssue {
    onUpdateIssue {
      id
      goalID
      title
      startDate
      endDate
      status
      createdAt
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteIssue = /* GraphQL */ `
  subscription OnDeleteIssue {
    onDeleteIssue {
      id
      goalID
      title
      startDate
      endDate
      status
      createdAt
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateGoalLink = /* GraphQL */ `
  subscription OnCreateGoalLink {
    onCreateGoalLink {
      id
      targetID
      targetType
      goalID
      profileID
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        coachs
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateGoalLink = /* GraphQL */ `
  subscription OnUpdateGoalLink {
    onUpdateGoalLink {
      id
      targetID
      targetType
      goalID
      profileID
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        coachs
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteGoalLink = /* GraphQL */ `
  subscription OnDeleteGoalLink {
    onDeleteGoalLink {
      id
      targetID
      targetType
      goalID
      profileID
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        coachs
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateVisionLink = /* GraphQL */ `
  subscription OnCreateVisionLink {
    onCreateVisionLink {
      id
      targetID
      targetType
      visionID
      profileID
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachs
        profileID
        isPublic
        archivedAt
        coachRoom {
          id
          spaceID
          groupID
          profileID
          name
          description
          coachs
          sharedGroups
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateVisionLink = /* GraphQL */ `
  subscription OnUpdateVisionLink {
    onUpdateVisionLink {
      id
      targetID
      targetType
      visionID
      profileID
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachs
        profileID
        isPublic
        archivedAt
        coachRoom {
          id
          spaceID
          groupID
          profileID
          name
          description
          coachs
          sharedGroups
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteVisionLink = /* GraphQL */ `
  subscription OnDeleteVisionLink {
    onDeleteVisionLink {
      id
      targetID
      targetType
      visionID
      profileID
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachs
        profileID
        isPublic
        archivedAt
        coachRoom {
          id
          spaceID
          groupID
          profileID
          name
          description
          coachs
          sharedGroups
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
