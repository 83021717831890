import dayjs from '@/plugins/dayjs';
import { API, graphqlOperation } from 'aws-amplify';
import {
  batchUpdateVisionsCoachs,
  batchUpdateGoalsCoachs,
  batchUpdateIssuesCoachs,
} from '@/graphql/customMutations';

export const state = () => ({
  coachRoom: {
    coachs: [],
    sharedGroups: [],
  },
  coachRooms: [],
  visions: [],
  vision: {},
  goals: [],
  // activeGoals: [],
  // completedGoals: [],
  goalToUpdate: null,
  issueToUpdate: null,
  modalConfirm: false,
  modalConfirmQuestion: null,
  modalYesButtonText: 'はい',
  modalConfirmAction: () => {},
  currentCoachIDs: {
    visions: [],
    goals: [],
    issues: [],
  },
  issue: {},
  modalSettingCoach: false,
  modalSettingGroup: false,
  sharedCoachRooms: [],
  archivedVisions: [],
  linkWithNewMessage: {
    selectedVisionsForLink: [],
    selectedGoalsForLink: [],
    selectedIssuesForLink: [],
  },
  showIssue: true,
  modalProgressHelper: false,
  issueProgressesDisplay: {
    startingLine: 1,
    inProgress: 2,
    midway: 3,
    takingShape: 4,
    almostThere: 5,
    achieved: 6,
  },
  issueProgressesTextDefault: '開始前',
  issueProgressesText: {
    beforeStart: '開始前',
    startingLine: '着手',
    inProgress: '進行中',
    midway: '中間点',
    takingShape: '具体化中',
    almostThere: '達成間近',
    achieved: '課題達成',
  },
  issueProgressesIntegerDefault: 0,
  issueProgressesInteger: {
    beforeStart: 0,
    startingLine: 1,
    inProgress: 2,
    midway: 3,
    takingShape: 4,
    almostThere: 5,
    achieved: 6,
  },
  modalConfirmChangeProgress: false,
  modalConfirmChangeProgressTitle: '',
  modalConfirmChangeProgressDescription: '',
  modalConfirmChangeProgressAction: () => {},
  progressHelper: {
    startingLine: {
      percentage: '1〜20%',
      title: '着手',
      description:
        '計画をたて、具体的な行動を起こす重要なスタートラインに立ちました。これから始まる学習旅行にワクワクしながら取り組みましょう！',
      clickMessage: 'あなたの目標への第一歩を応援しています。',
    },
    inProgress: {
      percentage: '20~40%',
      title: '進行中',
      description:
        '計画が動き出し、初期の行動が実を結び始めています。課題達成に向けた道の途中です。',
      clickMessage:
        '一歩一歩着実に進んでいます。あなたの努力が確実に結果につながっています。引き続き頑張りましょう！',
    },
    midway: {
      percentage: '40-60%',
      title: '中間点',
      description:
        '課題達成の中間点に到達し、一定の成果を実感しています。達成への道の半ばで、ここがモチベーションを高める重要なポイント。',
      clickMessage:
        '素晴らしい進捗です！これまでの成果を振り返り、残りの道のりにも前向きなエネルギーを注ぎましょう。',
    },
    takingShape: {
      percentage: '60-80%',
      title: '具体化中',
      description:
        '課題達成が現実のものとして具体化しています。成功が視野に入り、完成に向けた最終段階に入っています。',
      clickMessage:
        'あと少しでゴールです！あなたの努力が形になりつつあるこの瞬間を楽しみましょう。',
    },
    almostThere: {
      percentage: '80-90%',
      title: '達成間近',
      description:
        '課題達成まであと一歩というところまで来ました。達成が目前に迫り、高いモチベーションと達成感を感じているはずです。',
      clickMessage: '最後の一押しです！あなたの努力と根気が実を結ぼうとしています。',
    },
    achieved: {
      percentage: '100%',
      title: '課題達成',
      description:
        '課題を見事に達成しました。この成果を確認し、次の挑戦に向けた意欲を高めましょう。',
      clickMessage:
        '素晴らしい成就です！あなたの成功を祝福し、新たな挑戦への旅立ちに幸運を祈ります。',
    },
  },
});

export const mutations = {
  SET_COACH_ROOMS(state, data) {
    state.coachRooms = data;
  },
  ADD_COACH_ROOM(state, data) {
    state.coachRooms.push(data);
  },
  SET_COACH_ROOM(state, coachRoomData) {
    const coachRoom = coachRoomData || state.coachRooms.first || {};
    if (Object.keys(coachRoom).length === 0) return;
    state.coachRoom = coachRoom;
    state.visions = coachRoom?.visions?.items || [];
    state.archivedVisions = coachRoom?.visions?.items.filter((v) => v.status === 'archived') || [];
    const lastestActiveVision =
      coachRoom?.visions?.items
        .filter((vision) => vision.status === 'active')
        .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))[0] || {};
    state.vision = lastestActiveVision;
    state.goals = lastestActiveVision?.goals?.items || [];
  },
  SET_COACH_ROOM_WITH_PROFILE_OR_GROUP(state, { rooms, profileID, groupID }) {
    state.coachRooms = rooms;
    let findRoomOfProfile = null;
    if (profileID) {
      findRoomOfProfile = rooms.find((room) => room.profileID === profileID);
    } else if (groupID) {
      findRoomOfProfile = rooms.find((room) => room.groupID === groupID);
    }
    if (!findRoomOfProfile) return;
    state.coachRoom = findRoomOfProfile || {};
    state.visions = findRoomOfProfile?.visions?.items || [];
    state.archivedVisions =
      findRoomOfProfile?.visions?.items.filter((v) => v.status === 'archived') || [];
    const lastestActiveVision =
      findRoomOfProfile?.visions?.items
        .filter((vision) => vision.status === 'active')
        .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))[0] || {};
    state.vision = lastestActiveVision;
    state.goals = lastestActiveVision?.goals?.items || [];
  },
  UPDATE_COACH_ROOM(state, data) {
    const { coachRoom, coachRooms } = state;
    const findCoachRoom = coachRooms.find((room) => room.id === data.id);
    if (findCoachRoom) {
      Object.assign(findCoachRoom, data);
    }
    if (coachRoom.id === data.id) {
      state.coachRoom = {
        ...coachRoom,
        ...data,
      };
    }
  },
  SET_VISION(state, vision) {
    state.vision = vision;
    state.goals = vision?.goals?.items || [];
  },
  SET_VISIONS(state, visions) {
    state.visions = visions;
  },
  CREATE_VISION(state, data) {
    state.visions.push(data);
    state.vision = data;
  },
  UPDATE_VISION(state, data) {
    const vision = state.visions.find((v) => v.id === data.id);
    if (vision) {
      vision.title = data.title;
      vision.description = data.description;
      vision.files = data.files;
      vision.background = data.background;
      vision.isSetBackground = data.isSetBackground;
    } else {
      state.visions.push(data);
    }
    state.vision = {
      ...state.vision,
      ...data,
    };
  },
  UPDATE_VISION_STATUS(state, data) {
    const vision = state.visions.find((v) => v.id === data.id);
    state.vision = data;
    if (vision) {
      vision.status = data.status;
      vision.archivedAt = data.archivedAt;
      if (data.status === 'archived') {
        state.goals = [];
        state.vision = {};
        state.archivedVisions = state.archivedVisions.concat(vision);
      } else if (data.status === 'active') {
        state.archivedVisions = state.archivedVisions.filter((v) => v.id !== data.id);
      }
    }
  },
  CREATE_GOAL(state, data) {
    state.goals.push(data);
  },
  SET_GOAL_TO_UPDATE(state, data) {
    state.goalToUpdate = data;
  },
  UPDATE_GOAL(state, data) {
    const goal = state.goals.find((g) => g.id === data.id);
    if (goal) {
      goal.title = data.title;
      goal.color = data.color;
      goal.startDate = data.startDate;
      goal.endDate = data.endDate;
    }
  },
  UPDATE_GOAL_STATUS(state, data) {
    const goal = state.goals.find((g) => g.id === data.id);
    if (goal) {
      goal.status = data.status;
    }
  },
  DELETE_GOAL(state, data) {
    const index = state.goals.findIndex((g) => g.id === data.id);
    if (index > -1) {
      state.goals.splice(index, 1);
    }
  },
  CREATE_ISSUE(state, data) {
    const goal = state.goals.find((g) => g.id === data.goalID);
    if (goal) {
      goal.issues.items.push(data);
    }
  },
  SET_ISSUE_TO_UPDATE(state, data) {
    state.issueToUpdate = data;
  },
  UPDATE_ISSUE(state, data) {
    const goal = state.goals.find((g) => g.id === data.goalID);
    if (goal) {
      const issue = goal.issues.items.find((i) => i.id === data.id);
      if (issue) {
        issue.title = data.title;
        issue.startDate = data.startDate;
        issue.endDate = data.endDate;
      }
    }
  },
  UPDATE_ISSUE_STATUS(state, data) {
    const goal = state.goals.find((g) => g.id === data.goalID);
    if (goal) {
      const issue = goal.issues.items.find((i) => i.id === data.id);
      if (issue) {
        issue.status = data.status;
        issue.progress = data.progress;
      }
    }
  },
  UPDATE_ISSUE_PROGRESS(state, data) {
    const goal = state.goals.find((g) => g.id === data.goalID);
    if (goal) {
      const issue = goal.issues.items.find((i) => i.id === data.id);
      if (issue) {
        issue.progress = data.progress;
        if (data.status) issue.status = data.status;
      }
    }
  },
  DELETE_ISSUE(state, data) {
    const goal = state.goals.find((g) => g.id === data.goalID);
    if (goal) {
      const index = goal.issues.items.findIndex((i) => i.id === data.id);
      if (index > -1) {
        goal.issues.items.splice(index, 1);
      }
    }
  },
  OPEN_MODAL_CONFIRM(state, data) {
    state.modalConfirm = true;
    state.modalConfirmQuestion = data.question;
    state.modalConfirmAction = data.action;
    state.modalYesButtonText = data.yesButtonText || 'はい';
  },
  CLOSE_MODAL_CONFIRM(state) {
    state.modalConfirm = false;
    state.modalConfirmQuestion = null;
    state.modalConfirmAction = () => {};
  },
  SET_CURRENT_COACH_IDS(state, data) {
    state.currentCoachIDs[data.key] = data.ids;
  },
  OPEN_MODAL_SET_COACH(state) {
    state.modalSettingCoach = true;
  },
  CLOSE_MODAL_SET_COACH(state) {
    state.modalSettingCoach = false;
  },
  OPEN_MODAL_SET_GROUP(state) {
    state.modalSettingGroup = true;
  },
  CLOSE_MODAL_SET_GROUP(state) {
    state.modalSettingGroup = false;
  },
  SET_COACH_ROOMS_SHARED(state, group) {
    const { coachRooms } = state;
    state.sharedCoachRooms = coachRooms.filter((room) => (room.sharedGroups || []).includes(group.id)); // eslint-disable-line
  },
  SET_ISSUE(state, issue) {
    state.issue = issue;
  },
  SET_SELECT_ITEMS_FOR_LINK(state, { issues, goals, visions }) {
    state.linkWithNewMessage = {
      selectedVisionsForLink: visions,
      selectedGoalsForLink: goals,
      selectedIssuesForLink: issues,
    };
  },
  SET_HIDDEN_ISSUE(state, newState) {
    state.showIssue = newState;
  },
  OPEN_MODAL_PROGRESS_HELPER(state) {
    state.modalProgressHelper = true;
  },
  CLOSE_MODAL_PROGRESS_HELPER(state) {
    state.modalProgressHelper = false;
  },
  OPEN_MODAL_CONFIRM_CHANGE_PROGRESS(state, data) {
    state.modalConfirmChangeProgress = true;
    state.modalConfirmChangeProgressTitle = data.title;
    state.modalConfirmChangeProgressDescription = data.description;
    state.modalConfirmChangeProgressAction = data.action;
  },
  CLOSE_MODAL_CONFIRM_CHANGE_PROGRESS(state) {
    state.modalConfirmChangeProgress = false;
    state.modalConfirmChangeProgressTitle = '';
    state.modalConfirmChangeProgressDescription = '';
    state.modalConfirmChangeProgressAction = () => {};
  },
};

export const actions = {
  async getCoachRoomById({ commit, dispatch }, id) {
    try {
      const coachRoomData = await dispatch(
        'api/get',
        { query: 'getCoachRoom', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      await commit('SET_COACH_ROOM', coachRoomData);
      return coachRoomData;
    } catch (error) {
      console.error('fail to get coachRoom', error);
      return false;
    }
  },
  async getSpaceCoachRooms(
    { commit, dispatch },
    { spaceID, filter, limit, nextToken, ...variables } // eslint-disable-line comma-dangle
  ) {
    try {
      const coachRoomData = await dispatch(
        'api/query',
        { query: 'coachRoomBySpace', spaceID, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      await commit('SET_COACH_ROOMS', coachRoomData);
      return coachRoomData;
    } catch (error) {
      console.error('fail to list coachRoom', error);
      return false;
    }
  },
  async getSpaceCoachRoomOfProfileOrGroup(
    { commit, dispatch },
    { spaceID, filter, limit, nextToken, profileID, groupID } // eslint-disable-line comma-dangle
  ) {
    try {
      const coachRoomData = await dispatch(
        'api/query',
        { query: 'coachRoomBySpace', spaceID, filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_COACH_ROOM_WITH_PROFILE_OR_GROUP', { rooms: coachRoomData, profileID, groupID });
      return coachRoomData;
    } catch (error) {
      console.error('fail to list coachRoom by profile', error);
      return false;
    }
  },
  async createCoachRoom({ commit, dispatch }, input) {
    try {
      const newCoachRoom = await dispatch(
        'api/mutate',
        { mutation: 'createCoachRoom', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('ADD_COACH_ROOM', newCoachRoom);
      return newCoachRoom;
    } catch (error) {
      console.error('fail to create coachRoom', error);
      return false;
    }
  },
  async updateCoachRoom({ commit, dispatch }, input) {
    try {
      const updatedCoachRoom = await dispatch(
        'api/mutate',
        { mutation: 'updateCoachRoom', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('UPDATE_COACH_ROOM', input);
      return updatedCoachRoom;
    } catch (error) {
      console.error('fail to update coachRoom', error);
      return false;
    }
  },

  async getVision({ commit, dispatch }, id) {
    try {
      const visionData = await dispatch(
        'api/get',
        { query: 'getVision', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_VISION', visionData);
      return visionData;
    } catch (error) {
      console.error('fail to get vision', error);
      return false;
    }
  },
  async setVision({ commit }, vision) {
    try {
      commit('SET_VISION', vision);
      return vision;
    } catch (error) {
      console.error('fail to set vision', error);
      return false;
    }
  },
  async createVision({ commit, dispatch }, input) {
    try {
      const dataCreate = {
        coachRoomID: input.coachRoomID,
        title: input.title,
        description: input.description,
        coachs: input.coachs,
        files: input.files,
        profileID: input.profileID,
        isSetBackground: input.isSetBackground,
        isPublic: false,
        status: 'active',
      };
      const newVision = await dispatch(
        'api/mutate',
        { mutation: 'createVision', input: dataCreate },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('CREATE_VISION', newVision);
      return newVision;
    } catch (error) {
      console.error('fail to create vision', error);
      return false;
    }
  },
  async updateVision({ commit, dispatch }, input) {
    try {
      const dataUpdate = {
        ...input,
      };
      const updateVision = await dispatch(
        'api/mutate',
        { mutation: 'updateVision', input: dataUpdate },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('UPDATE_VISION', updateVision);
      return updateVision;
    } catch (error) {
      console.error('fail to update vision', error);
      return false;
    }
  },
  async updateVisionStatus({ commit, dispatch, state }, input) {
    try {
      if (input.status === 'active') {
        if (state.vision.id !== input.id) return;

        const checkCoachRoom = await dispatch(
          'api/get',
          { query: 'getCoachRoom', id: state.coachRoom.id },
          { root: true } // eslint-disable-line comma-dangle
        );
        commit('SET_VISIONS', checkCoachRoom.visions.items);
        const checkActiveVisions = checkCoachRoom.visions.items.filter(
          (item) => item.status === 'active' // eslint-disable-line comma-dangle
        );
        if (checkActiveVisions.length > 0) {
          dispatch(
            'alertError/addAlert',
            {
              type: 'alert-error',
              message: '現在のビジョンボードが編集であるので、解除できません。',
            },
            { root: true } // eslint-disable-line comma-dangle
          );
          // smooth scroll to top
          const mainEl = document.querySelector('.main');
          mainEl.scrollTo({ top: 0, behavior: 'smooth' });
          return;
        }
      }
      const dataUpdate = {
        id: input.id,
        status: input.status,
        archivedAt: input.status === 'archived' ? new Date().toISOString() : null,
      };
      const updateVision = await dispatch(
        'api/mutate',
        { mutation: 'updateVision', input: dataUpdate },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('UPDATE_VISION_STATUS', updateVision);
      return updateVision;
    } catch (error) {
      console.error('fail to update vision status', error);
      return false;
    }
  },
  async createGoal({ commit, dispatch }, input) {
    try {
      const dataCreate = {
        visionID: input.visionID,
        title: input.title,
        startDate: input.startDate,
        endDate: input.endDate,
        color: input.color,
        coachs: input.coachs,
        status: 'active',
        order: input.order,
      };
      const newGoal = await dispatch(
        'api/mutate',
        { mutation: 'createGoal', input: dataCreate },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('CREATE_GOAL', newGoal);
      if (newGoal?.id) {
        dispatch('updateVision', {
          id: newGoal.visionID,
          totalGoals: input.order,
        });
      }
      return newGoal;
    } catch (error) {
      console.error('fail to create goal', error);
      return false;
    }
  },
  async setGoalToUpdate({ commit }, input) {
    await commit('SET_GOAL_TO_UPDATE', input);
  },
  async updateGoal({ commit, dispatch }, input) {
    try {
      const dataUpdate = {
        ...input,
      };
      const updateGoal = await dispatch(
        'api/mutate',
        { mutation: 'updateGoal', input: dataUpdate },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('UPDATE_GOAL', updateGoal);
      return updateGoal;
    } catch (error) {
      console.error('fail to update goal', error);
      return false;
    }
  },
  async updateGoalStatus({ commit, dispatch }, input) {
    try {
      const dataUpdate = {
        id: input.goalID,
        status: input.status,
      };
      const updateGoal = await dispatch(
        'api/mutate',
        { mutation: 'updateGoal', input: dataUpdate },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('UPDATE_GOAL_STATUS', updateGoal);
      return updateGoal;
    } catch (error) {
      console.error('fail to update goal status', error);
      return false;
    }
  },
  async deleteGoal({ commit, dispatch }, input) {
    try {
      const deleteInput = {
        id: input.id,
      };
      commit('DELETE_GOAL', deleteInput);
      const getGoal = await dispatch(
        'api/get',
        { query: 'getGoal', id: input.id },
        { root: true } // eslint-disable-line comma-dangle
      );
      if (getGoal.id) {
        const links = getGoal.links.items;
        links.forEach(async (link) => {
          dispatch(
            'api/mutate',
            { mutation: 'deleteGoalLink', input: { id: link.id } },
            { root: true } // eslint-disable-line comma-dangle
          );
        });
        const issues = getGoal.issues.items || [];
        issues.forEach(async (issue) => {
          dispatch(
            'api/mutate',
            { mutation: 'deleteIssue', input: { id: issue.id } },
            { root: true } // eslint-disable-line comma-dangle
          );
        });
        const deleteGoal = await dispatch(
          'api/mutate',
          { mutation: 'deleteGoal', input: deleteInput },
          { root: true } // eslint-disable-line comma-dangle
        );
        return deleteGoal;
      }
      return false;
    } catch (error) {
      console.error('fail to delete goal', error);
      return false;
    }
  },

  async createIssue({ commit, dispatch }, input) {
    try {
      const createInput = {
        ...input,
        progress: 'beforeStart',
        status: 'active',
      };
      const newIssue = await dispatch(
        'api/mutate',
        { mutation: 'createIssue', input: createInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('CREATE_ISSUE', newIssue);
      return newIssue;
    } catch (error) {
      console.error('fail to create issue', error);
      return false;
    }
  },
  async setIssueToUpdate({ commit }, input) {
    await commit('SET_ISSUE_TO_UPDATE', input);
  },
  async updateIssue({ commit, dispatch }, input) {
    try {
      const updateInput = {
        ...input,
      };
      const updateIssue = await dispatch(
        'api/mutate',
        { mutation: 'updateIssue', input: updateInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('UPDATE_ISSUE', updateIssue);
      return updateIssue;
    } catch (error) {
      console.error('fail to update issue', error);
      return false;
    }
  },
  async updateIssueStatus({ commit, dispatch }, input) {
    try {
      const updateInput = {
        ...input,
      };
      const updateIssue = await dispatch(
        'api/mutate',
        { mutation: 'updateIssue', input: updateInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('UPDATE_ISSUE_STATUS', updateIssue);
      return updateIssue;
    } catch (error) {
      console.error('fail to update issue status', error);
      return false;
    }
  },
  async updateIssueProgress({ commit, dispatch }, input) {
    try {
      const updateInput = {
        ...input,
      };
      if (input.progress === 'achieved') {
        updateInput.status = 'completed';
      }
      commit('UPDATE_ISSUE_PROGRESS', updateInput);
      const updateIssue = await dispatch(
        'api/mutate',
        { mutation: 'updateIssue', input: updateInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      return updateIssue;
    } catch (error) {
      console.error('fail to update issue progress', error);
      return false;
    }
  },
  async deleteIssue({ commit, dispatch }, input) {
    try {
      const deleteInput = {
        id: input.id,
      };
      commit('DELETE_ISSUE', input);
      const getIssue = await dispatch(
        'api/get',
        { query: 'getIssue', id: input.id },
        { root: true } // eslint-disable-line comma-dangle
      );
      if (getIssue.id) {
        const links = getIssue.links.items;
        links.forEach(async (link) => {
          dispatch(
            'api/mutate',
            { mutation: 'deleteIssueLink', input: { id: link.id } },
            { root: true } // eslint-disable-line comma-dangle
          );
        });
        const deleteIssue = await dispatch(
          'api/mutate',
          { mutation: 'deleteIssue', input: deleteInput },
          { root: true } // eslint-disable-line comma-dangle
        );
        return deleteIssue;
      }
      return false;
    } catch (error) {
      console.error('fail to delete issue', error);
      return false;
    }
  },

  async openModalConfirm({ commit }, input) {
    commit('OPEN_MODAL_CONFIRM', input);
  },
  async closeModalConfirm({ commit }) {
    commit('CLOSE_MODAL_CONFIRM');
  },

  async openModalSetCoach({ commit }, input) {
    commit('OPEN_MODAL_SET_COACH', input);
  },
  async closeModalSetCoach({ commit }) {
    commit('CLOSE_MODAL_SET_COACH');
  },

  async openModalSetGroup({ commit }, input) {
    commit('OPEN_MODAL_SET_GROUP', input);
  },
  async closeModalSetGroup({ commit }) {
    commit('CLOSE_MODAL_SET_GROUP');
  },

  async batchUpdateCoachForTarget({ commit }, input) {
    const { target } = input;
    let mutation = '';
    let responseKey = '';
    if (target === 'vision') {
      mutation = batchUpdateVisionsCoachs;
      responseKey = 'batchUpdateVisionsCoachs';
    } else if (target === 'goal') {
      mutation = batchUpdateGoalsCoachs;
      responseKey = 'batchUpdateGoalsCoachs';
    } else if (target === 'issue') {
      mutation = batchUpdateIssuesCoachs;
      responseKey = 'batchUpdateIssuesCoachs';
    }
    try {
      const response = await API.graphql(
        graphqlOperation(mutation, { ids: input.ids, coachs: input.coachs }) // eslint-disable-line
      );
      const updatedTargets = response.data[responseKey];
      commit('SET_CURRENT_COACH_IDS', { key: target, ids: input.coachs });
      console.log(updatedTargets);
    } catch (error) {
      console.error('Error updating coach for target', error);
    }
  },

  async getCoachRoomsShared({ commit, state }, group) {
    await commit('SET_COACH_ROOMS_SHARED', group);
    const roomToSwitch = state.sharedCoachRooms[0];
    commit('SET_COACH_ROOM', roomToSwitch);
  },

  async setCoachRoom({ commit }, room) {
    await commit('SET_COACH_ROOM', room);
  },

  async createIssueLink({ dispatch }, input) {
    try {
      const createInput = {
        ...input,
      };
      const newIssueLink = await dispatch(
        'api/mutate',
        { mutation: 'createIssueLink', input: createInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      return newIssueLink;
    } catch (error) {
      console.error('fail to create Issue Link', error);
      return false;
    }
  },

  async deleteIssueLink({ dispatch }, id) {
    try {
      const deleteInput = {
        id,
      };
      const deleteIssueLink = await dispatch(
        'api/mutate',
        { mutation: 'deleteIssueLink', input: deleteInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      return deleteIssueLink;
    } catch (error) {
      console.error('fail to delete issue link', error);
      return false;
    }
  },

  async issueLinksByObject(
    { dispatch },
    { targetID, filter, limit, nextToken } // eslint-disable-line comma-dangle
  ) {
    try {
      const issueLinks = await dispatch(
        'api/query',
        { query: 'issueLinksByObject', targetID, filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      return issueLinks;
    } catch (error) {
      console.error('fail to list issue link', error);
      return false;
    }
  },

  async getIssue({ commit, dispatch }, id) {
    try {
      const issueData = await dispatch(
        'api/get',
        { query: 'getIssue', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_ISSUE', issueData);
      return issueData;
    } catch (error) {
      console.error('fail to get issue', error);
      return false;
    }
  },
  async listIssueLinks({ dispatch }, { filter, limit = 1000000 }) {
    try {
      const linkData = await dispatch(
        'api/queryAll',
        { query: 'listIssueLinks', filter, limit },
        { root: true } // eslint-disable-line comma-dangle
      );
      return linkData;
    } catch (error) {
      console.error('fail to list Issue Link', error);
      return false;
    }
  },
  async goalLinksByObject(
    { dispatch },
    { targetID, filter, limit, nextToken } // eslint-disable-line comma-dangle
  ) {
    try {
      const goalLinks = await dispatch(
        'api/query',
        { query: 'goalLinksByObject', targetID, filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      return goalLinks;
    } catch (error) {
      console.error('fail to list goal link', error);
      return false;
    }
  },
  async visionLinksByObject(
    { dispatch },
    { targetID, filter, limit, nextToken } // eslint-disable-line comma-dangle
  ) {
    try {
      const visionLinks = await dispatch(
        'api/query',
        { query: 'visionLinksByObject', targetID, filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      return visionLinks;
    } catch (error) {
      console.error('fail to list vision link', error);
      return false;
    }
  },
  async deleteVisionLink({ dispatch }, id) {
    try {
      const deleteInput = {
        id,
      };
      const deleteVisionLink = await dispatch(
        'api/mutate',
        { mutation: 'deleteVisionLink', input: deleteInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      return deleteVisionLink;
    } catch (error) {
      console.error('fail to delete vision link', error);
      return false;
    }
  },

  async createVisionLink({ dispatch }, input) {
    try {
      const createInput = {
        ...input,
      };
      const newVisionLink = await dispatch(
        'api/mutate',
        { mutation: 'createVisionLink', input: createInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      return newVisionLink;
    } catch (error) {
      console.error('fail to create vision Link', error);
      return false;
    }
  },

  async deleteGoalLink({ dispatch }, id) {
    try {
      const deleteInput = {
        id,
      };
      const deleteGoalLink = await dispatch(
        'api/mutate',
        { mutation: 'deleteGoalLink', input: deleteInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      return deleteGoalLink;
    } catch (error) {
      console.error('fail to delete goal link', error);
      return false;
    }
  },
  async createGoalLink({ dispatch }, input) {
    try {
      const createInput = {
        ...input,
      };
      const newGoalLink = await dispatch(
        'api/mutate',
        { mutation: 'createGoalLink', input: createInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      return newGoalLink;
    } catch (error) {
      console.error('fail to create goal Link', error);
      return false;
    }
  },
  setSelectItemsForLink({ commit }, { issues, goals, visions }) {
    commit('SET_SELECT_ITEMS_FOR_LINK', { issues, goals, visions });
  },
  setHiddenIssue({ commit }, hidden) {
    commit('SET_HIDDEN_ISSUE', hidden);
  },
  openModalProgressHelper({ commit }) {
    commit('OPEN_MODAL_PROGRESS_HELPER');
  },
  closeModalProgressHelper({ commit }) {
    commit('CLOSE_MODAL_PROGRESS_HELPER');
  },
  openModalConfirmChangeProgress({ commit }, input) {
    commit('OPEN_MODAL_CONFIRM_CHANGE_PROGRESS', input);
  },
  closeModalConfirmChangeProgress({ commit }) {
    commit('CLOSE_MODAL_CONFIRM_CHANGE_PROGRESS');
  },
};

export const getters = {
  coachRoom: (state) => state.coachRoom,
  coachRooms: (state) => state.coachRooms,
  visions: (state) => state.visions,
  goals: (state) => state.goals,
  goalToUpdate: (state) => state.goalToUpdate,
  issueToUpdate: (state) => state.issueToUpdate,
  modalConfirm: (state) => state.modalConfirm,
  modalConfirmQuestion: (state) => state.modalConfirmQuestion,
  modalYesButtonText: (state) => state.modalYesButtonText,
  modalConfirmAction: (state) => state.modalConfirmAction,
  vision: (state) => state.vision,
  modalSettingCoach: (state) => state.modalSettingCoach,
  modalSettingGroup: (state) => state.modalSettingGroup,
  sharedCoachRooms: (state) => state.sharedCoachRooms,
  archivedVisions: (state) => state.archivedVisions,
  issue: (state) => state.issue,
  linkWithNewMessage: (state) => state.linkWithNewMessage,
  showIssue: (state) => state.showIssue,
  // activeGoals: (state) => state.goals.filter((g) => g.status === 'active'),
  // completedGoals: (state) => state.goals.filter((g) => g.status === 'completed'),
  modalProgressHelper: (state) => state.modalProgressHelper,
  issueProgressesText: (state) => state.issueProgressesText,
  issueProgressesTextDefault: (state) => state.issueProgressesTextDefault,
  issueProgressesInteger: (state) => state.issueProgressesInteger,
  issueProgressesIntegerDefault: (state) => state.issueProgressesIntegerDefault,
  issueProgressesDisplay: (state) => state.issueProgressesDisplay,
  modalConfirmChangeProgress: (state) => state.modalConfirmChangeProgress,
  modalConfirmChangeProgressAction: (state) => state.modalConfirmChangeProgressAction,
  modalConfirmChangeProgressTitle: (state) => state.modalConfirmChangeProgressTitle,
  modalConfirmChangeProgressDescription: (state) => state.modalConfirmChangeProgressDescription,
  progressHelper: (state) => state.progressHelper,
};
