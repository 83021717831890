function sortByCreatedAt(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}

function getTime(commentAt) {
  const [seconds, minutes, hours] = commentAt.split(':').reverse();
  return (hours || 0) * 3600 + minutes * 60 + seconds * 1;
}

function sortByCommentAt(a, b) {
  const x = getTime(a.commentAt);
  const y = getTime(b.commentAt);
  return x - y || sortByCreatedAt(a, b);
}

export const state = () => ({
  videoComments: [],
  messToUpdate: null,
  messToAnswer: null,
  videoCurrentTime: 0,
  commentFilter: [],
  searchStr: '',
  strFilteredComments: [],
  currentFilteredCommentId: null,
});

export const mutations = {
  SET_LIST_COMMENTS(state, commentsData) {
    state.videoComments = commentsData;
  },
  SET_COMMENT(state, comment) {
    const commentIndex = state.videoComments.findIndex((item) => item.id === comment.id);
    if (commentIndex === -1) return;
    state.videoComments = [
      ...state.videoComments.slice(0, commentIndex),
      comment,
      ...state.videoComments.slice(commentIndex + 1),
    ];
  },
  CREATE_COMMENT(state, comment) {
    const { videoComments } = state;
    videoComments.push(comment);
    state.videoComments = [...videoComments];
  },
  UPDATE_COMMENT(state, newComment) {
    const { videoComments } = state;
    let updateIndex = null;
    videoComments.forEach((comm, index) => {
      if (comm.id === newComment.id) updateIndex = index;
    });
    videoComments[updateIndex] = newComment;
    state.videoComments = [...videoComments];
  },
  DELETE_COMMENT(state, deleteId) {
    const { videoComments } = state;
    state.videoComments = videoComments.filter(
      (comm) => comm.id !== deleteId && comm.parentID !== deleteId // eslint-disable-line comma-dangle, max-len
    );
  },
  SET_MESS_TO_UPDATE(state, mess) {
    state.messToUpdate = mess;
  },
  SET_MESS_TO_ANSWER(state, mess) {
    state.messToAnswer = mess;
  },
  SET_VIDEO_CURRENT_TIME(state, time) {
    state.videoCurrentTime = time;
  },
  SET_LIST_GUEST_COMMENTS(state, commentsData) {
    const { videoComments } = state;
    state.videoComments = videoComments.concat(commentsData);
  },
  SET_COMMENT_FILTER(state, filter) {
    state.commentFilter = filter;
  },
  SET_SEARCH_STR(state, searchStr) {
    state.searchStr = searchStr;
  },
  SET_CURRENT_COMMENT(state, id) {
    state.currentFilteredCommentId = id;
  },
};

export const actions = {
  async getComments({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const commentsData = await dispatch(
        'api/query',
        { query: 'listComments', filter, limit, nextToken },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      commit('SET_LIST_COMMENTS', commentsData);
      return Promise.resolve();
    } catch (error) {
      console.error('get Comments', error);
      return Promise.reject();
    }
  },
  async createComment({ dispatch }, input) {
    try {
      const comment = await dispatch(
        'api/mutate',
        { mutation: 'createComment', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      return comment;
    } catch (error) {
      console.error('create Comment', error);
      return Promise.reject();
    }
  },
  async setComment({ commit }, comment) {
    commit('SET_COMMENT', comment);
  },
  async updateComment({ dispatch }, input) {
    try {
      const comment = await dispatch(
        'api/mutate',
        { mutation: 'updateComment', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      return comment;
    } catch (error) {
      console.error('update Comment', error);
      return Promise.reject();
    }
  },
  async getCommentsPublic({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const commentsData = await dispatch(
        'api/queryPublic',
        { query: 'listCommentsGuest', filter, limit, nextToken },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      commit('SET_LIST_COMMENTS', commentsData);
      return Promise.resolve();
    } catch (error) {
      console.error('get Comments', error);
      return Promise.reject();
    }
  },
  async listGuestComments({ dispatch, commit }, { filter, limit, nextToken }) {
    try {
      const data = await dispatch(
        'api/queryPublic',
        { query: 'listGuestComments', filter, limit, nextToken },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      commit('SET_LIST_GUEST_COMMENTS', data);
      return data;
    } catch (error) {
      console.error('get Guest Comments', error);
      return Promise.reject();
    }
  },
  async createGuestComment({ dispatch, commit }, input) {
    try {
      const data = await dispatch(
        'api/mutatePublic',
        { mutation: 'createGuestComment', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      if (data.id) commit('CREATE_COMMENT', data);
      return data;
    } catch (error) {
      console.error('create Guest Comments', error);
      return Promise.reject();
    }
  },
  async updateGuestComment({ dispatch, commit }, input) {
    try {
      const data = await dispatch(
        'api/mutatePublic',
        { mutation: 'updateGuestComment', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      if (data.id) commit('UPDATE_COMMENT', data);
      return data;
    } catch (error) {
      console.error('update Guest Comments', error);
      return Promise.reject();
    }
  },
  async deleteGuestComment({ dispatch, commit, state }, id) {
    try {
      const data = await dispatch(
        'api/mutatePublic',
        { mutation: 'deleteGuestComment', input: { id } },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      const { videoComments } = state;
      const childChatToDelete = videoComments.filter((chat) => chat.parentID === id);
      childChatToDelete.forEach((chat) => {
        if (chat.name) {
          dispatch(
            'api/mutatePublic',
            { mutation: 'deleteGuestComment', input: { id: chat.id } },
            { root: true }, // eslint-disable-line prettier/prettier
          );
        } else {
          dispatch(
            'api/mutatePublic',
            { mutation: 'deleteComment', input: { id: chat.id } },
            { root: true }, // eslint-disable-line prettier/prettier
          );
        }
      });
      if (data.id) commit('DELETE_COMMENT', data.id);
    } catch (error) {
      console.error('delete Guest Comments', error);
      return Promise.reject();
    }
  },
  async deleteComment({ dispatch, state }, id) {
    await dispatch('api/mutate', { mutation: 'deleteComment', input: { id } }, { root: true });
    const { videoComments } = state;
    const childChatToDelete = videoComments.filter((chat) => chat.parentID === id);
    childChatToDelete.forEach((chat) => {
      if (chat.name) {
        dispatch(
          'api/mutatePublic',
          { mutation: 'deleteGuestComment', input: { id: chat.id } },
          { root: true }, // eslint-disable-line prettier/prettier
        );
      } else {
        dispatch(
          'api/mutatePublic',
          { mutation: 'deleteComment', input: { id: chat.id } },
          { root: true }, // eslint-disable-line prettier/prettier
        );
      }
    });
  },
  async setMessToUpdate({ commit }, mess) {
    commit('SET_MESS_TO_UPDATE', mess);
  },
  async setMessToAnswer({ commit }, mess) {
    commit('SET_MESS_TO_ANSWER', mess);
  },
  async setVideoCurrentTime({ commit }, mess) {
    commit('SET_VIDEO_CURRENT_TIME', mess);
  },
  async addNewCommentRealTime({ commit }, mess) {
    commit('CREATE_COMMENT', mess);
  },
  async updateCommentRealTime({ commit }, mess) {
    commit('UPDATE_COMMENT', mess);
  },
  async deleteCommentRealTime({ commit }, id) {
    commit('DELETE_COMMENT', id);
  },
  setCommentFilter({ commit }, filter) {
    commit('SET_COMMENT_FILTER', [...filter]);
  },
  async getComment({ dispatch }, id) {
    try {
      const chatData = await dispatch(
        'api/get',
        { query: 'getComment', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      return chatData;
    } catch (error) {
      console.error('fail to get comment', error);
      return false;
    }
  },
  setSearchStr({ commit, dispatch }, searchString) {
    commit('SET_SEARCH_STR', searchString);
    dispatch('moveSearchComment', 0);
  },
  moveSearchComment({ commit, getters }, count) {
    const commentIds = getters.strFilteredComments.map((comment) => comment.id);
    const currentId = getters.currentFilteredCommentId;
    const currentIndex = commentIds.indexOf(currentId);
    let nextIndex = 0;
    if (currentIndex > -1) {
      nextIndex = currentIndex + count;
      if (nextIndex >= commentIds.length) {
        nextIndex %= commentIds.length;
      } else if (nextIndex < 0) {
        nextIndex =
          commentIds.length === 1 ? 0 : commentIds.length + (nextIndex % commentIds.length);
      }
    }
    commit('SET_CURRENT_COMMENT', commentIds[nextIndex]);
  },
};

export const getters = {
  listComments: (state) => {
    const { videoComments } = state;
    const returnValues = [...videoComments];
    return returnValues.sort(sortByCommentAt);
  },
  commentFilter: (state) => state.commentFilter,
  messToUpdate: (state) => state.messToUpdate,
  messToAnswer: (state) => state.messToAnswer,
  videoCurrentTime: (state) => state.videoCurrentTime,
  // eslint-disable-next-line no-unused-vars
  searchCount: (state, _getters) => _getters.strFilteredComments.length,
  currentFilteredCommentId: (state) => state.currentFilteredCommentId,
  searchStr: (state) => state.searchStr,
  strFilteredComments: (state) => {
    let comments = [];
    if (state.searchStr.length > 0) {
      comments = state.videoComments
        .filter((comment) => comment.content.includes(state.searchStr))
        .sort(sortByCommentAt);
      if (state.commentFilter.length > 0) {
        comments = comments.filter((comment) => {
          if (comment.parentID === 'true') {
            return state.commentFilter.includes(comment.type);
          }
          const parentComment = state.videoComments.find(
            // eslint-disable-next-line comma-dangle
            (otherComment) => otherComment.id === comment.parentID
          );
          return state.commentFilter.includes(parentComment.type);
        });
      }
    } else {
      comments = [];
    }
    return comments;
  },
  searchCurrent: (state, _getters) => {
    const ids = _getters.strFilteredComments.map((comment) => comment.id);
    return ids.indexOf(state.currentFilteredCommentId) + 1;
  },
  openChildComments: (state, _getters) => {
    const parentIDs = _getters.strFilteredComments
      .map((comment) => comment.parentID)
      .filter((parentID) => parentID !== 'true');
    // 重複除去
    return [...new Set(parentIDs)];
  },
};
