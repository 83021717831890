//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Loading from 'vue-loading-overlay'; // eslint-disable-line
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: { Loading },
  props: {
    isFullPage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('loading', ['isLoading', 'isLoadingChat', 'isLoadingChatScrollDown']),
    activeLoadingChat() {
      return this.isLoadingChatScrollDown || this.isLoadingChat;
    },
  },
};
