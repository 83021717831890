import { Storage } from 'aws-amplify';

export const state = () => ({
  userSpaceProfile: {},
  listSpaceProfiles: [],
  listMemberProfiles: [],
  listUserMentions: {},
  listUserMentionsReverse: {},
  listUserMentionsID: [],
});

export const mutations = {
  SET_USER_SPACE_PROFILE(state, profile) {
    if (profile.confirmed === null) {
      profile.confirmed = true;
    }
    state.userSpaceProfile = profile;
  },
  SET_LIST_PROFILES(state, listSpaceProfiles) {
    state.listSpaceProfiles = listSpaceProfiles;
  },
  SET_LIST_MEMBERS(state, listSpaceProfiles) {
    state.listMemberProfiles = listSpaceProfiles;
  },
  CONFIRM_CURRENT_SPACE_PROFILE(state) {
    state.userSpaceProfile.confirmed = true;
  },
  SET_LIST_MENTIONS(state, profiles) {
    const returnData = {};
    const returnDataRev = {};
    profiles.forEach((profile) => {
      returnData[profile.id] = profile.name;
      returnDataRev[profile.name] = [profile.id];
      state.listUserMentionsID.push(profile.id);
    });
    state.listUserMentions = returnData;
    state.listUserMentionsReverse = returnDataRev;
  },
  SET_FILTER_VISION_MESSAGE(state, filterVisionMessage) {
    state.userSpaceProfile.filterVisionMessage = filterVisionMessage;
  },
};

export const actions = {
  async getProfileSpace({ commit, dispatch }, id) {
    try {
      const profileData = await dispatch(
        'api/get',
        { query: 'getProfile', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      if (profileData) {
        if (profileData.avatar) {
          profileData.avatarUrl = await Storage.get(profileData.avatar.key?.normalize('NFD'));
        }
        commit('SET_USER_SPACE_PROFILE', profileData);
      } else {
        commit('SET_USER_SPACE_PROFILE', {});
      }
      return profileData;
    } catch (error) {
      console.error('fail to get Profile', error);
      return false;
    }
  },

  async getProfile({ dispatch }, id) {
    try {
      const profileData = await dispatch(
        'api/get',
        { query: 'getProfile', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      return profileData;
    } catch (error) {
      console.error('fail to get Profile', error);
      return false;
    }
  },

  async getUserSpaceProfile({ commit, dispatch }, input) {
    try {
      const filter = {
        spaceID: { eq: input.spaceId },
        userID: { eq: input.userId },
      };
      const profile = await dispatch(
        'api/query',
        { query: 'listProfiles', filter },
        { root: true } // eslint-disable-line comma-dangle
      );
      const userSpaceProfile = profile.length > 0 ? profile[0] : {};
      if (userSpaceProfile) {
        if (userSpaceProfile.avatar) {
          userSpaceProfile.avatarUrl = await Storage.get(
            userSpaceProfile.avatar.key?.normalize('NFD') // eslint-disable-line
          );
        }
        commit('SET_USER_SPACE_PROFILE', userSpaceProfile);
      } else {
        commit('SET_USER_SPACE_PROFILE', {});
      }
      return profile;
    } catch (error) {
      console.error('fail to get Profile by filter', error);
      return false;
    }
  },

  async listSpaceProfile({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const profilesData = await dispatch(
        'api/query',
        { query: 'listProfiles', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      const returnData = [];
      Promise.all(
        profilesData.map(async (profile) => {
          const key = profile.avatar?.key;
          if (key) {
            profile.avatarUrl = await Storage.get(key?.normalize('NFD'));
          }
          returnData.push(profile);
        }) // eslint-disable-line comma-dangle
      ).then(() => {
        commit('SET_LIST_PROFILES', returnData);
      });
      return profilesData;
    } catch (error) {
      console.error('fail to list Space Profile', error);
      return false;
    }
  },

  async listMemberProfile(
    { commit, dispatch },
    { filter, limit, nextToken, isListUserMention = false } // eslint-disable-line
  ) {
    try {
      const profilesData = await dispatch(
        'api/query',
        { query: 'listProfiles', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      const returnData = [];
      Promise.all(
        profilesData.map(async (profile) => {
          const key = profile.avatar?.key;
          if (key) {
            profile.avatarUrl = await Storage.get(key?.normalize('NFD'));
          }
          returnData.push(profile);
        }) // eslint-disable-line comma-dangle
      ).then(() => {
        commit('SET_LIST_MEMBERS', returnData);
      });
      if (isListUserMention) {
        commit('SET_LIST_MENTIONS', profilesData);
      }
      return profilesData;
    } catch (error) {
      console.error('fail to list Member Profile', error);
      return false;
    }
  },

  async listProfile({ dispatch }, { filter, limit, nextToken }) {
    try {
      const profiles = await dispatch(
        'api/query',
        { query: 'listProfiles', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      return profiles;
    } catch (error) {
      console.error('fail to public list Profile', error);
      return false;
    }
  },

  async listMemberProfilePublic({ dispatch }, { filter, limit, nextToken }) {
    try {
      const profiles = await dispatch(
        'api/queryPublic',
        { query: 'listProfilesPublic', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      return profiles;
    } catch (error) {
      console.error('fail to public list Member Profile', error);
      return false;
    }
  },

  async createProfile({ commit, dispatch }, input) {
    try {
      const profile = await dispatch(
        'api/mutate',
        { mutation: 'createProfile', input },
        { root: true } // eslint-disable-line
      );
      commit('SET_USER_SPACE_PROFILE', profile);
      return profile;
    } catch (error) {
      console.error('fail to create Profile', error);
      return false;
    }
  },
  async updateProfile({ dispatch }, input) {
    try {
      const profile = await dispatch(
        'api/mutate',
        { mutation: 'updateProfile', input },
        { root: true } // eslint-disable-line
      );
      // commit('SET_USER_SPACE_PROFILE', profile);
      return profile;
    } catch (error) {
      console.error('fail to update Profile', error);
      return false;
    }
  },
  async deleteProfile({ dispatch }, id) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'deleteProfile', input: { id } },
        { root: true } // eslint-disable-line
      );
    } catch (error) {
      console.error('fail to delete Profile', error);
      return false;
    }
  },

  async removeSpaceProfile({ commit }) {
    commit('SET_USER_SPACE_PROFILE', {});
  },
};

export const getters = {
  userSpaceProfile: (state) => state.userSpaceProfile,
  listSpaceProfiles: (state) => state.listSpaceProfiles,
  listMemberProfiles: (state) => state.listMemberProfiles,
  listUserMentions: (state) => state.listUserMentions,
  listUserMentionsReverse: (state) => state.listUserMentionsReverse,
  listUserMentionsID: (state) => state.listUserMentionsID,
};
