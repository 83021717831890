export const state = () => ({
  historyCount: window.history.length,
  adminUsersPath: '/admin/users/',
  adminSpacesPath: '/admin/spaces',
});

export const mutations = {
  SET_USER_GOBACK_PATH(state, path) {
    state.adminUsersPath = path;
  },
  SET_SPACE_GOBACK_PATH(state, path) {
    state.adminSpacesPath = path;
  },
};

export const actions = {
  setUserGoBackPath({ commit, dispatch }, path) {
    commit('SET_USER_GOBACK_PATH', path);
    dispatch('resetSpaceGoBackPath');
  },
  resetUserGoBackPath({ commit }) {
    commit('SET_USER_GOBACK_PATH', '/admin/users/');
  },
  setSpaceGoBackPath({ commit, dispatch }, path) {
    commit('SET_SPACE_GOBACK_PATH', path);
    dispatch('resetUserGoBackPath');
  },
  resetSpaceGoBackPath({ commit }) {
    commit('SET_SPACE_GOBACK_PATH', '/admin/spaces');
  },
};

export const getters = {
  historyCount: (state) => state.historyCount,
  adminUsersPath: (state) => state.adminUsersPath,
  adminSpacesPath: (state) => state.adminSpacesPath,
};
