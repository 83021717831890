export default async ({ store, route, redirect }) => {
  const {
    currentUser: { id: userID },
  } = store.state.auth;
  const { spaceID: profileSpaceID, status, role } = store.state.profile.userSpaceProfile;
  const { currentSpace } = store.state.space;
  const spaceId = route.path.split('/')[2];
  if (
    !(
      userID === currentSpace.userID ||
      (spaceId === profileSpaceID && status === 'active' && role === 'editors')
    )
  ) {
    return redirect('/space');
  }
};
