export default ({ redirect, store }) => {
  console.log('auth', store.state.auth.isAuthenticated);
  if (!store.state.auth.isAuthenticated) {
    if (
      process.env.LP_URL !== undefined &&
      process.env.LP_URL !== null &&
      process.env.LP_URL !== ''
    ) {
      return redirect(process.env.LP_URL);
    }
  }
};
