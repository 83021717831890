const jaLocale = {
  common: {
    success: '成功しました',
    error: 'エラー',
    errorWithMessage: 'エラー。Cause: {message}',
    updated: '更新しました',
    copied: 'コピーしました',
    nameTaken: 'この名前は使われています',
  },
  auth: {
    incorrectLogin: '無効なメールアドレス/パスワードの組み合わせです。',
    invalidUser: '無効なユーザーです。管理者にお問い合わせください',
    registerError: '登録中にエラーが発生しました。Cause: {message}',
    alreadyRegistered: 'すでに登録済みのメールアドレスです。確認コードを再送します',
    forgotPasswordError: '',
    loginError: 'ログインエラー。Cause: {message}',
    checkVerifyCode: '{email}で確認コードを確認してください',
    userAlreadyConfirmed: 'すでに登録済みのメールアドレスです。',
    confirmCodeError: '無効な確認コードです。',
    sendResetPasswordEmail: 'パスワード再設定メールを送付しました。',
  },
  user: {
    createError: 'ユーザーを作成できない',
    deactivated: 'このアカウントは無効になっています',
    adminEnabled: '有効にしました。',
    adminDisabled: '無効にしました。',
    notFound: '存在しないユーザーです。',
    emailUpdated: 'メールアドレス更新が完了しました。',
    nameUpdated: 'ユーザー名の変更が完了しました。',
    passwordUpdated: 'パスワードの変更が完了しました。',
  },
  space: {
    deleted: 'このスペースはすでに削除されました。',
    nameTaken: 'この名前は使われています',
    spaceLimitMember: '参加人数が上限に達しました。',
    invitationExpired: '招待リンクが無効になっています。',
    invitationInvalid: 'このスペースへの招待は無効です',
    alreadyJoined: 'このスペースに参加しています',
    noPlan: '計画は設定されていません',
  },
  group: {
    deleted: 'このグループが削除されました。',
    nameTaken: 'グループ名が重複しています・',
  },
  profile: {
    nameTaken:
      'プロフィール名が重複しています。スペース内でプロフィール名は一意である必要があります。',
    updateProfile: 'プロフィール変更が完了しました。',
  },
  video: {
    upload: {
      groupRequired: 'グループを選択してください。',
      spaceLimitUpload: 'アップロード容量が超過しています。',
    },
    share: {
      invalid: '共有したビデオリンクが無効です',
    },
    changeToGroupError: 'このビデオはグループに公開されました',
    changeToSpaceError: 'このビデオはスペースに公開されました',
    delete: '動画の削除が完了しました。',
  },
  upload: {
    s3Error: 'アップロードエラー。Cause: {message}',
    sizeLimit2MB: 'ファイルサイズは2MBを超えることはできません',
    sizeLimit5MB: 'ファイルサイズは5MBを超えることはできません',
    sizeLimit10MB: 'ファイルサイズは10MBを超えることはできません',
    imageLimit2MB: 'プロフィール画像のサイズは2MBまでです。',
    blankError: 'ファイルは空白にすることはできません',
    imageUploadComplete: '画像のアップロードが完了しました。',
    videoUploadComplete: '動画アップロードが完了しました。',
  },
  payJP: {
    alreadyAddCard: 'このカードは追加されています',
  },
  admin: {
    space: {
      userAlreadyAdd: 'すでに登録済みのユーザーです。',
    },
  },
};

export default jaLocale;
