export default async ({ store, redirect }) => {
  const sliceLength = 130;
  const { currentUser } = store.state.auth;
  const profiles = currentUser.profiles.items;
  const profilesActive = profiles.filter((profile) => profile.status === 'active');
  if (profilesActive.length <= 0) {
    return redirect('/space/register');
  }
  const spaceIds = profilesActive.map((profile) => ({
    id: { eq: profile.spaceID },
    status: { ne: 'inactive' },
  }));
  const spaceIdChunks = [];
  for (let i = 0; i < spaceIds.length; i += sliceLength) {
    spaceIdChunks.push(spaceIds.slice(i, i + sliceLength));
  }
  const spaces = [];
  await Promise.all(
    spaceIdChunks.map(async (chunk) => {
      const filter = {
        or: chunk,
      };
      const spaceData = await store.dispatch('space/listSpace', { filter, limit: '10000' });
      spaces.push(...spaceData);
      // eslint-disable-next-line comma-dangle
    })
  );

  if (spaces.length > sliceLength) {
    await store.dispatch('space/setListSpace', { spaces });
  }

  if (spaces.length <= 0) {
    return redirect('/space/register');
  }
};
