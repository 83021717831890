import { API, Auth } from 'aws-amplify';

const processStatuses = {
  ready: 'ready',
  processing: 'processing',
  success: 'success',
  error: 'error',
};

export const state = () => ({
  processDeleteProfiles: processStatuses.ready,
  processDeleteChats: processStatuses.ready,
  processDeleteNotifications: processStatuses.ready,
  processDeleteGroups: processStatuses.ready,
  processDeleteJoinGroups: processStatuses.ready,
  processDeleteVideoAndInfos: processStatuses.ready,
  processDeletePublicSpaces: processStatuses.ready,
  processDeletePublicGroups: processStatuses.ready,
  processDeleteSpaceInvitations: processStatuses.ready,
  processDeletePayJPSubscriptions: processStatuses.ready,
  processDeletePaymentHistories: processStatuses.ready,
  processDeleteJoinSpaceKeys: processStatuses.ready,
  processDeleteSpace: processStatuses.ready,
  allProcesses: processStatuses.ready,
});

export const mutations = {
  set(state, { key, value }) {
    state[key] = value;
  },
  SET_PROCESSING(state, { key }) {
    state[key] = processStatuses.processing;
  },
  SET_SUCCESS(state, { key }) {
    state[key] = processStatuses.success;
  },
  SET_ERROR(state, { key }) {
    state[key] = processStatuses.error;
  },
  RESET_PROCESSES_STATUS(state) {
    state.processDeleteProfiles = processStatuses.ready;
    state.processDeleteChats = processStatuses.ready;
    state.processDeleteNotifications = processStatuses.ready;
    state.processDeleteGroups = processStatuses.ready;
    state.processDeleteJoinGroups = processStatuses.ready;
    state.processDeleteVideoAndInfos = processStatuses.ready;
    state.processDeletePublicSpaces = processStatuses.ready;
    state.processDeletePublicGroups = processStatuses.ready;
    state.processDeleteSpaceInvitations = processStatuses.ready;
    state.processDeletePayJPSubscriptions = processStatuses.ready;
    state.processDeletePaymentHistories = processStatuses.ready;
    state.processDeleteJoinSpaceKeys = processStatuses.ready;
    state.processDeleteSpace = processStatuses.ready;
    state.allProcesses = processStatuses.ready;
  },
};

// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

export const actions = {
  async get({ commit }, { query, key, params }) {
    const apiName = 'AdminQueries';
    const path = `/${query}`;
    const myInit = {
      queryStringParameters: {
        ...params,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const { ...value } = await API.get(apiName, path, myInit);
    if (key) commit('set', { key, value });
    return value;
  },

  async post({ commit }, { query, key, params }) {
    const apiName = 'AdminQueries';
    const path = `/${query}`;
    const myInit = {
      body: {
        ...params,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const { ...value } = await API.post(apiName, path, myInit);
    if (key) commit('set', { key, value });
    return value;
  },

  resetAllProcessesStatus({ commit }) {
    commit('RESET_PROCESSES_STATUS');
  },

  // eslint-disable-next-line no-unused-vars
  async deleteSpaceAndRelation({ commit, dispatch }, space) {
    try {
      const processes = [];
      const { id: spaceID } = space;
      // 2. Like
      const deleteLikes = async (profileID, nextToken = null) => {
        try {
          const filter = {
            profileID: { eq: profileID },
          };
          const { items: likesData, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listLikes', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            likesData.map(async (like) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteLike', input: { id: like.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteLikes(profileID, returnNextToken);
          }
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete likes error:', error);
          return Promise.reject();
        }
      };
      // 1. Profile
      const deleteProfiles = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeleteProfiles' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: profilesData, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listProfiles', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            profilesData.map(async (profile) => {
              const { id: profileID } = profile;
              await deleteLikes(profileID);
              await dispatch(
                'api/mutate',
                { mutation: 'deleteProfile', input: { id: profileID } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteProfiles(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeleteProfiles' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete profiles error:', error);
          commit('SET_ERROR', { key: 'processDeleteProfiles' });
          return Promise.reject();
        }
      };
      // 3. Chat
      const deleteChats = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeleteChats' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: chatData, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listChats', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            chatData.map(async (chat) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteChat', input: { id: chat.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteChats(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeleteChats' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete chats error:', error);
          commit('SET_ERROR', { key: 'processDeleteChats' });
          return Promise.reject();
        }
      };
      // 4. Notification
      const deleteNotifications = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeleteNotifications' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: notiData, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listNotifications', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            notiData.map(async (noti) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteNotification', input: { id: noti.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteNotifications(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeleteNotifications' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete noti error:', error);
          commit('SET_ERROR', { key: 'processDeleteNotifications' });
          return Promise.reject();
        }
      };
      // 6. ChatGroup
      const deleteChatGroups = async (groupID, nextToken = null) => {
        try {
          const filter = {
            groupID: { eq: groupID },
          };
          const { items: chatGroups, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listChatGroups', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            chatGroups.map(async (chatGroup) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteChatGroup', input: { id: chatGroup.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteChatGroups(groupID, returnNextToken);
          }
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete chat groups error:', error);
          return Promise.reject();
        }
      };
      // 8. GroupVideo
      const deleteGroupVideos = async (groupID, nextToken = null) => {
        try {
          const filter = {
            groupID: { eq: groupID },
          };
          const { items: groupVideos, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listGroupVideos', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            groupVideos.map(async (groupVideo) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteGroupVideo', input: { id: groupVideo.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteGroupVideos(groupID, returnNextToken);
          }
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete group videos error:', error);
          return Promise.reject();
        }
      };
      // 5. Group
      const deleteGroups = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeleteGroups' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: groups, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listGroups', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            groups.map(async (group) => {
              const { id: groupID } = group;
              await deleteChatGroups(groupID);
              await deleteGroupVideos(groupID);
              await dispatch(
                'api/mutate',
                { mutation: 'deleteGroup', input: { id: groupID } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteGroups(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeleteGroups' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete groups error:', error);
          commit('SET_ERROR', { key: 'processDeleteGroups' });
          return Promise.reject();
        }
      };
      // 7. JoinGroup
      const deleteJoinGroups = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeleteJoinGroups' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: joinGroups, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listJoinGroups', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            joinGroups.map(async (joinGroup) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteJoinGroup', input: { id: joinGroup.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteJoinGroups(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeleteJoinGroups' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete join groups error:', error);
          commit('SET_ERROR', { key: 'processDeleteJoinGroups' });
          return Promise.reject();
        }
      };
      // 11. VideoInfo
      // will be deleted with video
      // 12. Comment
      const deleteComments = async (videoID, nextToken = null) => {
        try {
          const filter = {
            videoID: { eq: videoID },
          };
          const { items: comments, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listComments', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            comments.map(async (cmt) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteComment', input: { id: cmt.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteComments(videoID, returnNextToken);
          }
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete comments error:', error);
          return Promise.reject();
        }
      };
      // 13. VideoAttachment
      const deleteVideoAttachments = async (videoID, nextToken = null) => {
        try {
          const filter = {
            videoID: { eq: videoID },
          };
          const { items: videoAttachments, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listVideoAttachments', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            videoAttachments.map(async (videoAttach) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteVideoAttachment', input: { id: videoAttach.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteVideoAttachments(videoID, returnNextToken);
          }
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete video attachments error:', error);
          return Promise.reject();
        }
      };
      // 14. GuestComment
      const deleteGuestComments = async (videoID, nextToken = null) => {
        try {
          const filter = {
            videoID: { eq: videoID },
          };
          const { items: guestComments, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listGuestComments', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            guestComments.map(async (guestCmt) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteGuestComment', input: { id: guestCmt.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteGuestComments(videoID, returnNextToken);
          }
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete guest comments error:', error);
          return Promise.reject();
        }
      };
      // 10. Video
      const deleteVideoAndInfos = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeleteVideoAndInfos' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: videos, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listVideos', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            videos.map(async (video) => {
              const { id: videoID } = video;
              await dispatch(
                'api/mutate',
                { mutation: 'deleteVideoInfo', input: { id: videoID } },
                { root: true } // eslint-disable-line
              );
              await deleteComments(videoID);
              await deleteVideoAttachments(videoID);
              await deleteGuestComments(videoID);
              await dispatch(
                'api/mutate',
                { mutation: 'deleteVideo', input: { id: videoID } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteVideoAndInfos(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeleteVideoAndInfos' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete videos and infos error:', error);
          commit('SET_ERROR', { key: 'processDeleteVideoAndInfos' });
          return Promise.reject();
        }
      };
      // 15. PublicSpace
      const deletePublicSpaces = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeletePublicSpaces' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: publicSpaces, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listPublicSpaces', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            publicSpaces.map(async (publisSpace) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deletePublicSpace', input: { id: publisSpace.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deletePublicSpaces(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeletePublicSpaces' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete public spaces error:', error);
          commit('SET_ERROR', { key: 'processDeletePublicSpaces' });
          return Promise.reject();
        }
      };
      // 16. PublicGroup
      const deletePublicGroups = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeletePublicGroups' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: publicGroups, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listPublicGroups', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            publicGroups.map(async (publicGroup) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deletePublicGroup', input: { id: publicGroup.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deletePublicGroups(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeletePublicGroups' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete public groups error:', error);
          commit('SET_ERROR', { key: 'processDeletePublicGroups' });
          return Promise.reject();
        }
      };
      // 17. SpaceInvitation
      const deleteSpaceInvitations = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeleteSpaceInvitations' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: spaceInvitations, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listSpaceInvitations', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            spaceInvitations.map(async (spaceInvitation) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteSpaceInvitation', input: { id: spaceInvitation.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteSpaceInvitations(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeleteSpaceInvitations' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete space invitations error:', error);
          commit('SET_ERROR', { key: 'processDeleteSpaceInvitations' });
          return Promise.reject();
        }
      };
      // 18. PayJPSubscription
      const deletePayJPSubscriptions = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeletePayJPSubscriptions' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: payJPSubscriptions, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listPayJPSubscriptions', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            payJPSubscriptions.map(async (payJPSubscription) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deletePayJPSubscription', input: { id: payJPSubscription.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deletePayJPSubscriptions(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeletePayJPSubscriptions' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete payjp subscriptions error:', error);
          commit('SET_ERROR', { key: 'processDeletePayJPSubscriptions' });
          return Promise.reject();
        }
      };
      // 19. PaymentHistory
      const deletePaymentHistories = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeletePaymentHistories' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: paymentHistories, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listPaymentHistorys', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            paymentHistories.map(async (paymentHistory) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deletePaymentHistory', input: { id: paymentHistory.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deletePaymentHistories(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeletePaymentHistories' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete payment histories error:', error);
          commit('SET_ERROR', { key: 'processDeletePaymentHistories' });
          return Promise.reject();
        }
      };
      // 20. JoinSpaceKey
      const deleteJoinSpaceKeys = async (nextToken = null) => {
        try {
          commit('SET_PROCESSING', { key: 'processDeleteJoinSpaceKeys' });
          const filter = {
            spaceID: { eq: spaceID },
          };
          const { items: joinKeys, nextToken: returnNextToken } = await dispatch(
            'api/query',
            { query: 'listJoinSpaceKeys', filter, getNextToken: true, nextToken },
            { root: true } // eslint-disable-line comma-dangle
          );
          await Promise.allSettled(
            joinKeys.map(async (joinKey) => {
              await dispatch(
                'api/mutate',
                { mutation: 'deleteJoinSpaceKey', input: { id: joinKey.id } },
                { root: true } // eslint-disable-line
              );
              return true;
            }) // eslint-disable-line comma-dangle
          );
          if (returnNextToken) {
            await deleteJoinSpaceKeys(returnNextToken);
          }
          commit('SET_SUCCESS', { key: 'processDeleteJoinSpaceKeys' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('Delete join space keys error:', error);
          commit('SET_ERROR', { key: 'processDeleteJoinSpaceKeys' });
          return Promise.reject();
        }
      };
      // 21. Space
      const deleteSpace = async () => {
        // await sleep(2000);
        // console.log('delete profiles done');
        try {
          commit('SET_PROCESSING', { key: 'processDeleteSpace' });
          await dispatch(
            'api/mutate',
            { mutation: 'deleteSpace', input: { id: spaceID } },
            { root: true } // eslint-disable-line comma-dangle
          );
          commit('SET_SUCCESS', { key: 'processDeleteSpace' });
          return Promise.resolve(true);
        } catch (error) {
          console.error('fail to delete space', error);
          commit('SET_ERROR', { key: 'processDeleteSpace' });
          return Promise.reject();
        }
      };

      commit('SET_PROCESSING', { key: 'allProcesses' });
      processes.push(
        deleteProfiles(),
        deleteChats(),
        deleteNotifications(),
        deleteGroups(),
        deleteJoinGroups(),
        deleteVideoAndInfos(),
        deletePublicSpaces(),
        deletePublicGroups(),
        deleteSpaceInvitations(),
        deletePayJPSubscriptions(),
        deletePaymentHistories(),
        deleteJoinSpaceKeys() // eslint-disable-line comma-dangle
      );
      await Promise.allSettled(processes);
      await deleteSpace();
      commit('SET_SUCCESS', { key: 'allProcesses' });
      console.log('All processes done!');
      return Promise.resolve(true);
    } catch (error) {
      console.error('fail delete space and relation', error);
      commit('SET_ERROR', { key: 'allProcesses' });
      return Promise.reject();
    }
  },

  async inactiveSpaceAndRelation({ dispatch }, space) {
    console.log(space);
    dispatch();
  },
};

export const getters = {
  processDeleteProfiles: (state) => state.processDeleteProfiles,
  processDeleteChats: (state) => state.processDeleteChats,
  processDeleteNotifications: (state) => state.processDeleteNotifications,
  processDeleteGroups: (state) => state.processDeleteGroups,
  processDeleteJoinGroups: (state) => state.processDeleteJoinGroups,
  processDeleteVideoAndInfos: (state) => state.processDeleteVideoAndInfos,
  processDeletePublicSpaces: (state) => state.processDeletePublicSpaces,
  processDeletePublicGroups: (state) => state.processDeletePublicGroups,
  processDeleteSpaceInvitations: (state) => state.processDeleteSpaceInvitations,
  processDeletePayJPSubscriptions: (state) => state.processDeletePayJPSubscriptions,
  processDeletePaymentHistories: (state) => state.processDeletePaymentHistories,
  processDeleteJoinSpaceKeys: (state) => state.processDeleteJoinSpaceKeys,
  processDeleteSpace: (state) => state.processDeleteSpace,
  allProcesses: (state) => state.allProcesses,
};
