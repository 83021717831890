export const state = () => ({
  videoLike: {},
});

export const mutations = {
  SET_LIKE(state, like) {
    state.videoLike = like;
  },
  REMOVE_LIKE(state) {
    state.videoLike = null;
  },
};

export const actions = {
  async setCurrentLike({ commit }, like) {
    commit('SET_LIKE', like);
  },

  async like({ commit, dispatch }, input) {
    try {
      const likeData = await dispatch(
        'api/mutate',
        { mutation: 'createLike', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_LIKE', likeData);
      return likeData;
    } catch (error) {
      console.error('like fail:', error);
      return Promise.reject();
    }
  },

  async unLike({ commit, dispatch }, id) {
    try {
      await dispatch(
        'api/mutate',
        { mutation: 'deleteLike', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('REMOVE_LIKE');
      return Promise.resolve();
    } catch (error) {
      console.error('unLike fail:', error);
      return Promise.reject();
    }
  },

  async listLike({ dispatch }, { filter, limit, nextToken }) {
    const likesData = await dispatch(
      'api/query',
      { query: 'listLikes', filter, limit, nextToken },
      { root: true } // eslint-disable-line comma-dangle
    );
    return likesData;
  },
  async listLikePublic({ dispatch }, { filter, limit, nextToken }) {
    const likesData = await dispatch(
      'api/queryPublic',
      { query: 'listLikes', filter, limit, nextToken },
      { root: true } // eslint-disable-line comma-dangle
    );
    return likesData;
  },
};

export const getters = {
  videoLike: (state) => state.videoLike,
};
