//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isMore: false,
    };
  },
  mounted() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', this.handleResize, true);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize, true);
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async handleLogout() {
      this.logout();
      this.$router.push('/admin/login');
    },
    toggleMore() {
      this.isMore = !this.isMore;
    },
    handleResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
};
