import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d9c3dbf6 = () => interopDefault(import('../pages/01-advantage-feedback-training1.vue' /* webpackChunkName: "pages/01-advantage-feedback-training1" */))
const _459aefa1 = () => interopDefault(import('../pages/02-market-price-feedback-training1.vue' /* webpackChunkName: "pages/02-market-price-feedback-training1" */))
const _41473656 = () => interopDefault(import('../pages/03-roleplaying-feedback-training1.vue' /* webpackChunkName: "pages/03-roleplaying-feedback-training1" */))
const _6ba16a58 = () => interopDefault(import('../pages/04-types-feedback-training1.vue' /* webpackChunkName: "pages/04-types-feedback-training1" */))
const _cdbaf9d6 = () => interopDefault(import('../pages/05-function-online-lecturer-tool1.vue' /* webpackChunkName: "pages/05-function-online-lecturer-tool1" */))
const _6ba55ce8 = () => interopDefault(import('../pages/06-preparation-online-lecturer1.vue' /* webpackChunkName: "pages/06-preparation-online-lecturer1" */))
const _219a7375 = () => interopDefault(import('../pages/07-summary-online-lecturer-tool1.vue' /* webpackChunkName: "pages/07-summary-online-lecturer-tool1" */))
const _0f116d86 = () => interopDefault(import('../pages/08-how-to-start-online-lecturer1.vue' /* webpackChunkName: "pages/08-how-to-start-online-lecturer1" */))
const _191a4bcc = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _05b44323 = () => interopDefault(import('../pages/column1.vue' /* webpackChunkName: "pages/column1" */))
const _732be4aa = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _0ca2401e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _17bc31be = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _5d8bb507 = () => interopDefault(import('../pages/operating_company.vue' /* webpackChunkName: "pages/operating_company" */))
const _05f3a25a = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _42ed4f11 = () => interopDefault(import('../pages/space/index.vue' /* webpackChunkName: "pages/space/index" */))
const _4bf609e2 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _5b142ee9 = () => interopDefault(import('../pages/transaction/index.vue' /* webpackChunkName: "pages/transaction/index" */))
const _616b3774 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _1303ae00 = () => interopDefault(import('../pages/vision_board_user_guide.vue' /* webpackChunkName: "pages/vision_board_user_guide" */))
const _403fda34 = () => interopDefault(import('../pages/account/confirm.vue' /* webpackChunkName: "pages/account/confirm" */))
const _4fd93246 = () => interopDefault(import('../pages/account/create.vue' /* webpackChunkName: "pages/account/create" */))
const _7f1011ca = () => interopDefault(import('../pages/account/detail.vue' /* webpackChunkName: "pages/account/detail" */))
const _41f56658 = () => interopDefault(import('../pages/account/edit.vue' /* webpackChunkName: "pages/account/edit" */))
const _3bf62fed = () => interopDefault(import('../pages/account/register.vue' /* webpackChunkName: "pages/account/register" */))
const _78aa46f4 = () => interopDefault(import('../pages/account/unregister.vue' /* webpackChunkName: "pages/account/unregister" */))
const _44558ec0 = () => interopDefault(import('../pages/account/username.vue' /* webpackChunkName: "pages/account/username" */))
const _7eabd97a = () => interopDefault(import('../pages/account/verify.vue' /* webpackChunkName: "pages/account/verify" */))
const _85cb059e = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "pages/admin/login" */))
const _722a049a = () => interopDefault(import('../pages/admin/notifications/index.vue' /* webpackChunkName: "pages/admin/notifications/index" */))
const _78b5d151 = () => interopDefault(import('../pages/admin/payment/index.vue' /* webpackChunkName: "pages/admin/payment/index" */))
const _541a7550 = () => interopDefault(import('../pages/admin/spaces/index.vue' /* webpackChunkName: "pages/admin/spaces/index" */))
const _bd76825a = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _50f54f4e = () => interopDefault(import('../pages/contact/confirm.vue' /* webpackChunkName: "pages/contact/confirm" */))
const _075634ac = () => interopDefault(import('../pages/password/change.vue' /* webpackChunkName: "pages/password/change" */))
const _18776fb7 = () => interopDefault(import('../pages/password/resend.vue' /* webpackChunkName: "pages/password/resend" */))
const _2a516d83 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _520c3b4a = () => interopDefault(import('../pages/printable/payment_history.vue' /* webpackChunkName: "pages/printable/payment_history" */))
const _4270ddcd = () => interopDefault(import('../pages/space/create.vue' /* webpackChunkName: "pages/space/create" */))
const _65c60d3b = () => interopDefault(import('../pages/space/join.vue' /* webpackChunkName: "pages/space/join" */))
const _59cb494a = () => interopDefault(import('../pages/space/logout.vue' /* webpackChunkName: "pages/space/logout" */))
const _315dd498 = () => interopDefault(import('../pages/space/register.vue' /* webpackChunkName: "pages/space/register" */))
const _184e1788 = () => interopDefault(import('../pages/space/search/index.vue' /* webpackChunkName: "pages/space/search/index" */))
const _07aeb47e = () => interopDefault(import('../pages/admin/notifications/new.vue' /* webpackChunkName: "pages/admin/notifications/new" */))
const _532fc8df = () => interopDefault(import('../pages/admin/payment/new.vue' /* webpackChunkName: "pages/admin/payment/new" */))
const _7d89ffba = () => interopDefault(import('../pages/admin/spaces/runUpdate.vue' /* webpackChunkName: "pages/admin/spaces/runUpdate" */))
const _7af24c5c = () => interopDefault(import('../pages/admin/users/autoConfirm.vue' /* webpackChunkName: "pages/admin/users/autoConfirm" */))
const _a04ff1c4 = () => interopDefault(import('../pages/admin/notifications/_id/index.vue' /* webpackChunkName: "pages/admin/notifications/_id/index" */))
const _12b3dbbc = () => interopDefault(import('../pages/admin/payment/_id/index.vue' /* webpackChunkName: "pages/admin/payment/_id/index" */))
const _3e8d177a = () => interopDefault(import('../pages/admin/spaces/_id/index.vue' /* webpackChunkName: "pages/admin/spaces/_id/index" */))
const _572c383e = () => interopDefault(import('../pages/admin/users/_id/index.vue' /* webpackChunkName: "pages/admin/users/_id/index" */))
const _2cd7c06e = () => interopDefault(import('../pages/admin/notifications/_id/edit.vue' /* webpackChunkName: "pages/admin/notifications/_id/edit" */))
const _0effe010 = () => interopDefault(import('../pages/admin/payment/_id/edit.vue' /* webpackChunkName: "pages/admin/payment/_id/edit" */))
const _002dffac = () => interopDefault(import('../pages/admin/spaces/_id/add_members.vue' /* webpackChunkName: "pages/admin/spaces/_id/add_members" */))
const _47319aa9 = () => interopDefault(import('../pages/admin/spaces/_id/edit.vue' /* webpackChunkName: "pages/admin/spaces/_id/edit" */))
const _07750844 = () => interopDefault(import('../pages/admin/spaces/_id/import_members.vue' /* webpackChunkName: "pages/admin/spaces/_id/import_members" */))
const _40ae2964 = () => interopDefault(import('../pages/admin/users/_id/edit.vue' /* webpackChunkName: "pages/admin/users/_id/edit" */))
const _a8f0e8b4 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _55130d08 = () => interopDefault(import('../pages/space/_id/index.vue' /* webpackChunkName: "pages/space/_id/index" */))
const _162bec31 = () => interopDefault(import('../pages/video/_id/index.vue' /* webpackChunkName: "pages/video/_id/index" */))
const _3998bfb7 = () => interopDefault(import('../pages/space/_id/block.vue' /* webpackChunkName: "pages/space/_id/block" */))
const _05c171f2 = () => interopDefault(import('../pages/space/_id/delete_confirm.vue' /* webpackChunkName: "pages/space/_id/delete_confirm" */))
const _3e928a50 = () => interopDefault(import('../pages/space/_id/edit.vue' /* webpackChunkName: "pages/space/_id/edit" */))
const _b022f334 = () => interopDefault(import('../pages/space/_id/issue_link.vue' /* webpackChunkName: "pages/space/_id/issue_link" */))
const _5f7feb60 = () => interopDefault(import('../pages/space/_id/member.vue' /* webpackChunkName: "pages/space/_id/member" */))
const _41848718 = () => interopDefault(import('../pages/space/_id/notification/index.vue' /* webpackChunkName: "pages/space/_id/notification/index" */))
const _272c5696 = () => interopDefault(import('../pages/space/_id/profile/index.vue' /* webpackChunkName: "pages/space/_id/profile/index" */))
const _4d95106c = () => interopDefault(import('../pages/space/_id/send_invite.vue' /* webpackChunkName: "pages/space/_id/send_invite" */))
const _3955f37a = () => interopDefault(import('../pages/space/_id/setting.vue' /* webpackChunkName: "pages/space/_id/setting" */))
const _57882d86 = () => interopDefault(import('../pages/space/_id/settingPlan/index.vue' /* webpackChunkName: "pages/space/_id/settingPlan/index" */))
const _55e3c130 = () => interopDefault(import('../pages/space/_id/video/index.vue' /* webpackChunkName: "pages/space/_id/video/index" */))
const _24946da3 = () => interopDefault(import('../pages/space/_id/video_tags.vue' /* webpackChunkName: "pages/space/_id/video_tags" */))
const _06290f0a = () => interopDefault(import('../pages/video/_id/mock.vue' /* webpackChunkName: "pages/video/_id/mock" */))
const _1b0f1396 = () => interopDefault(import('../pages/video/_id/shared.vue' /* webpackChunkName: "pages/video/_id/shared" */))
const _6a39628c = () => interopDefault(import('../pages/space/_id/group/new.vue' /* webpackChunkName: "pages/space/_id/group/new" */))
const _6a7fa1f6 = () => interopDefault(import('../pages/space/_id/profile/edit.vue' /* webpackChunkName: "pages/space/_id/profile/edit" */))
const _90090c9c = () => interopDefault(import('../pages/space/_id/settingPlan/cancel_billing.vue' /* webpackChunkName: "pages/space/_id/settingPlan/cancel_billing" */))
const _4c14af02 = () => interopDefault(import('../pages/space/_id/settingPlan/completed.vue' /* webpackChunkName: "pages/space/_id/settingPlan/completed" */))
const _4124cc9e = () => interopDefault(import('../pages/space/_id/settingPlan/confirmation.vue' /* webpackChunkName: "pages/space/_id/settingPlan/confirmation" */))
const _114e75ed = () => interopDefault(import('../pages/space/_id/settingPlan/custom.vue' /* webpackChunkName: "pages/space/_id/settingPlan/custom" */))
const _635819ee = () => interopDefault(import('../pages/space/_id/settingPlan/manual_payment.vue' /* webpackChunkName: "pages/space/_id/settingPlan/manual_payment" */))
const _02cd32a9 = () => interopDefault(import('../pages/space/_id/settingPlan/payments.vue' /* webpackChunkName: "pages/space/_id/settingPlan/payments" */))
const _cde7442a = () => interopDefault(import('../pages/space/_id/settingPlan/re_billing.vue' /* webpackChunkName: "pages/space/_id/settingPlan/re_billing" */))
const _0cab9b40 = () => interopDefault(import('../pages/space/_id/settingPlan/selection.vue' /* webpackChunkName: "pages/space/_id/settingPlan/selection" */))
const _ec080d90 = () => interopDefault(import('../pages/space/_id/video/tutorial.vue' /* webpackChunkName: "pages/space/_id/video/tutorial" */))
const _d75f0a4a = () => interopDefault(import('../pages/space/_id/video/upload.vue' /* webpackChunkName: "pages/space/_id/video/upload" */))
const _d4375d52 = () => interopDefault(import('../pages/space/_id/group/_id/index.vue' /* webpackChunkName: "pages/space/_id/group/_id/index" */))
const _5c04de31 = () => interopDefault(import('../pages/space/_id/issue/_id/index.vue' /* webpackChunkName: "pages/space/_id/issue/_id/index" */))
const _610d735a = () => interopDefault(import('../pages/space/_id/video/_id/index.vue' /* webpackChunkName: "pages/space/_id/video/_id/index" */))
const _6de5c1aa = () => interopDefault(import('../pages/space/_id/group/_id/archived_vision.vue' /* webpackChunkName: "pages/space/_id/group/_id/archived_vision" */))
const _820e2dc8 = () => interopDefault(import('../pages/space/_id/group/_id/detail.vue' /* webpackChunkName: "pages/space/_id/group/_id/detail" */))
const _4d2669d6 = () => interopDefault(import('../pages/space/_id/group/_id/edit.vue' /* webpackChunkName: "pages/space/_id/group/_id/edit" */))
const _6c736703 = () => interopDefault(import('../pages/space/_id/group/_id/video/index.vue' /* webpackChunkName: "pages/space/_id/group/_id/video/index" */))
const _4c30214c = () => interopDefault(import('../pages/space/_id/group/_id/vision_board.vue' /* webpackChunkName: "pages/space/_id/group/_id/vision_board" */))
const _48cc654c = () => interopDefault(import('../pages/space/_id/issue/_id/video.vue' /* webpackChunkName: "pages/space/_id/issue/_id/video" */))
const _57274699 = () => interopDefault(import('../pages/space/_id/video/_id/edit.vue' /* webpackChunkName: "pages/space/_id/video/_id/edit" */))
const _c5bfd6c4 = () => interopDefault(import('../pages/space/_id/coachRoom/_id/vision/create.vue' /* webpackChunkName: "pages/space/_id/coachRoom/_id/vision/create" */))
const _1d6b486e = () => interopDefault(import('../pages/space/_id/group/_id/video/_id/index.vue' /* webpackChunkName: "pages/space/_id/group/_id/video/_id/index" */))
const _ab615e3e = () => interopDefault(import('../pages/space/_id/coachRoom/_id/vision/_id/edit.vue' /* webpackChunkName: "pages/space/_id/coachRoom/_id/vision/_id/edit" */))
const _a1c2bcfc = () => interopDefault(import('../pages/space/_id/coachRoom/_id/vision/_id/goals/create.vue' /* webpackChunkName: "pages/space/_id/coachRoom/_id/vision/_id/goals/create" */))
const _36de34c5 = () => interopDefault(import('../pages/space/_id/coachRoom/_id/vision/_id/goals/_id/edit.vue' /* webpackChunkName: "pages/space/_id/coachRoom/_id/vision/_id/goals/_id/edit" */))
const _6bc3ab74 = () => interopDefault(import('../pages/space/_id/coachRoom/_id/vision/_id/goals/_id/issues/create.vue' /* webpackChunkName: "pages/space/_id/coachRoom/_id/vision/_id/goals/_id/issues/create" */))
const _dfc2c6ee = () => interopDefault(import('../pages/space/_id/coachRoom/_id/vision/_id/goals/_id/issues/_id/edit.vue' /* webpackChunkName: "pages/space/_id/coachRoom/_id/vision/_id/goals/_id/issues/_id/edit" */))
const _30073cda = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/01-advantage-feedback-training1",
    component: _d9c3dbf6,
    name: "01-advantage-feedback-training1"
  }, {
    path: "/02-market-price-feedback-training1",
    component: _459aefa1,
    name: "02-market-price-feedback-training1"
  }, {
    path: "/03-roleplaying-feedback-training1",
    component: _41473656,
    name: "03-roleplaying-feedback-training1"
  }, {
    path: "/04-types-feedback-training1",
    component: _6ba16a58,
    name: "04-types-feedback-training1"
  }, {
    path: "/05-function-online-lecturer-tool1",
    component: _cdbaf9d6,
    name: "05-function-online-lecturer-tool1"
  }, {
    path: "/06-preparation-online-lecturer1",
    component: _6ba55ce8,
    name: "06-preparation-online-lecturer1"
  }, {
    path: "/07-summary-online-lecturer-tool1",
    component: _219a7375,
    name: "07-summary-online-lecturer-tool1"
  }, {
    path: "/08-how-to-start-online-lecturer1",
    component: _0f116d86,
    name: "08-how-to-start-online-lecturer1"
  }, {
    path: "/admin",
    component: _191a4bcc,
    name: "admin"
  }, {
    path: "/column1",
    component: _05b44323,
    name: "column1"
  }, {
    path: "/contact",
    component: _732be4aa,
    name: "contact"
  }, {
    path: "/login",
    component: _0ca2401e,
    name: "login"
  }, {
    path: "/news",
    component: _17bc31be,
    name: "news"
  }, {
    path: "/operating_company",
    component: _5d8bb507,
    name: "operating_company"
  }, {
    path: "/policy",
    component: _05f3a25a,
    name: "policy"
  }, {
    path: "/space",
    component: _42ed4f11,
    name: "space"
  }, {
    path: "/terms",
    component: _4bf609e2,
    name: "terms"
  }, {
    path: "/transaction",
    component: _5b142ee9,
    name: "transaction"
  }, {
    path: "/video",
    component: _616b3774,
    name: "video"
  }, {
    path: "/vision_board_user_guide",
    component: _1303ae00,
    name: "vision_board_user_guide"
  }, {
    path: "/account/confirm",
    component: _403fda34,
    name: "account-confirm"
  }, {
    path: "/account/create",
    component: _4fd93246,
    name: "account-create"
  }, {
    path: "/account/detail",
    component: _7f1011ca,
    name: "account-detail"
  }, {
    path: "/account/edit",
    component: _41f56658,
    name: "account-edit"
  }, {
    path: "/account/register",
    component: _3bf62fed,
    name: "account-register"
  }, {
    path: "/account/unregister",
    component: _78aa46f4,
    name: "account-unregister"
  }, {
    path: "/account/username",
    component: _44558ec0,
    name: "account-username"
  }, {
    path: "/account/verify",
    component: _7eabd97a,
    name: "account-verify"
  }, {
    path: "/admin/login",
    component: _85cb059e,
    name: "admin-login"
  }, {
    path: "/admin/notifications",
    component: _722a049a,
    name: "admin-notifications"
  }, {
    path: "/admin/payment",
    component: _78b5d151,
    name: "admin-payment"
  }, {
    path: "/admin/spaces",
    component: _541a7550,
    name: "admin-spaces"
  }, {
    path: "/admin/users",
    component: _bd76825a,
    name: "admin-users"
  }, {
    path: "/contact/confirm",
    component: _50f54f4e,
    name: "contact-confirm"
  }, {
    path: "/password/change",
    component: _075634ac,
    name: "password-change"
  }, {
    path: "/password/resend",
    component: _18776fb7,
    name: "password-resend"
  }, {
    path: "/password/reset",
    component: _2a516d83,
    name: "password-reset"
  }, {
    path: "/printable/payment_history",
    component: _520c3b4a,
    name: "printable-payment_history"
  }, {
    path: "/space/create",
    component: _4270ddcd,
    name: "space-create"
  }, {
    path: "/space/join",
    component: _65c60d3b,
    name: "space-join"
  }, {
    path: "/space/logout",
    component: _59cb494a,
    name: "space-logout"
  }, {
    path: "/space/register",
    component: _315dd498,
    name: "space-register"
  }, {
    path: "/space/search",
    component: _184e1788,
    name: "space-search"
  }, {
    path: "/admin/notifications/new",
    component: _07aeb47e,
    name: "admin-notifications-new"
  }, {
    path: "/admin/payment/new",
    component: _532fc8df,
    name: "admin-payment-new"
  }, {
    path: "/admin/spaces/runUpdate",
    component: _7d89ffba,
    name: "admin-spaces-runUpdate"
  }, {
    path: "/admin/users/autoConfirm",
    component: _7af24c5c,
    name: "admin-users-autoConfirm"
  }, {
    path: "/admin/notifications/:id",
    component: _a04ff1c4,
    name: "admin-notifications-id"
  }, {
    path: "/admin/payment/:id",
    component: _12b3dbbc,
    name: "admin-payment-id"
  }, {
    path: "/admin/spaces/:id",
    component: _3e8d177a,
    name: "admin-spaces-id"
  }, {
    path: "/admin/users/:id",
    component: _572c383e,
    name: "admin-users-id"
  }, {
    path: "/admin/notifications/:id/edit",
    component: _2cd7c06e,
    name: "admin-notifications-id-edit"
  }, {
    path: "/admin/payment/:id/edit",
    component: _0effe010,
    name: "admin-payment-id-edit"
  }, {
    path: "/admin/spaces/:id/add_members",
    component: _002dffac,
    name: "admin-spaces-id-add_members"
  }, {
    path: "/admin/spaces/:id/edit",
    component: _47319aa9,
    name: "admin-spaces-id-edit"
  }, {
    path: "/admin/spaces/:id/import_members",
    component: _07750844,
    name: "admin-spaces-id-import_members"
  }, {
    path: "/admin/users/:id/edit",
    component: _40ae2964,
    name: "admin-users-id-edit"
  }, {
    path: "/news/:id",
    component: _a8f0e8b4,
    name: "news-id"
  }, {
    path: "/space/:id",
    component: _55130d08,
    name: "space-id"
  }, {
    path: "/video/:id",
    component: _162bec31,
    name: "video-id"
  }, {
    path: "/space/:id/block",
    component: _3998bfb7,
    name: "space-id-block"
  }, {
    path: "/space/:id/delete_confirm",
    component: _05c171f2,
    name: "space-id-delete_confirm"
  }, {
    path: "/space/:id/edit",
    component: _3e928a50,
    name: "space-id-edit"
  }, {
    path: "/space/:id/issue_link",
    component: _b022f334,
    name: "space-id-issue_link"
  }, {
    path: "/space/:id/member",
    component: _5f7feb60,
    name: "space-id-member"
  }, {
    path: "/space/:id/notification",
    component: _41848718,
    name: "space-id-notification"
  }, {
    path: "/space/:id/profile",
    component: _272c5696,
    name: "space-id-profile"
  }, {
    path: "/space/:id/send_invite",
    component: _4d95106c,
    name: "space-id-send_invite"
  }, {
    path: "/space/:id/setting",
    component: _3955f37a,
    name: "space-id-setting"
  }, {
    path: "/space/:id/settingPlan",
    component: _57882d86,
    name: "space-id-settingPlan"
  }, {
    path: "/space/:id/video",
    component: _55e3c130,
    name: "space-id-video"
  }, {
    path: "/space/:id/video_tags",
    component: _24946da3,
    name: "space-id-video_tags"
  }, {
    path: "/video/:id/mock",
    component: _06290f0a,
    name: "video-id-mock"
  }, {
    path: "/video/:id/shared",
    component: _1b0f1396,
    name: "video-id-shared"
  }, {
    path: "/space/:id/group/new",
    component: _6a39628c,
    name: "space-id-group-new"
  }, {
    path: "/space/:id/profile/edit",
    component: _6a7fa1f6,
    name: "space-id-profile-edit"
  }, {
    path: "/space/:id/settingPlan/cancel_billing",
    component: _90090c9c,
    name: "space-id-settingPlan-cancel_billing"
  }, {
    path: "/space/:id/settingPlan/completed",
    component: _4c14af02,
    name: "space-id-settingPlan-completed"
  }, {
    path: "/space/:id/settingPlan/confirmation",
    component: _4124cc9e,
    name: "space-id-settingPlan-confirmation"
  }, {
    path: "/space/:id/settingPlan/custom",
    component: _114e75ed,
    name: "space-id-settingPlan-custom"
  }, {
    path: "/space/:id/settingPlan/manual_payment",
    component: _635819ee,
    name: "space-id-settingPlan-manual_payment"
  }, {
    path: "/space/:id/settingPlan/payments",
    component: _02cd32a9,
    name: "space-id-settingPlan-payments"
  }, {
    path: "/space/:id/settingPlan/re_billing",
    component: _cde7442a,
    name: "space-id-settingPlan-re_billing"
  }, {
    path: "/space/:id/settingPlan/selection",
    component: _0cab9b40,
    name: "space-id-settingPlan-selection"
  }, {
    path: "/space/:id/video/tutorial",
    component: _ec080d90,
    name: "space-id-video-tutorial"
  }, {
    path: "/space/:id/video/upload",
    component: _d75f0a4a,
    name: "space-id-video-upload"
  }, {
    path: "/space/:id/group/:id",
    component: _d4375d52,
    name: "space-id-group-id"
  }, {
    path: "/space/:id/issue/:id",
    component: _5c04de31,
    name: "space-id-issue-id"
  }, {
    path: "/space/:id/video/:id",
    component: _610d735a,
    name: "space-id-video-id"
  }, {
    path: "/space/:id/group/:id?/archived_vision",
    component: _6de5c1aa,
    name: "space-id-group-id-archived_vision"
  }, {
    path: "/space/:id/group/:id?/detail",
    component: _820e2dc8,
    name: "space-id-group-id-detail"
  }, {
    path: "/space/:id/group/:id?/edit",
    component: _4d2669d6,
    name: "space-id-group-id-edit"
  }, {
    path: "/space/:id/group/:id?/video",
    component: _6c736703,
    name: "space-id-group-id-video"
  }, {
    path: "/space/:id/group/:id?/vision_board",
    component: _4c30214c,
    name: "space-id-group-id-vision_board"
  }, {
    path: "/space/:id/issue/:id?/video",
    component: _48cc654c,
    name: "space-id-issue-id-video"
  }, {
    path: "/space/:id/video/:id/edit",
    component: _57274699,
    name: "space-id-video-id-edit"
  }, {
    path: "/space/:id/coachRoom/:id?/vision/create",
    component: _c5bfd6c4,
    name: "space-id-coachRoom-id-vision-create"
  }, {
    path: "/space/:id/group/:id?/video/:id",
    component: _1d6b486e,
    name: "space-id-group-id-video-id"
  }, {
    path: "/space/:id/coachRoom/:id?/vision/:id?/edit",
    component: _ab615e3e,
    name: "space-id-coachRoom-id-vision-id-edit"
  }, {
    path: "/space/:id/coachRoom/:id?/vision/:id?/goals/create",
    component: _a1c2bcfc,
    name: "space-id-coachRoom-id-vision-id-goals-create"
  }, {
    path: "/space/:id/coachRoom/:id?/vision/:id?/goals/:id?/edit",
    component: _36de34c5,
    name: "space-id-coachRoom-id-vision-id-goals-id-edit"
  }, {
    path: "/space/:id/coachRoom/:id?/vision/:id?/goals/:id?/issues/create",
    component: _6bc3ab74,
    name: "space-id-coachRoom-id-vision-id-goals-id-issues-create"
  }, {
    path: "/space/:id/coachRoom/:id?/vision/:id?/goals/:id?/issues/:id?/edit",
    component: _dfc2c6ee,
    name: "space-id-coachRoom-id-vision-id-goals-id-issues-id-edit"
  }, {
    path: "/",
    component: _30073cda,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
