export default async ({ store, route, redirect }) => {
  const groupID = route.path.split('/')[4];
  const spaceId = route.path.split('/')[2];
  const {
    currentUser: { id: userID },
  } = store.state.auth;
  const { currentSpace } = store.state.space;
  const spaceEditors = currentSpace.editors || [];
  const currentGroup = await store.dispatch('group/getGroup', groupID);
  if (currentGroup.status === 'inactive') {
    return redirect(`/space/${spaceId}/video`);
  }
  if (currentGroup.userID !== userID) {
    const joinGroupUser = currentGroup.joinedMembers.items;
    const joinGroupUserID = joinGroupUser.map((join) => join.userID);
    if (
      !joinGroupUserID.includes(userID) ||
      (currentSpace.userID !== userID && !spaceEditors.includes(userID))
    ) {
      return redirect(`/space/${spaceId}/video`);
    }
  }
};
