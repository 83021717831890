import Vue from 'vue';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja';
// Using all rule
// import * as rules from 'vee-validate/dist/rules';

// loop over all rules
// for (const rule in rules) {
//   extend(rule, {
//     ...rules[rule], // add the rule
//     message: en.messages[rule], // add its message
//   });
// }

// Using what u needs
import {
  required,
  min,
  min_value as minValue,
  max,
  max_value as maxValue,
  email,
  numeric,
  regex,
  confirmed,
} from 'vee-validate/dist/rules';

extend('required', required);
extend('email', email);
extend('max', max);
extend('min', min);
extend('min_value', minValue);
extend('max_value', maxValue);
extend('numeric', numeric);
extend('regex', regex);
extend('confirmed', confirmed);

extend('duplicatedEmail', (value) => {
  // TODO:
  // Request api to check existed email
  if (value === 'test@test.com') return true;
  return '登録済みメールアドレスです';
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
localize('ja', ja);
