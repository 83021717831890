/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      birthday
      gender
      address
      phoneNumber
      career
      avatar {
        bucket
        region
        key
      }
      description
      email
      receiveNotify
      receiveEmail
      receiveEmailCycle
      isDisabled
      isDeleted
      isUnregistered
      isReadyDelete
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedGroups {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      memo
      createdAt
      updatedAt
      spaces {
        items {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
        nextToken
      }
      fcmToken
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      birthday
      gender
      address
      phoneNumber
      career
      avatar {
        bucket
        region
        key
      }
      description
      email
      receiveNotify
      receiveEmail
      receiveEmailCycle
      isDisabled
      isDeleted
      isUnregistered
      isReadyDelete
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedGroups {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      memo
      createdAt
      updatedAt
      spaces {
        items {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
        nextToken
      }
      fcmToken
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      birthday
      gender
      address
      phoneNumber
      career
      avatar {
        bucket
        region
        key
      }
      description
      email
      receiveNotify
      receiveEmail
      receiveEmailCycle
      isDisabled
      isDeleted
      isUnregistered
      isReadyDelete
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedGroups {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      memo
      createdAt
      updatedAt
      spaces {
        items {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace($input: CreateSpaceInput!, $condition: ModelSpaceConditionInput) {
    createSpace(input: $input, condition: $condition) {
      id
      isListMemberPublic
      name
      description
      avatar {
        bucket
        region
        key
      }
      inviteCode
      userID
      editors
      members
      isActiveCoachRoom
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          items {
            id
          }
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      public {
        items {
          id
          name
          description
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      status
      planID
      planName
      maxMember
      currentUpload
      currentStorage
      monthlyUpload
      maxStorage
      paymentFee
      paymentCycle
      paymentMethod
      dayLimitation
      memo
      createdAt
      updatedAt
      videos {
        items {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
          user {
            joinedGroups {
              items {
                id
              }
              nextToken
            }
          }
        }
        nextToken
      }
      joinSpaceKey {
        items {
          id
          spaceID
          userName
          spaceName
          content
          avatarKey
          requestRole
          keyAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      spaceInvitation {
        items {
          id
          spaceID
          userID
          email
          role
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace($input: UpdateSpaceInput!, $condition: ModelSpaceConditionInput) {
    updateSpace(input: $input, condition: $condition) {
      id
      isListMemberPublic
      name
      description
      avatar {
        bucket
        region
        key
      }
      inviteCode
      userID
      editors
      members
      isActiveCoachRoom
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          items {
            id
          }
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      public {
        items {
          id
          name
          description
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      status
      planID
      planName
      maxMember
      currentUpload
      currentStorage
      monthlyUpload
      maxStorage
      paymentFee
      paymentCycle
      paymentMethod
      dayLimitation
      memo
      createdAt
      updatedAt
      videos {
        items {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
          user {
            joinedGroups {
              items {
                id
              }
              nextToken
            }
          }
        }
        nextToken
      }
      joinSpaceKey(limit: 100000) {
        items {
          id
          spaceID
          userName
          spaceName
          content
          avatarKey
          requestRole
          keyAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      spaceInvitation {
        items {
          id
          spaceID
          userID
          email
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      inviteCode
      userID
      editors
      members
      isActiveCoachRoom
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      public {
        items {
          id
          name
          description
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      status
      planID
      planName
      maxMember
      currentUpload
      currentStorage
      monthlyUpload
      maxStorage
      paymentFee
      paymentCycle
      paymentMethod
      memo
      createdAt
      updatedAt
      videos {
        items {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
        nextToken
      }
      joinSpaceKey {
        items {
          id
          spaceID
          userName
          spaceName
          content
          avatarKey
          requestRole
          keyAccess
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPublicSpace = /* GraphQL */ `
  mutation CreatePublicSpace(
    $input: CreatePublicSpaceInput!
    $condition: ModelPublicSpaceConditionInput
  ) {
    createPublicSpace(input: $input, condition: $condition) {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      spaceID
      owner
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const updatePublicSpace = /* GraphQL */ `
  mutation UpdatePublicSpace(
    $input: UpdatePublicSpaceInput!
    $condition: ModelPublicSpaceConditionInput
  ) {
    updatePublicSpace(input: $input, condition: $condition) {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      spaceID
      owner
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const deletePublicSpace = /* GraphQL */ `
  mutation DeletePublicSpace(
    $input: DeletePublicSpaceInput!
    $condition: ModelPublicSpaceConditionInput
  ) {
    deletePublicSpace(input: $input, condition: $condition) {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      spaceID
      owner
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput!, $condition: ModelGroupConditionInput) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      public {
        items {
          id
          name
          description
          groupID
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedMembers {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      isCoachGroup
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($input: UpdateGroupInput!, $condition: ModelGroupConditionInput) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      public {
        items {
          id
          name
          description
          groupID
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedMembers {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      isCoachGroup
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      public {
        items {
          id
          name
          description
          groupID
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedMembers {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const createPublicGroup = /* GraphQL */ `
  mutation CreatePublicGroup(
    $input: CreatePublicGroupInput!
    $condition: ModelPublicGroupConditionInput
  ) {
    createPublicGroup(input: $input, condition: $condition) {
      id
      name
      description
      groupID
      spaceID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePublicGroup = /* GraphQL */ `
  mutation UpdatePublicGroup(
    $input: UpdatePublicGroupInput!
    $condition: ModelPublicGroupConditionInput
  ) {
    updatePublicGroup(input: $input, condition: $condition) {
      id
      name
      description
      groupID
      spaceID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePublicGroup = /* GraphQL */ `
  mutation DeletePublicGroup(
    $input: DeletePublicGroupInput!
    $condition: ModelPublicGroupConditionInput
  ) {
    deletePublicGroup(input: $input, condition: $condition) {
      id
      name
      description
      groupID
      spaceID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createJoinGroup = /* GraphQL */ `
  mutation CreateJoinGroup(
    $input: CreateJoinGroupInput!
    $condition: ModelJoinGroupConditionInput
  ) {
    createJoinGroup(input: $input, condition: $condition) {
      id
      groupID
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJoinGroup = /* GraphQL */ `
  mutation UpdateJoinGroup(
    $input: UpdateJoinGroupInput!
    $condition: ModelJoinGroupConditionInput
  ) {
    updateJoinGroup(input: $input, condition: $condition) {
      id
      groupID
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteJoinGroup = /* GraphQL */ `
  mutation DeleteJoinGroup(
    $input: DeleteJoinGroupInput!
    $condition: ModelJoinGroupConditionInput
  ) {
    deleteJoinGroup(input: $input, condition: $condition) {
      id
      groupID
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      targetID
      targetType
      profileID
      emoji
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      targetID
      targetType
      profileID
      emoji
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      targetID
      targetType
      profileID
      emoji
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVideoInfo = /* GraphQL */ `
  mutation CreateVideoInfo(
    $input: CreateVideoInfoInput!
    $condition: ModelVideoInfoConditionInput
  ) {
    createVideoInfo(input: $input, condition: $condition) {
      id
      watched
      createdAt
      updatedAt
    }
  }
`;
export const updateVideoInfo = /* GraphQL */ `
  mutation UpdateVideoInfo(
    $input: UpdateVideoInfoInput!
    $condition: ModelVideoInfoConditionInput
  ) {
    updateVideoInfo(input: $input, condition: $condition) {
      id
      watched
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideoInfo = /* GraphQL */ `
  mutation DeleteVideoInfo(
    $input: DeleteVideoInfoInput!
    $condition: ModelVideoInfoConditionInput
  ) {
    deleteVideoInfo(input: $input, condition: $condition) {
      id
      watched
      createdAt
      updatedAt
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo($input: CreateVideoInput!, $condition: ModelVideoConditionInput) {
    createVideo(input: $input, condition: $condition) {
      id
      title
      description
      status
      thumbnail {
        bucket
        region
        key
      }
      content {
        bucket
        region
        key
      }
      profileID
      spaceID
      inviteCode
      publicToSpace
      duration
      infoID
      publicToGroup {
        items {
          id
          groupID
          videoID
          createdAt
          updatedAt
        }
        nextToken
      }
      isShareAble
      createdAt
      updatedAt
      type
      videoUrl
      tags
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      info {
        id
        watched
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo($input: UpdateVideoInput!, $condition: ModelVideoConditionInput) {
    updateVideo(input: $input, condition: $condition) {
      id
      title
      description
      status
      thumbnail {
        bucket
        region
        key
      }
      content {
        bucket
        region
        key
      }
      capacity
      profileID
      spaceID
      inviteCode
      publicToSpace
      duration
      infoID
      publicToGroup {
        items {
          id
          groupID
          videoID
          createdAt
          updatedAt
        }
        nextToken
      }
      isShareAble
      createdAt
      updatedAt
      type
      videoUrl
      tags
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      info {
        id
        watched
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      title
      description
      status
      thumbnail {
        bucket
        region
        key
      }
      content {
        bucket
        region
        key
      }
      profileID
      spaceID
      inviteCode
      publicToSpace
      duration
      infoID
      publicToGroup {
        items {
          id
          groupID
          videoID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      type
      videoUrl
      tags
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      info {
        id
        watched
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGroupVideo = /* GraphQL */ `
  mutation CreateGroupVideo(
    $input: CreateGroupVideoInput!
    $condition: ModelGroupVideoConditionInput
  ) {
    createGroupVideo(input: $input, condition: $condition) {
      id
      groupID
      videoID
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateGroupVideo = /* GraphQL */ `
  mutation UpdateGroupVideo(
    $input: UpdateGroupVideoInput!
    $condition: ModelGroupVideoConditionInput
  ) {
    updateGroupVideo(input: $input, condition: $condition) {
      id
      groupID
      videoID
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteGroupVideo = /* GraphQL */ `
  mutation DeleteGroupVideo(
    $input: DeleteGroupVideoInput!
    $condition: ModelGroupVideoConditionInput
  ) {
    deleteGroupVideo(input: $input, condition: $condition) {
      id
      groupID
      videoID
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CreateCommentInput!, $condition: ModelCommentConditionInput) {
    createComment(input: $input, condition: $condition) {
      id
      content
      commentAt
      attachments {
        bucket
        region
        key
      }
      profileID
      videoID
      groupID
      parentID
      type
      spaceID
      editors
      mentions
      mentionContent
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment($input: UpdateCommentInput!, $condition: ModelCommentConditionInput) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      commentAt
      attachments {
        bucket
        region
        key
      }
      profileID
      videoID
      groupID
      parentID
      type
      spaceID
      editors
      mentions
      mentionContent
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat($input: CreateChatInput!, $condition: ModelChatConditionInput) {
    createChat(input: $input, condition: $condition) {
      id
      content
      title
      profileID
      spaceID
      files {
        bucket
        region
        key
      }
      parentID
      type
      mentions
      mentionContent
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              title
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      child(limit: 100000) {
        items {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
          likes {
            items {
              id
              targetID
              targetType
              profileID
              emoji
              createdAt
              updatedAt
            }
            nextToken
          }
          profile {
            id
            name
            career
            description
            userID
            spaceID
            confirmed
            status
            role
            reason
            avatar {
              bucket
              region
              key
            }
            notifySetting {
              items {
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
                visionBoardSetting
              }
            }
            groupNotifySetting {
              items {
                groupID
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
              }
            }
            user {
              receiveNotify
              receiveEmail
              email
              isDisabled
              isDeleted
              isUnregistered
              isReadyDelete
              fcmToken
            }
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat($input: UpdateChatInput!, $condition: ModelChatConditionInput) {
    updateChat(input: $input, condition: $condition) {
      id
      content
      title
      profileID
      spaceID
      files {
        bucket
        region
        key
      }
      parentID
      type
      mentions
      mentionContent
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              title
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      child(limit: 100000) {
        items {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
          likes {
            items {
              id
              targetID
              targetType
              profileID
              emoji
              createdAt
              updatedAt
            }
            nextToken
          }
          profile {
            id
            name
            career
            description
            userID
            spaceID
            confirmed
            status
            role
            reason
            avatar {
              bucket
              region
              key
            }
            notifySetting {
              items {
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
                visionBoardSetting
              }
            }
            groupNotifySetting {
              items {
                groupID
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
              }
            }
            user {
              receiveNotify
              receiveEmail
              email
              isDisabled
              isDeleted
              isUnregistered
              isReadyDelete
              fcmToken
            }
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      content
      profileID
      spaceID
      files {
        bucket
        region
        key
      }
      parentID
      mentions
      createdAt
      updatedAt
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createChatGroup = /* GraphQL */ `
  mutation CreateChatGroup(
    $input: CreateChatGroupInput!
    $condition: ModelChatGroupConditionInput
  ) {
    createChatGroup(input: $input, condition: $condition) {
      id
      content
      title
      profileID
      groupID
      files {
        bucket
        region
        key
      }
      parentID
      type
      mentions
      mentionContent
      createdAt
      updatedAt
      visionActionPerformer
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              title
              vision {
                id
                status
                coachRoomID
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      child(limit: 100000) {
        items {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
          likes {
            items {
              id
              targetID
              targetType
              profileID
              emoji
              createdAt
              updatedAt
            }
            nextToken
          }
          profile {
            id
            name
            career
            description
            userID
            spaceID
            confirmed
            status
            role
            reason
            avatar {
              bucket
              region
              key
            }
            notifySetting {
              items {
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
                visionBoardSetting
              }
            }
            groupNotifySetting {
              items {
                groupID
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
              }
            }
            user {
              receiveNotify
              receiveEmail
              email
              isDisabled
              isDeleted
              isUnregistered
              isReadyDelete
              fcmToken
            }
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateChatGroup = /* GraphQL */ `
  mutation UpdateChatGroup(
    $input: UpdateChatGroupInput!
    $condition: ModelChatGroupConditionInput
  ) {
    updateChatGroup(input: $input, condition: $condition) {
      id
      content
      title
      profileID
      groupID
      files {
        bucket
        region
        key
      }
      parentID
      type
      mentions
      mentionContent
      createdAt
      updatedAt
      visionActionPerformer
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              title
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      child(limit: 100000) {
        items {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
          likes {
            items {
              id
              targetID
              targetType
              profileID
              emoji
              createdAt
              updatedAt
            }
            nextToken
          }
          profile {
            id
            name
            career
            description
            userID
            spaceID
            confirmed
            status
            role
            reason
            avatar {
              bucket
              region
              key
            }
            notifySetting {
              items {
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
                visionBoardSetting
              }
            }
            groupNotifySetting {
              items {
                groupID
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
              }
            }
            user {
              receiveNotify
              receiveEmail
              email
              isDisabled
              isDeleted
              isUnregistered
              isReadyDelete
              fcmToken
            }
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteChatGroup = /* GraphQL */ `
  mutation DeleteChatGroup(
    $input: DeleteChatGroupInput!
    $condition: ModelChatGroupConditionInput
  ) {
    deleteChatGroup(input: $input, condition: $condition) {
      id
      content
      profileID
      groupID
      files {
        bucket
        region
        key
      }
      parentID
      mentions
      createdAt
      updatedAt
      visionActionPerformer
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile($input: CreateProfileInput!, $condition: ModelProfileConditionInput) {
    createProfile(input: $input, condition: $condition) {
      id
      isSpaceCoach
      name
      career
      avatar {
        bucket
        region
        key
      }
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      notifySetting {
        items {
          id
          profileID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          visionBoardSetting
          createdAt
          updatedAt
        }
        nextToken
      }
      confirmed
      status
      role
      reason
      groupNotifySetting {
        items {
          id
          profileID
          groupID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      space {
        id
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile($input: UpdateProfileInput!, $condition: ModelProfileConditionInput) {
    updateProfile(input: $input, condition: $condition) {
      id
      isSpaceCoach
      name
      career
      avatar {
        bucket
        region
        key
      }
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      notifySetting {
        items {
          id
          profileID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          visionBoardSetting
          createdAt
          updatedAt
        }
        nextToken
      }
      confirmed
      status
      role
      reason
      groupNotifySetting {
        items {
          id
          profileID
          groupID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          createdAt
          updatedAt
        }
        nextToken
      }
      isEditOnce
      createdAt
      updatedAt
      space {
        id
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      filterVisionMessage {
        myAction
        someOneElseAction
      }
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile($input: DeleteProfileInput!, $condition: ModelProfileConditionInput) {
    deleteProfile(input: $input, condition: $condition) {
      id
      name
      career
      avatar {
        bucket
        region
        key
      }
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      notifySetting {
        items {
          id
          profileID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          visionBoardSetting
          createdAt
          updatedAt
        }
        nextToken
      }
      confirmed
      status
      role
      reason
      groupNotifySetting {
        items {
          id
          profileID
          groupID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const createJoinSpaceKey = /* GraphQL */ `
  mutation CreateJoinSpaceKey(
    $input: CreateJoinSpaceKeyInput!
    $condition: ModelJoinSpaceKeyConditionInput
  ) {
    createJoinSpaceKey(input: $input, condition: $condition) {
      id
      spaceID
      userName
      spaceName
      content
      avatarKey
      requestRole
      keyAccess
      createdAt
      updatedAt
      emailInvite
    }
  }
`;
export const updateJoinSpaceKey = /* GraphQL */ `
  mutation UpdateJoinSpaceKey(
    $input: UpdateJoinSpaceKeyInput!
    $condition: ModelJoinSpaceKeyConditionInput
  ) {
    updateJoinSpaceKey(input: $input, condition: $condition) {
      id
      spaceID
      userName
      spaceName
      content
      avatarKey
      requestRole
      keyAccess
      createdAt
      updatedAt
      emailInvite
    }
  }
`;
export const deleteJoinSpaceKey = /* GraphQL */ `
  mutation DeleteJoinSpaceKey(
    $input: DeleteJoinSpaceKeyInput!
    $condition: ModelJoinSpaceKeyConditionInput
  ) {
    deleteJoinSpaceKey(input: $input, condition: $condition) {
      id
      spaceID
      userName
      spaceName
      content
      avatarKey
      requestRole
      keyAccess
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      spaceID
      groupID
      videoID
      messageID
      visionID
      goalID
      issueID
      profileID
      receiverID
      type
      status
      statusBadge
      fromGroups
      userID
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      spaceID
      groupID
      videoID
      messageID
      visionID
      goalID
      issueID
      profileID
      receiverID
      type
      status
      statusBadge
      fromGroups
      userID
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      spaceID
      groupID
      videoID
      messageID
      visionID
      goalID
      issueID
      profileID
      receiverID
      type
      status
      statusBadge
      fromGroups
      userID
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createSpaceNotificationSetting = /* GraphQL */ `
  mutation CreateSpaceNotificationSetting(
    $input: CreateSpaceNotificationSettingInput!
    $condition: ModelSpaceNotificationSettingConditionInput
  ) {
    createSpaceNotificationSetting(input: $input, condition: $condition) {
      id
      profileID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      visionBoardSetting
      createdAt
      updatedAt
    }
  }
`;
export const updateSpaceNotificationSetting = /* GraphQL */ `
  mutation UpdateSpaceNotificationSetting(
    $input: UpdateSpaceNotificationSettingInput!
    $condition: ModelSpaceNotificationSettingConditionInput
  ) {
    updateSpaceNotificationSetting(input: $input, condition: $condition) {
      id
      profileID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      visionBoardSetting
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpaceNotificationSetting = /* GraphQL */ `
  mutation DeleteSpaceNotificationSetting(
    $input: DeleteSpaceNotificationSettingInput!
    $condition: ModelSpaceNotificationSettingConditionInput
  ) {
    deleteSpaceNotificationSetting(input: $input, condition: $condition) {
      id
      profileID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      visionBoardSetting
      createdAt
      updatedAt
    }
  }
`;
export const createGroupNotificationSetting = /* GraphQL */ `
  mutation CreateGroupNotificationSetting(
    $input: CreateGroupNotificationSettingInput!
    $condition: ModelGroupNotificationSettingConditionInput
  ) {
    createGroupNotificationSetting(input: $input, condition: $condition) {
      id
      profileID
      groupID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupNotificationSetting = /* GraphQL */ `
  mutation UpdateGroupNotificationSetting(
    $input: UpdateGroupNotificationSettingInput!
    $condition: ModelGroupNotificationSettingConditionInput
  ) {
    updateGroupNotificationSetting(input: $input, condition: $condition) {
      id
      profileID
      groupID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupNotificationSetting = /* GraphQL */ `
  mutation DeleteGroupNotificationSetting(
    $input: DeleteGroupNotificationSettingInput!
    $condition: ModelGroupNotificationSettingConditionInput
  ) {
    deleteGroupNotificationSetting(input: $input, condition: $condition) {
      id
      profileID
      groupID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      createdAt
      updatedAt
    }
  }
`;
export const createPlanMaster = /* GraphQL */ `
  mutation CreatePlanMaster(
    $input: CreatePlanMasterInput!
    $condition: ModelPlanMasterConditionInput
  ) {
    createPlanMaster(input: $input, condition: $condition) {
      id
      name
      description
      maxMember
      monthlyUpload
      maxStorage
      monthlyFee
      yearlyFee
      createdAt
      updatedAt
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const updatePlanMaster = /* GraphQL */ `
  mutation UpdatePlanMaster(
    $input: UpdatePlanMasterInput!
    $condition: ModelPlanMasterConditionInput
  ) {
    updatePlanMaster(input: $input, condition: $condition) {
      id
      name
      description
      maxMember
      monthlyUpload
      maxStorage
      monthlyFee
      yearlyFee
      createdAt
      updatedAt
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const deletePlanMaster = /* GraphQL */ `
  mutation DeletePlanMaster(
    $input: DeletePlanMasterInput!
    $condition: ModelPlanMasterConditionInput
  ) {
    deletePlanMaster(input: $input, condition: $condition) {
      id
      name
      description
      maxMember
      monthlyUpload
      maxStorage
      monthlyFee
      yearlyFee
      createdAt
      updatedAt
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const createPaymentHistory = /* GraphQL */ `
  mutation CreatePaymentHistory(
    $input: CreatePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    createPaymentHistory(input: $input, condition: $condition) {
      id
      spaceID
      userID
      spaceName
      accountName
      paymentPackage
      productName
      amount
      maxMember
      monthlyUpload
      maxStorage
      totalFee
      invoiceDate
      paymentDate
      paymentPeriod
      expireDate
      paymentMethod
      status
      memo
      payJPPaymentID
      payJPSubscriptionID
      billStatus
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentHistory = /* GraphQL */ `
  mutation UpdatePaymentHistory(
    $input: UpdatePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    updatePaymentHistory(input: $input, condition: $condition) {
      id
      spaceID
      userID
      spaceName
      accountName
      paymentPackage
      productName
      amount
      maxMember
      monthlyUpload
      maxStorage
      totalFee
      invoiceDate
      paymentDate
      paymentPeriod
      expireDate
      paymentMethod
      status
      memo
      payJPPaymentID
      payJPSubscriptionID
      billStatus
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentHistory = /* GraphQL */ `
  mutation DeletePaymentHistory(
    $input: DeletePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    deletePaymentHistory(input: $input, condition: $condition) {
      id
      spaceID
      userID
      spaceName
      accountName
      paymentPackage
      productName
      amount
      maxMember
      monthlyUpload
      maxStorage
      totalFee
      invoiceDate
      paymentDate
      paymentPeriod
      expireDate
      paymentMethod
      status
      memo
      payJPPaymentID
      payJPSubscriptionID
      billStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      content
      commentAt
      attachments {
        bucket
        region
        key
      }
      profileID
      videoID
      groupID
      parentID
      spaceID
      editors
      mentions
      createdAt
      updatedAt
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGuestComment = /* GraphQL */ `
  mutation CreateGuestComment(
    $input: CreateGuestCommentInput!
    $condition: ModelGuestCommentConditionInput
  ) {
    createGuestComment(input: $input, condition: $condition) {
      id
      name
      content
      commentAt
      accessKeyId
      type
      secretAccessKey
      sessionToken
      parentID
      videoID
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateGuestComment = /* GraphQL */ `
  mutation UpdateGuestComment(
    $input: UpdateGuestCommentInput!
    $condition: ModelGuestCommentConditionInput
  ) {
    updateGuestComment(input: $input, condition: $condition) {
      id
      name
      content
      type
      commentAt
      accessKeyId
      secretAccessKey
      sessionToken
      parentID
      videoID
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteGuestComment = /* GraphQL */ `
  mutation DeleteGuestComment(
    $input: DeleteGuestCommentInput!
    $condition: ModelGuestCommentConditionInput
  ) {
    deleteGuestComment(input: $input, condition: $condition) {
      id
      name
      content
      commentAt
      accessKeyId
      secretAccessKey
      sessionToken
      parentID
      videoID
      createdAt
      updatedAt
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUserAccount = /* GraphQL */ `
  mutation CreateUserAccount(
    $input: CreateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    createUserAccount(input: $input, condition: $condition) {
      id
      userID
      email
      password
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserAccount = /* GraphQL */ `
  mutation UpdateUserAccount(
    $input: UpdateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    updateUserAccount(input: $input, condition: $condition) {
      id
      userID
      email
      password
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserAccount = /* GraphQL */ `
  mutation DeleteUserAccount(
    $input: DeleteUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    deleteUserAccount(input: $input, condition: $condition) {
      id
      userID
      email
      password
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createEmailCycle = /* GraphQL */ `
  mutation CreateEmailCycle(
    $input: CreateEmailCycleInput!
    $condition: ModelEmailCycleConditionInput
  ) {
    createEmailCycle(input: $input, condition: $condition) {
      id
      typeCycle
      content
      email
      receiverName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEmailCycle = /* GraphQL */ `
  mutation UpdateEmailCycle(
    $input: UpdateEmailCycleInput!
    $condition: ModelEmailCycleConditionInput
  ) {
    updateEmailCycle(input: $input, condition: $condition) {
      id
      typeCycle
      content
      email
      receiverName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEmailCycle = /* GraphQL */ `
  mutation DeleteEmailCycle(
    $input: DeleteEmailCycleInput!
    $condition: ModelEmailCycleConditionInput
  ) {
    deleteEmailCycle(input: $input, condition: $condition) {
      id
      typeCycle
      content
      email
      receiverName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSpaceInvitation = /* GraphQL */ `
  mutation CreateSpaceInvitation(
    $input: CreateSpaceInvitationInput!
    $condition: ModelSpaceInvitationConditionInput
  ) {
    createSpaceInvitation(input: $input, condition: $condition) {
      id
      spaceID
      userID
      email
      role
      createdAt
      updatedAt
    }
  }
`;
export const updateSpaceInvitation = /* GraphQL */ `
  mutation UpdateSpaceInvitation(
    $input: UpdateSpaceInvitationInput!
    $condition: ModelSpaceInvitationConditionInput
  ) {
    updateSpaceInvitation(input: $input, condition: $condition) {
      id
      spaceID
      userID
      email
      role
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpaceInvitation = /* GraphQL */ `
  mutation DeleteSpaceInvitation(
    $input: DeleteSpaceInvitationInput!
    $condition: ModelSpaceInvitationConditionInput
  ) {
    deleteSpaceInvitation(input: $input, condition: $condition) {
      id
      spaceID
      userID
      email
      role
      createdAt
      updatedAt
    }
  }
`;
export const createVideoAttachment = /* GraphQL */ `
  mutation CreateVideoAttachment(
    $input: CreateVideoAttachmentInput!
    $condition: ModelVideoAttachmentConditionInput
  ) {
    createVideoAttachment(input: $input, condition: $condition) {
      id
      attachToID
      videoID
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateVideoAttachment = /* GraphQL */ `
  mutation UpdateVideoAttachment(
    $input: UpdateVideoAttachmentInput!
    $condition: ModelVideoAttachmentConditionInput
  ) {
    updateVideoAttachment(input: $input, condition: $condition) {
      id
      attachToID
      videoID
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteVideoAttachment = /* GraphQL */ `
  mutation DeleteVideoAttachment(
    $input: DeleteVideoAttachmentInput!
    $condition: ModelVideoAttachmentConditionInput
  ) {
    deleteVideoAttachment(input: $input, condition: $condition) {
      id
      attachToID
      videoID
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        isShareAble
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createUserUnfinish = /* GraphQL */ `
  mutation CreateUserUnfinish(
    $input: CreateUserUnfinishInput!
    $condition: ModelUserUnfinishConditionInput
  ) {
    createUserUnfinish(input: $input, condition: $condition) {
      id
      email
    }
  }
`;
export const updateUserUnfinish = /* GraphQL */ `
  mutation UpdateUserUnfinish(
    $input: UpdateUserUnfinishInput!
    $condition: ModelUserUnfinishConditionInput
  ) {
    updateUserUnfinish(input: $input, condition: $condition) {
      id
      email
    }
  }
`;
export const deleteUserUnfinish = /* GraphQL */ `
  mutation DeleteUserUnfinish(
    $input: DeleteUserUnfinishInput!
    $condition: ModelUserUnfinishConditionInput
  ) {
    deleteUserUnfinish(input: $input, condition: $condition) {
      id
      email
    }
  }
`;
export const createPayJPSubscription = /* GraphQL */ `
  mutation CreatePayJpSubscription(
    $input: CreatePayJPSubscriptionInput!
    $condition: ModelPayJPSubscriptionConditionInput
  ) {
    createPayJPSubscription(input: $input, condition: $condition) {
      id
      userID
      spaceID
      productType
      productName
      quantity
      amount
      startEffectDate
      paymentCycle
      payJPPlanID
      payJPSubscriptionID
      createdAt
      updatedAt
      currentPeriodStart
      currentPeriodEnd
      status
    }
  }
`;
export const updatePayJPSubscription = /* GraphQL */ `
  mutation UpdatePayJpSubscription(
    $input: UpdatePayJPSubscriptionInput!
    $condition: ModelPayJPSubscriptionConditionInput
  ) {
    updatePayJPSubscription(input: $input, condition: $condition) {
      id
      userID
      spaceID
      productType
      productName
      quantity
      amount
      startEffectDate
      paymentCycle
      payJPPlanID
      payJPSubscriptionID
      createdAt
      updatedAt
      currentPeriodStart
      currentPeriodEnd
      status
    }
  }
`;
export const deletePayJPSubscription = /* GraphQL */ `
  mutation DeletePayJpSubscription(
    $input: DeletePayJPSubscriptionInput!
    $condition: ModelPayJPSubscriptionConditionInput
  ) {
    deletePayJPSubscription(input: $input, condition: $condition) {
      id
      userID
      spaceID
      productType
      productName
      quantity
      amount
      startEffectDate
      paymentCycle
      payJPPlanID
      payJPSubscriptionID
      createdAt
      updatedAt
      currentPeriodStart
      currentPeriodEnd
      status
    }
  }
`;
export const createAdminNotification = /* GraphQL */ `
  mutation CreateAdminNotification(
    $input: CreateAdminNotificationInput!
    $condition: ModelAdminNotificationConditionInput
  ) {
    createAdminNotification(input: $input, condition: $condition) {
      id
      targetID
      body
      type
      startedAt
      startedAtFormat
      endedAt
      endedAtFormat
      createdAt
      updatedAt
    }
  }
`;
export const updateAdminNotification = /* GraphQL */ `
  mutation UpdateAdminNotification(
    $input: UpdateAdminNotificationInput!
    $condition: ModelAdminNotificationConditionInput
  ) {
    updateAdminNotification(input: $input, condition: $condition) {
      id
      targetID
      body
      type
      startedAt
      startedAtFormat
      endedAt
      endedAtFormat
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdminNotification = /* GraphQL */ `
  mutation DeleteAdminNotification(
    $input: DeleteAdminNotificationInput!
    $condition: ModelAdminNotificationConditionInput
  ) {
    deleteAdminNotification(input: $input, condition: $condition) {
      id
      targetID
      body
      type
      startedAt
      startedAtFormat
      endedAt
      endedAtFormat
      createdAt
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      description
      type
      spaceID
      parentID
      parent {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        child {
          nextToken
        }
        createdAt
        updatedAt
      }
      child {
        items {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      description
      type
      spaceID
      parentID
      parent {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        child {
          nextToken
        }
        createdAt
        updatedAt
      }
      child {
        items {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      description
      type
      spaceID
      parentID
      parent {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        child {
          nextToken
        }
        createdAt
        updatedAt
      }
      child {
        items {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCoachRoom = /* GraphQL */ `
  mutation CreateCoachRoom(
    $input: CreateCoachRoomInput!
    $condition: ModelCoachRoomConditionInput
  ) {
    createCoachRoom(input: $input, condition: $condition) {
      id
      spaceID
      groupID
      name
      description
      profileID
      coachs
      sharedGroups
      visions {
        items {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCoachRoom = /* GraphQL */ `
  mutation UpdateCoachRoom(
    $input: UpdateCoachRoomInput!
    $condition: ModelCoachRoomConditionInput
  ) {
    updateCoachRoom(input: $input, condition: $condition) {
      id
      spaceID
      groupID
      name
      description
      profileID
      coachs
      sharedGroups
      visions {
        items {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoachRoom = /* GraphQL */ `
  mutation DeleteCoachRoom(
    $input: DeleteCoachRoomInput!
    $condition: ModelCoachRoomConditionInput
  ) {
    deleteCoachRoom(input: $input, condition: $condition) {
      id
      spaceID
      name
      description
      profileID
      coachs
      sharedGroups
      visions {
        items {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVision = /* GraphQL */ `
  mutation CreateVision($input: CreateVisionInput!, $condition: ModelVisionConditionInput) {
    createVision(input: $input, condition: $condition) {
      id
      coachRoomID
      title
      description
      files {
        bucket
        region
        key
      }
      status
      profileID
      isPublic
      coachs
      createdAt
      archivedAt
      isSetBackground
      totalGoals
      background {
        bucket
        region
        key
      }
      coachRoom {
        id
        spaceID
        name
        description
        visions {
          nextToken
        }
        createdAt
        updatedAt
      }
      goals {
        items {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateVision = /* GraphQL */ `
  mutation UpdateVision($input: UpdateVisionInput!, $condition: ModelVisionConditionInput) {
    updateVision(input: $input, condition: $condition) {
      id
      coachRoomID
      title
      description
      files {
        bucket
        region
        key
      }
      status
      profileID
      isPublic
      coachs
      createdAt
      archivedAt
      isSetBackground
      totalGoals
      background {
        bucket
        region
        key
      }
      coachRoom {
        id
        spaceID
        name
        description
        visions {
          nextToken
        }
        createdAt
        updatedAt
      }
      goals {
        items {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteVision = /* GraphQL */ `
  mutation DeleteVision($input: DeleteVisionInput!, $condition: ModelVisionConditionInput) {
    deleteVision(input: $input, condition: $condition) {
      id
      coachRoomID
      title
      description
      files {
        bucket
        region
        key
      }
      status
      profileID
      isPublic
      createdAt
      archivedAt
      coachRoom {
        id
        spaceID
        name
        description
        visions {
          nextToken
        }
        createdAt
        updatedAt
      }
      goals {
        items {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal($input: CreateGoalInput!, $condition: ModelGoalConditionInput) {
    createGoal(input: $input, condition: $condition) {
      id
      visionID
      title
      color
      startDate
      order
      endDate
      status
      coachs
      createdAt
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachRoom {
          id
          spaceID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      issues {
        items {
          id
          goalID
          title
          startDate
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal($input: UpdateGoalInput!, $condition: ModelGoalConditionInput) {
    updateGoal(input: $input, condition: $condition) {
      id
      visionID
      title
      color
      startDate
      order
      endDate
      status
      coachs
      createdAt
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachRoom {
          id
          spaceID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      issues {
        items {
          id
          goalID
          title
          startDate
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal($input: DeleteGoalInput!, $condition: ModelGoalConditionInput) {
    deleteGoal(input: $input, condition: $condition) {
      id
      visionID
      title
      color
      startDate
      order
      endDate
      status
      createdAt
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachRoom {
          id
          spaceID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      issues {
        items {
          id
          goalID
          title
          startDate
          endDate
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createIssue = /* GraphQL */ `
  mutation CreateIssue($input: CreateIssueInput!, $condition: ModelIssueConditionInput) {
    createIssue(input: $input, condition: $condition) {
      id
      goalID
      visionID
      title
      startDate
      endDate
      status
      coachs
      createdAt
      progress
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateIssue = /* GraphQL */ `
  mutation UpdateIssue($input: UpdateIssueInput!, $condition: ModelIssueConditionInput) {
    updateIssue(input: $input, condition: $condition) {
      id
      goalID
      visionID
      title
      startDate
      endDate
      status
      coachs
      createdAt
      progress
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteIssue = /* GraphQL */ `
  mutation DeleteIssue($input: DeleteIssueInput!, $condition: ModelIssueConditionInput) {
    deleteIssue(input: $input, condition: $condition) {
      id
      goalID
      title
      startDate
      endDate
      status
      createdAt
      progress
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const batchUpdateVisionsCoachs = /* GraphQL */ `
  mutation BatchUpdateVisionsCoachs($ids: [ID!]!, $coachs: [String]!) {
    batchUpdateVisionsCoachs(ids: $ids, coachs: $coachs) {
      id
      coachRoomID
      title
      description
      files {
        bucket
        region
        key
      }
      status
      createdAt
      coachs
      coachRoom {
        id
        spaceID
        groupID
        name
        description
        visions {
          nextToken
        }
        createdAt
        updatedAt
      }
      goals {
        items {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          coachs
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const batchUpdateGoalsCoachs = /* GraphQL */ `
  mutation BatchUpdateGoalsCoachs($ids: [ID!]!, $coachs: [String]!) {
    batchUpdateGoalsCoachs(ids: $ids, coachs: $coachs) {
      id
      visionID
      title
      color
      startDate
      order
      endDate
      status
      createdAt
      coachs
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachs
        coachRoom {
          id
          spaceID
          groupID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      issues {
        items {
          id
          goalID
          title
          startDate
          endDate
          status
          createdAt
          coachs
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const batchUpdateIssuesCoachs = /* GraphQL */ `
  mutation BatchUpdateIssuesCoachs($ids: [ID!]!, $coachs: [String]!) {
    batchUpdateIssuesCoachs(ids: $ids, coachs: $coachs) {
      id
      goalID
      title
      startDate
      endDate
      status
      createdAt
      coachs
      progress
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        coachs
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;

export const createIssueLink = /* GraphQL */ `
  mutation CreateIssueLink(
    $input: CreateIssueLinkInput!
    $condition: ModelIssueLinkConditionInput
  ) {
    createIssueLink(input: $input, condition: $condition) {
      id
      targetID
      targetType
      issueID
      profileID
      issue {
        id
        goalID
        title
        startDate
        endDate
        status
        createdAt
        coachs
        goal {
          id
          visionID
          vision {
            id
            coachRoomID
            status
          }
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          coachs
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIssueLink = /* GraphQL */ `
  mutation DeleteIssueLink(
    $input: DeleteIssueLinkInput!
    $condition: ModelIssueLinkConditionInput
  ) {
    deleteIssueLink(input: $input, condition: $condition) {
      id
      targetID
      targetType
      issueID
      profileID
      issue {
        id
        goalID
        title
        startDate
        endDate
        status
        createdAt
        coachs
        goal {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          coachs
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGoalLink = /* GraphQL */ `
  mutation CreateGoalLink($input: CreateGoalLinkInput!, $condition: ModelGoalLinkConditionInput) {
    createGoalLink(input: $input, condition: $condition) {
      id
      targetID
      targetType
      goalID
      profileID
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        coachs
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateGoalLink = /* GraphQL */ `
  mutation UpdateGoalLink($input: UpdateGoalLinkInput!, $condition: ModelGoalLinkConditionInput) {
    updateGoalLink(input: $input, condition: $condition) {
      id
      targetID
      targetType
      goalID
      profileID
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        coachs
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteGoalLink = /* GraphQL */ `
  mutation DeleteGoalLink($input: DeleteGoalLinkInput!, $condition: ModelGoalLinkConditionInput) {
    deleteGoalLink(input: $input, condition: $condition) {
      id
      targetID
      targetType
      goalID
      profileID
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        coachs
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVisionLink = /* GraphQL */ `
  mutation CreateVisionLink(
    $input: CreateVisionLinkInput!
    $condition: ModelVisionLinkConditionInput
  ) {
    createVisionLink(input: $input, condition: $condition) {
      id
      targetID
      targetType
      visionID
      profileID
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachs
        profileID
        isPublic
        archivedAt
        coachRoom {
          id
          spaceID
          groupID
          profileID
          name
          description
          coachs
          sharedGroups
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateVisionLink = /* GraphQL */ `
  mutation UpdateVisionLink(
    $input: UpdateVisionLinkInput!
    $condition: ModelVisionLinkConditionInput
  ) {
    updateVisionLink(input: $input, condition: $condition) {
      id
      targetID
      targetType
      visionID
      profileID
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachs
        profileID
        isPublic
        archivedAt
        coachRoom {
          id
          spaceID
          groupID
          profileID
          name
          description
          coachs
          sharedGroups
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteVisionLink = /* GraphQL */ `
  mutation DeleteVisionLink(
    $input: DeleteVisionLinkInput!
    $condition: ModelVisionLinkConditionInput
  ) {
    deleteVisionLink(input: $input, condition: $condition) {
      id
      targetID
      targetType
      visionID
      profileID
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachs
        profileID
        isPublic
        archivedAt
        coachRoom {
          id
          spaceID
          groupID
          profileID
          name
          description
          coachs
          sharedGroups
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;