//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faEllipsisV, faInfoCircle, faBars } from '@fortawesome/free-solid-svg-icons';
import { mapActions, mapGetters } from 'vuex';
import common from '@/mixins/common';
import * as Cookie from '@/lib/util/cookies';
import * as CryptoJS from '@/lib/util/crypto';

export default {
  mixins: [common],
  props: {
    activeSidebar: {
      type: Boolean,
      default: false,
    },
    hasMoreBtn: {
      type: Boolean,
      default: true,
    },
    hasBackBtn: {
      type: Boolean,
      default: true,
    },
    groupName: {
      type: String,
      default: 'Group name',
    },
  },
  data() {
    return {
      image_src: 'https://bulma.io/images/placeholders/128x128.png',
      default: 'https://bulma.io/images/placeholders/128x128.png',
      isMore: false,
    };
  },
  computed: {
    ...mapGetters('space', ['currentSpace']),
    ...mapGetters('group', ['currentGroup']),
    faEllipsisV() {
      return faEllipsisV;
    },
    faInfoCircle() {
      return faInfoCircle;
    },
    showSidebar() {
      return this.activeSidebar;
    },
    showMoreBtn() {
      return this.hasMoreBtn;
    },
    showBackBtn() {
      return false;
    },
    isGroup() {
      return this.$route.name.includes('group');
    },
    isSpace() {
      return this.$route.name.includes('space');
    },
    // isListVideo() {
    //   return this.$route.name === 'space-id-video';
    // },
    isAdminSignIn() {
      return Cookie.default.getCookie('isAdminSignInAsUser') === 'true';
    },
    faBars() {
      return faBars;
    },
  },
  created() {},
  methods: {
    ...mapActions('auth', ['logout', 'signIn']),
    ...mapActions('notifications', ['stopListenToNotifications']),
    ...mapActions('guest', ['removeGuestUser']),
    toggleNav() {
      if (this.isMore) {
        this.isMore = false;
      }
      this.$emit('toggle', !this.showSidebar);
    },
    redirectGroupVideo() {
      const { id: groupID } = this.currentGroup;
      const { id: spaceID } = this.currentSpace;
      this.$router.push({ path: `/space/${spaceID}/group/${groupID}/video` });
    },
    // redirectInfo() {
    //   if (this.isGroup) {
    //     const { id: groupID } = this.currentGroup;
    //     const { id: spaceID } = this.currentSpace;
    //     this.$router.push({ path: `/space/${spaceID}/group/${groupID}/detail` });
    //   } else {
    //     this.$router.push({ name: 'space-id-edit' });
    //   }
    // },
    toggleMore() {
      this.isMore = !this.isMore;
    },
    async handleLogout() {
      // this.removeGuestUser();
      this.stopListenToNotifications();
      this.logout();
      this.$router.push('/login');
    },
    async signInAsAdmin() {
      const email = Cookie.default.getCookie('adminEmail');
      const password = Cookie.default.getCookie('adminPassword');
      if (email && password) {
        this.$store.dispatch('loading/setOnLoading');
        await this.logout();
        const decryptEmail = CryptoJS.default.decryptMessage(email);
        const decryptPassword = CryptoJS.default.decryptMessage(password);
        await this.signIn({ email: decryptEmail, password: decryptPassword });
        Cookie.default.setCookie('isAdminSignInAsUser', '', -1);
        this.$router.push({ path: '/admin' });
      }
    },
    // getGroupVideoNotifications() {
    //   const { id: groupID } = this.currentGroup;
    //   const { uploadVideoGroup } = this.notifications;
    //   return uploadVideoGroup.filter((notify) => notify.groupID === groupID).length;
    // },
  },
};
