import { API, graphqlOperation } from 'aws-amplify';
import { mapGetters, mapActions } from 'vuex';
import {
  onCreateAdminNotification,
  onUpdateAdminNotification,
  onDeleteAdminNotification,
} from '@/graphql/customSubscriptions';
import dayjs from '@/plugins/dayjs';

export default {
  data() {
    return {
      createAdminNotificationSub: null,
      updateAdminNotificationSub: null,
      deleteAdminNotificationSub: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },
  beforeDestroy() {
    this.createAdminNotificationSub?.unsubscribe();
    this.updateAdminNotificationSub?.unsubscribe();
    this.deleteAdminNotificationSub?.unsubscribe();
  },
  async mounted() {
    if (this.isAuthenticated) {
      this.createAdminNotificationSub = API.graphql(
        graphqlOperation(onCreateAdminNotification) // eslint-disable-line
      ).subscribe({
        next: ({ value }) => {
          const noti = value.data.onCreateAdminNotification;
          const today = dayjs();
          const startTime = dayjs(noti.startedAtFormat);
          const endTime = dayjs(noti.endedAtFormat);
          if (today.isBetween(startTime, endTime)) {
            this.addAdminNoti(noti);
          }
        },
      });

      this.updateAdminNotificationSub = API.graphql(
        graphqlOperation(onUpdateAdminNotification) // eslint-disable-line
      ).subscribe({
        next: ({ value }) => {
          const noti = value.data.onUpdateAdminNotification;
          const today = dayjs();
          const startTime = dayjs(noti.startedAtFormat);
          const endTime = dayjs(noti.endedAtFormat);
          if (today.isBetween(startTime, endTime)) {
            this.updateAdminNoti(noti);
          } else {
            this.removeAdminNoti(noti.id);
          }
        },
      });

      this.deleteAdminNotificationSub = API.graphql(
        graphqlOperation(onDeleteAdminNotification) // eslint-disable-line
      ).subscribe({
        next: ({ value }) => {
          const noti = value.data.onDeleteAdminNotification;
          this.removeAdminNoti(noti.id);
        },
      });
    }
  },
  methods: {
    ...mapActions('adminNotification', ['addAdminNoti', 'updateAdminNoti', 'removeAdminNoti']),
  },
};
