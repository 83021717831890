export const state = () => ({
  listNotiAdmin: [],
  checkListNotiAdminInterval: null,
});

export const mutations = {
  SET_LIST_NOTI_ADMIN(state, list) {
    state.listNotiAdmin = list;
  },
  ADD_ADMIN_NOTI(state, noti) {
    const { listNotiAdmin } = state;
    listNotiAdmin.unshift(noti);
    state.listNotiAdmin = [...listNotiAdmin];
  },
  UPDATE_ADMIN_NOTI(state, noti) {
    const { listNotiAdmin } = state;
    const findIndex = listNotiAdmin.findIndex((item) => item.id === noti.id);
    if (findIndex !== -1) {
      listNotiAdmin[findIndex] = noti;
      state.listNotiAdmin = [...listNotiAdmin];
    } else {
      listNotiAdmin.unshift(noti);
      state.listNotiAdmin = [...listNotiAdmin];
    }
  },
  REMOVE_ADMIN_NOTI(state, id) {
    state.listNotiAdmin = state.listNotiAdmin.filter((noti) => noti.id !== id);
  },
  SET_CHECK_INTERVAL(state) {
    const interval = setInterval(() => {
      const time = new Date().getTime() / 1000;
      state.listNotiAdmin = state.listNotiAdmin.filter((noti) => noti.endedAt >= time);
    }, 1000);
    state.checkListNotiAdminInterval = interval;
  },
  CLEAR_CHECK_INTERVAL(state) {
    clearInterval(state.checkListNotiAdminInterval);
    state.checkListNotiAdminInterval = null;
  },
};

export const actions = {
  async queryListAdminNotification({ dispatch }, options) {
    try {
      const data = await dispatch(
        'api/query',
        {
          query: 'adminNotificationByStartedAt',
          ...options,
        },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      return data;
    } catch (error) {
      console.error('query list admin notifications:', error);
      return false;
    }
  },

  async getAdminNotification({ dispatch }, id) {
    try {
      return await dispatch(
        'api/get',
        { query: 'getAdminNotification', id },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to get admin notification:', error);
      return false;
    }
  },

  async createAdminNotification({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'createAdminNotification', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
    } catch (error) {
      console.error('create admin notification:', error);
      return false;
    }
  },

  async updateAdminNotification({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'updateAdminNotification', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
    } catch (error) {
      console.error('update admin notification:', error);
      return false;
    }
  },

  async deleteAdminNotification({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'deleteAdminNotification', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
    } catch (error) {
      console.error('delete admin notification:', error);
      return false;
    }
  },

  setListNotiAdmin({ commit }, list) {
    const filterList = list.filter((item) => {
      const checkClose = localStorage.getItem(`closedAdminNoti${item.id}`, 'true');
      return checkClose !== 'true';
    });
    commit('SET_LIST_NOTI_ADMIN', filterList);
  },

  addAdminNoti({ commit }, noti) {
    commit('ADD_ADMIN_NOTI', noti);
  },

  updateAdminNoti({ commit }, noti) {
    commit('UPDATE_ADMIN_NOTI', noti);
  },

  removeAdminNoti({ commit }, id) {
    commit('REMOVE_ADMIN_NOTI', id);
  },

  setCheckInterval({ commit }) {
    commit('SET_CHECK_INTERVAL');
  },

  clearCheckInterval({ commit }) {
    commit('CLEAR_CHECK_INTERVAL');
  },
};

export const getters = {
  listNotiAdmin: (state) => state.listNotiAdmin,
};
