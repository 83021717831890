import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('notifications', ['notifications']),
    ...mapGetters('space', ['currentSpace']),
  },
  methods: {
    ...mapActions('notifications', ['checkBadges']),
    async checkBadge(type, filterID = null) {
      const notifiesToCheck = this.notifications[type];
      let checkIDs = null;
      if (type === 'mentionChat') {
        if (filterID === 'all') {
          await this.checkBadges({ type });
        } else if (filterID) {
          checkIDs = notifiesToCheck
            .filter((noti) => noti.groupID === filterID && noti.statusBadge === true)
            .map((notify) => notify.id);
          await this.checkBadges({ type, notifyIDs: checkIDs });
        } else {
          checkIDs = notifiesToCheck
            .filter((noti) => !noti.groupID && noti.statusBadge === true)
            .map((notify) => notify.id);
          await this.checkBadges({ type, notifyIDs: checkIDs });
        }
      } else if (type === 'requestJoinSpace') {
        if (filterID) {
          checkIDs = notifiesToCheck
            .filter((noti) => noti.userID === filterID && noti.statusBadge === true)
            .map((notify) => notify.id);
          await this.checkBadges({ type, notifyIDs: checkIDs });
        } else {
          await this.checkBadges({ type });
        }
      } else {
        await this.checkBadges({ type });
      }
    },
  },
};
