function sortByCreatedAt(a, b) {
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  return 0;
}

export const state = () => ({
  currentPaymentHistory: {},
  listPaymentHistories: [],
  pendingData: {},
});

export const mutations = {
  SET_PAYMENT_HISTORY(state, payment) {
    state.currentPaymentHistory = payment;
  },
  SET_PAYMENT_HISTORIES(state, payments) {
    state.listPaymentHistories = payments;
  },
  SET_PENDING_DATA(state, data) {
    state.pendingData = data;
  },
};

export const actions = {
  async listPaymentHistory({ commit, dispatch }, { filter, limit }) {
    try {
      const paymentData = await dispatch(
        'api/queryAll',
        { query: 'listPaymentHistorys', filter, limit },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_PAYMENT_HISTORIES', paymentData);
      return paymentData;
    } catch (error) {
      console.error('fail to list Payment History', error);
      return false;
    }
  },

  async getPaymentHistory({ commit, dispatch }, id) {
    try {
      const paymentData = await dispatch(
        'api/get',
        { query: 'getPaymentHistory', id },
        // eslint-disable-next-line comma-dangle
        { root: true }
      );
      commit('SET_PAYMENT_HISTORY', paymentData);
      return paymentData;
    } catch (error) {
      console.error('fail to get Payment History', error);
      return false;
    }
  },

  async createPaymentHistory({ commit, dispatch }, input) {
    try {
      const paymentData = await dispatch(
        'api/mutate',
        { mutation: 'createPaymentHistory', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_PAYMENT_HISTORY', paymentData);
      return paymentData;
    } catch (error) {
      console.error('fail to create Payment History', error);
      return false;
    }
  },

  async updatePaymentHistory({ commit, dispatch }, input) {
    try {
      const paymentData = await dispatch(
        'api/mutate',
        { mutation: 'updatePaymentHistory', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_PAYMENT_HISTORY', paymentData);
      return paymentData;
    } catch (error) {
      console.error('fail to update Payment History', error);
      return false;
    }
  },

  async deletePaymentHistory({ dispatch }, id) {
    try {
      const paymentData = await dispatch(
        'api/mutate',
        { mutation: 'deletePaymentHistory', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
      return paymentData;
    } catch (error) {
      console.error('fail to delete Payment History', error);
      return false;
    }
  },

  preparePaymentData({ commit }, input) {
    commit('SET_PENDING_DATA', input);
  },

  async listPaymentBySpaceIDStatus({ dispatch, commit }, { spaceID, filter, limit, nextToken, ...variables }) { // eslint-disable-line
    try {
      const paymentData = await dispatch(
        'api/query',
        { query: 'listBySpaceIDStatus', spaceID, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_PAYMENT_HISTORIES', paymentData);
    } catch (error) {
      console.error('fail list Payment History', error);
      return false;
    }
  },
};

export const getters = {
  currentPaymentHistory: (state) => state.currentPaymentHistory,
  listPaymentHistories: (state) => state.listPaymentHistories,
  pendingData: (state) => state.pendingData,
  listPaymentHistoriesSort: (state) => state.listPaymentHistories.sort(sortByCreatedAt),
};
