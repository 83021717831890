export const state = () => ({
  videoGroups: [],
});

export const mutations = {
  LIST_VIDEO_GROUPS(state, videos) {
    state.videoGroups = videos;
  },
};

export const actions = {
  async getVideoGroups({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const groupVideo = await dispatch(
        'api/query',
        { query: 'listGroupVideos', filter, limit, nextToken },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      const videos = [];
      await groupVideo.forEach((data) => {
        const { video, groupID } = data;
        if (video.status === 'approve') {
          video.groupID = groupID;
          videos.push(video);
        }
      });
      commit('LIST_VIDEO_GROUPS', videos);
      return Promise.resolve();
    } catch (error) {
      console.error('get group video', error);
      return Promise.reject();
    }
  },
};

export const getters = {
  videoGroups: (state) => state.videoGroups,
};
