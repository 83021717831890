import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"top-right","duration":2000})

const globals = [{"name":"success","message":function(payload) {
          return payload;
        },"options":{"type":"success","position":"top-right","className":"custom-success","duration":2000}},{"name":"info","message":function(payload) {
          return payload;
        },"options":{"type":"info","position":"top-right","duration":2000}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
