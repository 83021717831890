export const state = () => ({
  welcomeMessage: false,
  spaceWelcomeMessage: false,
  thankYouMessage: false,
});

export const mutations = {
  SET_WELCOME_MESSAGE(state, message) {
    state.welcomeMessage = message;
  },
  SET_SPACE_WELCOME_MESSAGE(state, message) {
    state.spaceWelcomeMessage = message;
  },
  SET_THANK_YOU_MESSAGE(state, message) {
    state.thankYouMessage = message;
  },
};

export const actions = {
  // async function to set the welcome message state by calling mutation
  async setWelcomeMessage({ commit }, message) {
    commit('SET_WELCOME_MESSAGE', message);
  },
  async setSpaceWelcomeMessage({ commit }, message) {
    commit('SET_SPACE_WELCOME_MESSAGE', message);
  },
  async setThankYouMessage({ commit }, message) {
    commit('SET_THANK_YOU_MESSAGE', message);
  },
};
export const getters = {
  getWelcomeMessage(state, _getters, rootState) {
    return state.welcomeMessage && rootState.space.currentSpace?.isActiveCoachRoom;
  },
  getSpaceWelcomeMessage(state, _getters, rootState) {
    return state.spaceWelcomeMessage && rootState.space.currentSpace?.isActiveCoachRoom;
  },
  getThankYouMessage(state, _getters, rootState) {
    return state.thankYouMessage && rootState.space.currentSpace?.isActiveCoachRoom;
  },
};
