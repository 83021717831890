import dayjs from '@/plugins/dayjs';

function sortByCreatedAt(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}

export const state = () => ({
  messagesGroup: [],
});

export const mutations = {
  LIST_CHAT_GROUPS(state, { messages, dayLimitation, isLoadingDown }) {
    const dayLimit = dayLimitation || -1;
    if (dayLimit > 0) {
      const queryFrom = dayjs().add(-dayLimit, 'day').format();
      if (isLoadingDown) {
        messages.forEach((mess) => {
          if (mess.createdAt > queryFrom) state.messagesGroup.push(mess);
        });
      } else {
        messages.forEach((mess) => {
          if (mess.createdAt > queryFrom) state.messagesGroup.unshift(mess);
        });
      }
    } else if (isLoadingDown) {
      messages.forEach((mess) => state.messagesGroup.push(mess));
    } else {
      messages.forEach((mess) => state.messagesGroup.unshift(mess));
    }
  },
  CREATE_CHAT(state, message) {
    const { messagesGroup } = state;
    state.messagesGroup = [...messagesGroup, message];
  },
  NEW_CHAT(state, message) {
    const { messagesGroup } = state;
    if (message.parentID === 'true') {
      state.messagesGroup = [...messagesGroup, message];
    } else {
      const parentMess = messagesGroup.find((mess) => mess.id === message.parentID);
      if (parentMess) {
        parentMess.child.items.push(message);
        // also push to root level of list messages for reactions state
        state.messagesGroup = [...messagesGroup];
      }
    }
  },
  UPDATE_CHAT(state, message) {
    const { messagesGroup } = state;
    const objIndex = messagesGroup.findIndex((obj) => obj.id === message.id);
    messagesGroup[objIndex] = message;
    state.messagesGroup = [...messagesGroup];
  },
  DELETE_CHAT(state, message) {
    const { messagesGroup } = state;
    const newMessages = messagesGroup.filter((item) => item.id !== message.id);
    state.messagesGroup = [...newMessages];
  },
  LOAD_MORE_CHATGROUPS(state, newMess) {
    const { messagesGroup } = state;
    state.messagesGroup = messagesGroup.concat(newMess);
  },
  RESET_LIST_CHAT_GROUP(state) {
    state.messagesGroup = [];
  },
  UPDATE_DISPLAY_LINKS_FOR_GROUP_CHAT(state, { messageID, visions, goals, issues }) {
    const { messagesGroup } = state;
    const objIndex = messagesGroup.findIndex((obj) => obj.id === messageID);
    if (objIndex > -1) {
      messagesGroup[objIndex] = {
        ...messagesGroup[objIndex],
        visions: { items: visions },
        goals: { items: goals },
        issues: { items: issues },
      };
      state.messagesGroup = [...messagesGroup];
    }
  },
};

export const actions = {
  async createChatGroup({ dispatch }, input) {
    try {
      const comment = await dispatch(
        'api/mutate',
        { mutation: 'createChatGroup', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      return comment;
    } catch (error) {
      console.error('create Chat group ', error);
      return Promise.reject();
    }
  },
  async updateChatGroup({ dispatch }, input) {
    try {
      const comment = await dispatch(
        'api/mutate',
        { mutation: 'updateChatGroup', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      return comment;
    } catch (error) {
      console.error('update chat group', error);
      return Promise.reject();
    }
  },
  async deleteChatGroup({ dispatch, state }, input) {
    try {
      const comment = await dispatch(
        'api/mutate',
        { mutation: 'deleteChatGroup', input },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      const { messagesGroup } = state;
      const childChatToDelete = messagesGroup.filter((chat) => chat.parentID === input.id);
      childChatToDelete.forEach((chat) => {
        dispatch(
          'api/mutate',
          { mutation: 'deleteChatGroup', input: { id: chat.id } },
          { root: true }, // eslint-disable-line prettier/prettier
        );
      });
      return comment;
    } catch (error) {
      console.error('delete Chat Group', error);
      return Promise.reject();
    }
  },
  async newChatGroup({ commit }, message) {
    commit('NEW_CHAT', message);
  },
  subscribeChangeChatGroup({ commit }, message) {
    commit('UPDATE_CHAT', message);
  },
  subscribeDeleteChatGroup({ commit }, message) {
    commit('DELETE_CHAT', message);
  },
  async queryListChatGroup(
    { commit, dispatch },
    { options, dayLimitation = null, isHighLightList = false, isLoadingDown = false } // eslint-disable-line
  ) {
    try {
      const messagesData = await dispatch(
        'api/query',
        {
          query: 'groupChatByCreatedAt',
          ...options,
          isHighLightList,
        },
        { root: true }, // eslint-disable-line prettier/prettier
      );
      commit('LIST_CHAT_GROUPS', { messages: messagesData, dayLimitation, isLoadingDown });
      return messagesData.length > 0;
    } catch (error) {
      console.error('query group chats', error);
      return false;
    }
  },

  async resetListChatGroup({ commit }) {
    commit('RESET_LIST_CHAT_GROUP');
  },
  async getChatGroup({ dispatch }, id) {
    try {
      const chatData = await dispatch(
        'api/get',
        { query: 'getChatGroup', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      return chatData;
    } catch (error) {
      console.error('fail to get issue', error);
      return false;
    }
  },
  updateDisplayLinksForGroupChat({ commit }, { messageID, visions, goals, issues }) {
    commit('UPDATE_DISPLAY_LINKS_FOR_GROUP_CHAT', { messageID, visions, goals, issues });
  },
};

export const getters = {
  listChatGroups: (state, _getters, rootState) => {
    const myAction = rootState.profile?.userSpaceProfile?.filterVisionMessage?.myAction || [];
    const someOneElseAction =
      rootState.profile?.userSpaceProfile?.filterVisionMessage?.someOneElseAction || [];
    const currentProfileID = rootState.profile?.userSpaceProfile?.id || '';
    const currentGroupID = rootState.group?.currentGroup?.id || '';
    const { messagesGroup } = state;
    return [...messagesGroup]
      .filter((message) => {
        if (message.type !== 'system') return true;
        if (message.title === 'ナレッジ・ループからのご案内') return true;
        if (currentProfileID === message.visionActionPerformer) {
          if (myAction.includes(currentGroupID)) return false;
          return true;
        }
        if (someOneElseAction.includes(currentGroupID)) return false;
        return true;
      })
      .sort(sortByCreatedAt);
  },
};
