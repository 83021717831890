export const state = () => ({
  user: null,
});

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
};

export const actions = {
  async getUser({ commit, dispatch }, id) {
    const user = await dispatch('api/get', { query: 'getUser', id }, { root: true });
    commit('SET_USER', user);
    return user;
  },
  async createUser({ commit, dispatch }, input) {
    try {
      input.queryType = 'User';
      const user = await dispatch('api/mutate', { mutation: 'createUser', input }, { root: true });
      commit('SET_USER', user);
      return user;
    } catch (e) {
      return false;
    }
  },
  async updateUser({ dispatch }, input) {
    const user = await dispatch('api/mutate', { mutation: 'updateUser', input }, { root: true });
    await dispatch('auth/load', {}, { root: true });
    return user;
  },
  async findOrCreateUser({ dispatch }, { username, payload }) {
    const user = await dispatch('getUser', username);
    if (user) return user;

    return dispatch('createUser', payload);
  },

  async listUsers({ dispatch }, { filter, limit, nextToken }) {
    try {
      const userData = await dispatch(
        'api/query',
        { query: 'listUsers', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      return userData;
    } catch (error) {
      console.error('fail to list Users', error);
      return Promise.reject();
    }
  },

  async getUserUnfinish({ dispatch }, id) {
    try {
      const user = await dispatch('api/get', { query: 'getUserUnfinish', id }, { root: true });
      return user;
    } catch (e) {
      return false;
    }
  },

  async createUserUnfinish({ dispatch }, input) {
    try {
      const user = await dispatch(
        'api/mutate',
        { mutation: 'createUserUnfinish', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      return user;
    } catch (e) {
      return false;
    }
  },

  async deleteUserUnfinish({ dispatch }, id) {
    try {
      const user = await dispatch(
        'api/mutate',
        { mutation: 'deleteUserUnfinish', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
      return user;
    } catch (e) {
      return false;
    }
  },

  async listUsersByCreatedAt({ dispatch }, { queryType, filter, limit, nextToken, ...variables }) {
    try {
      const userData = await dispatch(
        'api/query',
        { query: 'userByCreatedAt', queryType, filter, limit, nextToken, ...variables },
        { root: true } // eslint-disable-line comma-dangle
      );
      return userData;
    } catch (error) {
      console.error('fail to list Users by created at:', error);
      return Promise.reject();
    }
  },
};
