export default async ({ store, route, redirect }) => {
  const groupID = route.path.split('/')[4];
  const spaceId = route.path.split('/')[2];
  const {
    currentUser: { id: userID },
  } = store.state.auth;
  if (store.state.group.currentGroup.id !== groupID) {
    await store.dispatch('group/getGroup', groupID);
  }
  const { currentGroup } = store.state.group;
  if (!store.state.coachRoom?.coachRooms?.length) {
    await store.dispatch('coachRoom/getSpaceCoachRooms', {
      spaceID: spaceId,
    });
    await store.dispatch('coachRoom/getCoachRoomsShared', currentGroup);
  }
  if (currentGroup.status === 'inactive') {
    return redirect(`/space/${spaceId}/video`);
  }
  if (currentGroup.userID !== userID) {
    const joinGroupUser = currentGroup.joinedMembers.items;
    const joinGroupUserID = joinGroupUser.map((join) => join.userID);
    if (!joinGroupUserID.includes(userID)) {
      return redirect(`/space/${spaceId}/video`);
    }
  }
};
