import { API, graphqlOperation } from 'aws-amplify';
import { mapGetters } from 'vuex';
import {
  onUpdateSpace,
  onUpdateGroup,
  onDeleteSpace,
  onDeleteGroup,
} from '@/graphql/customSubscriptions';

export default {
  data() {
    return {
      updateSpaceSubscription: null,
      groupSpaceSubscription: null,
      deletedSpaceSubscription: null,
      deletedGroupSubscription: null,
    };
  },
  computed: {
    ...mapGetters('space', ['currentSpace']),
    ...mapGetters('group', ['currentGroup']),
    ...mapGetters('auth', ['currentUser']),
  },
  beforeDestroy() {
    if (this.updateSpaceSubscription && this.groupSpaceSubscription) {
      this.updateSpaceSubscription.unsubscribe();
      this.groupSpaceSubscription.unsubscribe();
      this.deletedSpaceSubscription.unsubscribe();
      this.deletedGroupSubscription.unsubscribe();
    }
  },
  watch: {
    '$route.name': {
      handler(name) {
        if (name === 'account-unregister') {
          if (this.updateSpaceSubscription && this.groupSpaceSubscription) {
            this.updateSpaceSubscription.unsubscribe();
            this.groupSpaceSubscription.unsubscribe();
            this.deletedSpaceSubscription.unsubscribe();
            this.deletedGroupSubscription.unsubscribe();
            // console.log('unregister listen to space group exist channel');
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    const spaceID = this.currentSpace.id;
    const groupID = this.currentGroup.id;
    if (this.$route.name === 'account-unregister') {
      if (this.updateSpaceSubscription && this.groupSpaceSubscription) {
        this.updateSpaceSubscription.unsubscribe();
        this.groupSpaceSubscription.unsubscribe();
        this.deletedSpaceSubscription.unsubscribe();
        this.deletedGroupSubscription.unsubscribe();
      }
      // console.log('unregister listen to space group exist channel');
      return;
    }
    if (this.$auth.isAuthenticated) {
      this.updateSpaceSubscription = API.graphql(graphqlOperation(onUpdateSpace)).subscribe({
        next: ({ value }) => {
          const updatedSpace = value.data.onUpdateSpace;
          if (updatedSpace.status === 'inactive' && updatedSpace.id === spaceID) {
            if (this.currentUser.isDeleted || this.currentUser.isDisabled) {
              this.$router.push({ path: '/login' }, () => {
                this.$toast.error(this.$locale.t('user.deactivated'));
              });
            } else {
              this.$router.push({ path: '/space' }, () => {
                this.$toast.error(this.$locale.t('space.deleted'));
              });
            }
          }
        },
      });

      this.groupSpaceSubscription = API.graphql(graphqlOperation(onUpdateGroup)).subscribe({
        next: ({ value }) => {
          const updatedGroup = value.data.onUpdateGroup;
          if (updatedGroup.status === 'inactive') {
            this.removeJoinGroup(updatedGroup.id);
          }
        },
      });

      this.deletedSpaceSubscription = API.graphql(graphqlOperation(onDeleteSpace)).subscribe({
        next: ({ value }) => {
          const deletedSpace = value.data.onDeleteSpace;
          if (deletedSpace.id === spaceID) {
            this.$router.push({ path: '/space' }, () => {
              this.$toast.error(this.$locale.t('space.deleted'));
            });
          }
        },
      });
      this.deletedGroupSubscription = API.graphql(graphqlOperation(onDeleteGroup)).subscribe({
        next: ({ value }) => {
          const deletedGroup = value.data.onDeleteGroup;
          if (deletedGroup.id === groupID) {
            this.removeJoinGroup(deletedGroup.id);
          }
        },
      });
    }
  },
};
